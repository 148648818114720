import React from "react";
import { Button, Modal } from "reactstrap";
import { IoMdClose } from "react-icons/io";
import "./ModalConfirmation.css";

const ModalConfirmation = ({ toggle, modal, text, confirmFunction }) => {
  return (
    <Modal className="modal-confirmation" isOpen={modal} toggle={toggle} centered={true}>
      <div className="close-btn-container">
        <IoMdClose onClick={toggle} />
      </div>

      <div className="text-container">
        <h5>{text}</h5>
      </div>

      <div className="btn-container">
        <Button color="danger" onClick={confirmFunction}>
          Sim
        </Button>{" "}
        <Button color="primary" onClick={toggle}>
          Não
        </Button>
      </div>
    </Modal>
  );
};

export default ModalConfirmation;
