// import React from "react";
// import Nav from "./KeyCloak/Nav";
// import { useKeycloak } from "@react-keycloak/web";

const KeyAuth = () => {
  // const { keycloak } = useKeycloak();
  // const isLoggedIn = keycloak.authenticated;
  //   if (!isLoggedIn) {
  //     return (
  //       <div>
  //         <Nav />
  //         <h1 className="text-black text-4xl">Não logado.</h1>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div>
  //         <Nav />
  //         <h1 className="text-black text-4xl">Welcome to the Protected Page.</h1>
  //       </div>
  //     );
  //   }
};

export default KeyAuth;
