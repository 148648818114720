import React from "react";
import { Navigate } from "react-router-dom";
import CheckAuth from "../Auth/CheckAuth";

const Index = () => {
  let isAuth = CheckAuth();

  if (isAuth) {
    return <Navigate to="/home" />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default Index;
