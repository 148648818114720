import React, { useState, useEffect } from "react";
import Header from "../../elements/Header";
import { Link } from "react-router-dom";
// import { useKeycloak } from "@react-keycloak/web";
import { useParams } from "react-router-dom";
import {
  // Table,
  // UncontrolledCollapse,
  // Collapse,
  Button,
  // CardBody,
  // Card,
  // ListGroup,
  // ListGroupItem,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
// import {
//   BsFillFlagFill,
//   BsFillPencilFill,
//   BsFillPlusCircleFill,
// } from "react-icons/bs";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import CheckPermission from "./CheckPermission";
import { BsArrowLeft } from "react-icons/bs";
import ValidaSenhaSegura from "./ValidaSenha";

const NewUser = () => {
  document.title = "Mission Page - New";
  let apiUrl = process.env.REACT_APP_API_URL;
  let { id: companyId } = useParams();
  //const { keycloak } = useKeycloak();
  // const navigate = useNavigate();
  // const [userProfile, setUserProfile] = useState({});
  const [companyData, setCompanyData] = useState();
  const [permissionsData, setPermissionsData] = useState();
  const [modal, setModal] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
    button: "",
    buttonHref: "",
  });
  const toggleModal = (content) => {
    if (!content.title) content.title = "";
    if (!content.content) content.content = "";
    if (!content.button) content.button = "Ok";
    if (!content.buttonHref) content.buttonHref = null;
    if (!content.actionButton) content.actionButton = null;

    setModalContent(content);
    setModal(!modal);
  };

  function getPermissionsData() {
    //console.log("getPermissions");
    fetch(apiUrl + "/users/roles", {
      method: "GET",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setPermissionsData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  function getCompanyData() {
    return new Promise((resolve, reject) => {
      fetch(apiUrl + "/companies/" + companyId, {
        method: "GET",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userPermission.access_token,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            throw new Error("Erro");
          }
        })
        .then((data) => {
          try {
            data = JSON.parse(data);
          } catch (error) {}
          setCompanyData(data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          console.error("Error:", error);
        });
    });
  }

  const [userPermission, setUserPermission] = useState(null);
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });

  useEffect(() => {
    if (userPermission) {
      getCompanyData().then(() => {
        getPermissionsData();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission]);

  const getLoading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status" id="loading">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  };

  const onFormSubmit = (values) => {
    values.preventDefault();
    let fieldsAndValues = {};

    let breakReq = false;

    Object.entries(values.target.elements).forEach((ele) => {
      console.log(ele[1].id, ele[1].value);
      if (
        ele[0] === "email" &&
        (!ele[1] || !ele[1].value || ele[1].value.toString().lenght < 2)
      ) {
        breakReq = "O campo email é obrigatório!";
      } else if (ele[1].id === "password") {
        let statusSenha = ValidaSenhaSegura(ele[1].value);
        console.log(statusSenha);
        if (statusSenha !== "true") {
          breakReq = "A senha " + statusSenha.toString().toLocaleLowerCase();
        }
      }
      let value = ele[1].value;
      if (value) fieldsAndValues[ele[1].id] = value;
    });

    if (!breakReq) {
      toggleModal({
        title: "Inserir novo usuário",
        content: (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status" id="loading">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ),
        buttonHref: null,
        actionButton: null,
      });
      fetch(apiUrl + "/users", {
        method: "POST",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userPermission.access_token,
        },
        body: JSON.stringify(fieldsAndValues),
      })
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          } else {
            return response.text().then((error) => {
              throw new Error(error);
            });
          }
        })
        .then((data) => {
          toggleModal({
            title: "Inserir novo usuário",
            content:
              "Novo usuário inserido!\n\nVocê poderá vê-lo na página de gerenciamento.",
            buttonHref: "/companiesManagement/" + companyId,
          });
        })
        .catch((error) => {
          console.log(error);
          toggleModal({
            title: "Inserir novo usuário",
            content:
              "Houve um erro ao inserir o usuário. " +
              (error.toString().indexOf("Conflict") &&
                "Aparentemente já existe um usuário no sistema com estes dados, por favor verifique o email e username."),
            buttonHref: null,
            actionButton: () => setModal(false),
          });
          console.error("Error:", error);
        });
    } else {
      toggleModal({
        title: "Inserir novo usuário",
        content: "Não foi possível inserir um novo usuário: " + breakReq,
        buttonHref: null,
        actionButton: () => setModal(false),
      });
    }
  };
  return (
    <div className="p-2">
      <Modal isOpen={modal} toggle={toggleModal} backdrop="static">
        <ModalHeader>{modalContent.title}</ModalHeader>
        <ModalBody>{modalContent.content}</ModalBody>
        {modalContent.buttonHref || modalContent.actionButton ? (
          <ModalFooter>
            <Link
              className="btn btn-outline-secondary"
              to={modalContent.buttonHref ? modalContent.buttonHref : false}
              onClick={
                modalContent.actionButton ? modalContent.actionButton : false
              }
            >
              {modalContent.button}
            </Link>
          </ModalFooter>
        ) : (
          ""
        )}
      </Modal>
      <Header />
      <div className="container" style={{ marginTop: "10vh " }}>
        <div id="basicInformation" className="recuadoOut">
          <div className="row pt-4">
            <h2 className="col-10 m-0 text-start">
              <Link
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Voltar para a lista de empresas"
                className="text-end "
                style={{ marginRight: "0px" }}
                to={"/companiesManagement/" + companyId}
              >
                <BsArrowLeft
                  className="nav-button-circle "
                  style={{ boxShadow: "none" }}
                />
              </Link>
              Novo usuário
            </h2>
            <div className="col-2 pr-0 m-0 row"></div>
          </div>
          <hr />
          <div
            className="main-content mt-0 p-3"
            style={{ display: "flex", padding: "5px" }}
          >
            <div style={{ width: "100%" }} id="uprojetos">
              {true ||
              CheckPermission(
                userPermission && userPermission.role
                  ? userPermission.role
                  : "",
                "EMPLOYEE"
              ) ? (
                !permissionsData || !companyData ? (
                  getLoading()
                ) : (
                  <Form onSubmit={onFormSubmit}>
                    <FormGroup row>
                      <Label for={"email"} sm={3}>
                        Email
                      </Label>
                      <Col sm={9}>
                        <Input
                          id={"email"}
                          name={"Email"}
                          placeholder={"Email"}
                          type={"text"}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for={"first_name"} sm={3}>
                        Nome
                      </Label>
                      <Col sm={9}>
                        <Input
                          id={"first_name"}
                          name={"Nome"}
                          placeholder={"Nome"}
                          type={"text"}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for={"last_name"} sm={3}>
                        Sobrenome
                      </Label>
                      <Col sm={9}>
                        <Input
                          id={"last_name"}
                          name={"Sobrenome"}
                          placeholder={"Sobrenome"}
                          type={"text"}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for={"username"} sm={3}>
                        Username
                      </Label>
                      <Col sm={9}>
                        <Input
                          id={"username"}
                          name={"Username"}
                          placeholder={"Username"}
                          type={"text"}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for={"role"} sm={3}>
                        Permissão
                      </Label>
                      <Col sm={9}>
                        <select
                          className="form-select"
                          id={"role"}
                          name={"Permissão"}
                        >
                          {permissionsData
                            ? permissionsData.map((permissionsItem) => {
                                if (
                                  localStorage.getItem("robotictech_role") ===
                                    "ADMIN" ||
                                  (permissionsItem &&
                                    permissionsItem.role &&
                                    permissionsItem.role !== "ADMIN")
                                ) {
                                  return (
                                    <option
                                      key={Math.random()}
                                      value={permissionsItem.role}
                                    >
                                      {permissionsItem.name}
                                    </option>
                                  );
                                } else {
                                  return false;
                                }
                              })
                            : ""}
                        </select>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for={"password"} sm={3}>
                        Senha
                      </Label>
                      <Col sm={9}>
                        <Input
                          id={"password"}
                          name={"Senha"}
                          placeholder={"Senha"}
                          type={"password"}
                          onChange={(t) => {
                            let statusSenha = ValidaSenhaSegura(t.target.value);
                            if (statusSenha !== "true") {
                              setPasswordMessage(
                                <Row className={"text-danger"}>
                                  {statusSenha}
                                </Row>
                              );
                            } else {
                              setPasswordMessage(
                                <Row className={"text-success"}>
                                  A senha atende aos requisitos de complexidade!
                                </Row>
                              );
                            }
                          }}
                        />
                        <div className="ms-3 mt-1">
                          <small>
                            {passwordMessage ? passwordMessage : ""}
                          </small>
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for={"company_id"} sm={3}>
                        Empresa
                      </Label>
                      <Col sm={9}>
                        <Input
                          id={"company_id"}
                          name={"Empresa"}
                          placeholder={"Empresa"}
                          type={"text"}
                          hidden={true}
                          defaultValue={
                            companyData && companyData.id ? companyData.id : ""
                          }
                        />
                        <Input
                          disabled={true}
                          defaultValue={
                            companyData && companyData.name
                              ? companyData.name
                              : ""
                          }
                        ></Input>
                      </Col>
                    </FormGroup>

                    <div className="row">
                      <Button className="col-8 m-auto">Criar Usuário</Button>
                    </div>
                  </Form>
                )
              ) : (
                <h4>Sem permissão para acessar esta área.</h4>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUser;
