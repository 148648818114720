import React, { useState } from "react";
import { Label, Modal } from "reactstrap";
import GalleryCarousel from "../GalleryCarousel/GalleryCarousel";
import "./DetectionDetailsModal.css";
import { IoCloseSharp } from "react-icons/io5";
import ModalSupervisionMode from "../ModalSupervisionMode/ModalSupervisionMode";

const DetectionDetailsModal = ({ modalDetails, toggle, modalContent }) => {
  const [supervisionMode, setSupervisionMode] = useState(false);

  const toggleSupervisionMode = () => setSupervisionMode(!supervisionMode);

  const detectedObjectType = modalContent.imageArray[0].objectType;

  return (
    <>
      {supervisionMode && (
        <ModalSupervisionMode
          toggleSupervisionMode={toggleSupervisionMode}
          supervisionModeOpen={supervisionMode}
          activePointSupervision={modalContent.index}
          detectionsArray={modalContent.imageArray}
          poleInfo={modalContent.pointDetails}
        />
      )}

      {!supervisionMode && (
        <Modal
          size="xl"
          isOpen={modalDetails}
          toggle={toggle}
          backdrop={true}
          keyboard={false}
          style={{ zIndex: "1600" }}
        >
          <div className="header-detection-modal">
            <div className="container-title">
              <h5>{modalContent.header}</h5>

              {detectedObjectType === "pole" && (
                <div
                  className="container-switch-detection"
                  style={{ cursor: "pointer" }}
                >
                  <div
                    onClick={toggleSupervisionMode}
                    style={{ fontSize: "24px" }}
                  >
                    <img
                      alt="icon"
                      src={
                        supervisionMode
                          ? "/imgs/HABILITAR.svg"
                          : "/imgs/DESABILITAR.svg"
                      }
                      style={{ width: "37px" }}
                    />
                  </div>

                  <Label
                    style={{ margin: 0, cursor: "pointer" }}
                    onClick={toggleSupervisionMode}
                  >
                    <small>Modo de supervisão</small>
                  </Label>
                </div>
              )}
            </div>

            <IoCloseSharp
              style={{ cursor: "pointer" }}
              size="1.5rem"
              onClick={toggle}
            />
          </div>

          <GalleryCarousel
            key={Math.random()}
            imageArray={modalContent.imageArray}
            index={modalContent.index}
            detectedObjectType={detectedObjectType}
          />
        </Modal>
      )}
    </>
  );
};

export default DetectionDetailsModal;
