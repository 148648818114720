import React, { useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./ModalMessage.css"

const ModalMessage = ({ isOpen, message, toggle }) => {

  useEffect(() => {
    setTimeout(() => {
      toggle();
    }, 3000)
  }, [toggle])

  return (
    <Modal isOpen={isOpen} toggle={toggle} styles={{ zIndex: 3000 }}>
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody className="message-body">{message}</ModalBody>
    </Modal>
  );
};

export default ModalMessage;
