import React from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import "./ModalNewAnnotation.css";

const ModalNewAnnotation = ({
  isOpen,
  toggle,
  options,
  annotationType,
  setAnnotationType,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} styles={{ zIndex: 3000 }}>
      <ModalHeader toggle={toggle} className="modal-new-annotation">
        Selecione o tipo de componente que deseja anotar!
      </ModalHeader>
      <ModalBody className="body-modal-new-annotation">
        <Input
          type="select"
          onChange={(e) => setAnnotationType(e.target.value)}
          value={annotationType}
        >
          {options.map((o) => {
            if (o.value !== "pole") {
              return (
                <option key={o.value} value={o.value}>
                  {o.text}
                </option>
              );
            }
            return null;
          })}
        </Input>
        <span style={{ fontSize: "13px" }}>
          *Cada nova anotação gerará um novo objecto detectado!
        </span>
        <Button color="success" onClick={toggle}>
          Ok
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default ModalNewAnnotation;
