import React from "react";
import { Marker } from "react-leaflet";
import Leaflet from "leaflet";

const defaultRadiusMarker = process.env.REACT_APP_DEFAULT_POINT_RADIUS;

const DraggableMapMarker = ({
  point,
  icon,
  color,
  clickFunction,
  onDragStart,
  onDragEnd,
  onDrag,
  markerRef
}) => {
  if (point.draggable === null || point.draggable === undefined) {
    point.draggable = false;
  }

  const createCustomIcon = (color) => {
    const customSvgIcon = `
      <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="15" stroke="${color}" stroke-width="0" fill="transparent" />
        <circle cx="20" cy="20" r="${defaultRadiusMarker}" fill="${color}" />
      </svg>
    `;

    return Leaflet.divIcon({
      html: customSvgIcon,
      className: "custom-icon",
      iconSize: [40, 40],
      iconAnchor: [20, 20],
    });
  };

  return (
    <Marker
      draggable={point.draggable}
      icon={point.radius ? icon : createCustomIcon(point.color || color)}
      position={[point.latitude, point.longitude]}
      eventHandlers={{
        dragstart: () => onDragStart(point),
        drag: (e) => onDrag(e, point),
        dragend: (e) => onDragEnd(),
        click: () => clickFunction(point.draggable),
      }}
      ref={(el) => {
        if (el) {
          markerRef.current[point.id] = el;
        }
      }}
    />
  );
};

export default DraggableMapMarker;
