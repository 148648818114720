import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./pages/Home/Home";
import Login from "./pages/Auth/Login";
import Index from "./pages/Index/Index";
import Missoes from "./pages/Missions/Missions";
import Projetos from "./pages/Projects/Projects";
import Reports from "./pages/Projects/Reports";
import Map from "./pages/Map/Map";
import UserManagement from "./pages/Auth/UserManagement";
import CompaniesManagement from "./pages/Auth/CompaniesManagement";

import KeyAuth from "./pages/KeyAuth";
import NewProject from "./pages/Projects/NewProject";
import NewMission from "./pages/Missions/NewMission";
import EditMission from "./pages/Missions/EditMission";
import MissionImages from "./pages/Missions/MissionImages";
import NewCompany from "./pages/Auth/NewCompany";
import NewUser from "./pages/Auth/NewUser";
import EditUser from "./pages/Auth/EditUser";
import NewReport from "./pages/Projects/NewReport";
import EditMissionPoints from "./pages/Missions/EditPoints";
import ProjectDetails from "./pages/Projects/ProjectDetails";
import EditCompany from "./pages/Auth/EditCompany";
import { Toast, ToastBody, ToastHeader } from "reactstrap";

let timer = null;

const Router = () => {
  // Begin global message
  const [toast, setToast] = useState(null);

  useEffect(() => {
    if (toast && toast.timer) {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        setToast(null);
      }, toast.timer);
    }
  }, [toast]);
  // End global message

  return (
    <>
      <Toast
        style={{
          position: "absolute",
          top: "8vh",
          right: "3vw",
          zIndex: "1050",
          visibility: toast ? "visible" : "hidden",
          opacity: toast ? "1" : "0",
          transition: "visibility 0.5s, opacity 0.2s ease-out",
        }}
      >
        <ToastHeader toggle={() => setToast(null)}>
          {toast && toast.title ? toast.title : "Alerta"}
        </ToastHeader>
        <ToastBody>{toast && toast.message ? toast.message : ""}</ToastBody>
      </Toast>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/auth" element={<KeyAuth />} />

        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/projetos" element={<Projetos />} />
        <Route path="/detalhesprojeto" element={<ProjectDetails />} />
        <Route path="/detalhesprojeto/:id" element={<ProjectDetails />} />
        <Route path="/novoprojeto" element={<NewProject />} />
        <Route path="/novoprojeto/:id" element={<NewProject />} />
        {/* <Route path="/editarprojeto/:id" element={<EditProject />} /> */}
        <Route path="/editarprojeto/:id" element={<NewProject />} />
        <Route path="/missoes" element={<Missoes />} />
        <Route path="/missoes/:id" element={<Missoes toast={setToast} />} />
        <Route path="/missoes/:id/imagens" element={<MissionImages />} />
        <Route
          path="/editarpontos/:id"
          element={<EditMissionPoints toast={setToast} />}
        />
        <Route
          path="/missoes/:id/imagens/:projetoID"
          element={<MissionImages />}
        />
        <Route path="/novamissao/:id" element={<NewMission />} />
        <Route
          path="/editarmissao/:missionId/:projectId"
          element={<EditMission />}
        />
        <Route path="/relatorios/" element={<Reports />} />
        <Route
          path="/novorelatorio/:id"
          element={<NewReport toast={setToast} />}
        />
        <Route path="/relatorios/:id" element={<Reports />} />
        <Route path="/mapa" element={<Map toast={setToast} />} />
        <Route path="/mapa/:type" element={<Map toast={setToast} />} />
        <Route path="/mapa/:type/:id" element={<Map toast={setToast} />} />
        <Route path="/userManagement" element={<UserManagement />} />
        <Route path="/companiesManagement" element={<CompaniesManagement />} />
        <Route
          path="/companiesManagement/:id"
          element={<CompaniesManagement />}
        />
        <Route path="/editCompany" element={<EditCompany />} />
        <Route path="/editCompany/:id" element={<EditCompany />} />
        <Route path="/novaempresa" element={<NewCompany />} />
        <Route path="/novousuario" element={<NewUser />} />
        <Route path="/novousuario/:id" element={<NewUser />} />
        <Route path="/editarusuario/:id" element={<EditUser />} />
      </Routes>
    </>
  );
};

export default Router;
