import React, { useState, useEffect } from "react";
import { useNavigate, Navigate, Link, useParams } from "react-router-dom";
import Header from "../../elements/Header";
import CheckAuth from "../Auth/CheckAuth";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  ListGroupItem,
  ListGroup,
  Row,
  Col,
  Container,
} from "reactstrap";
import {
  BsArrowLeft,
  BsGeoAltFill,
  BsFillPencilFill,
  BsFillPlusCircleFill,
  BsTrashFill,
  BsFillExclamationTriangleFill,
  BsFillCheckCircleFill,
  BsFillXCircleFill,
  BsPinMapFill,
  BsClipboard,
} from "react-icons/bs";
import "../../elements/Styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import CheckPermission from "../Auth/CheckPermission";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { Circle, MapContainer, TileLayer } from "react-leaflet";
import PaginationBar from "../../elements/PaginationBar/PaginationBar";

// Recupera informações do arquivo ".env"
let mapSourcePreview = JSON.parse(process.env.REACT_APP_LEAFLET_PREVIEWMAP);

const Missions = (props) => {
  // Define o título da aba/página
  document.title = "Lista de Missões";

  // Recupera informações do arquivo ".env"
  let apiUrl = process.env.REACT_APP_API_URL;
  let defaultPointColor = process.env.REACT_APP_DEFAULT_POINT_COLOR;

  const navigate = useNavigate();

  // Recupera informações recebidas pela url
  let { id: projectId } = useParams();

  // Início aquisição das informações do usuário logado
  const [userPermission, setUserPermission] = useState();
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });
  // Fim aquisição das informações do usuário logado

  // Hook de armazenamento dos dados do projeto
  const [projectData, setProjectData] = useState(false);

  // Hook de apoio para atualização dos dados da página
  // const [updatePage, setUpdatePage] = useState();

  // begin get projects list
  const [listProjectsList, setProjectsList] = useState(null);

  const getProjectsList = () => {
    fetch(
      // apiUrl +
      //   "/companies/" +
      //   localStorage.getItem("robotictech_company_id") +
      //   "/projects?size=10000",
      CheckPermission(
        userPermission && userPermission.role ? userPermission.role : "",
        "ADMIN"
      )
        ? apiUrl + "/projects?size=10000"
        : apiUrl +
            "/companies/" +
            localStorage.getItem("robotictech_company_id") +
            "/projects?size=10000",
      {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userPermission.access_token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((plist) => {
        try {
          plist = JSON.parse(plist);
        } catch (error) {}
        let projectList = [];
        if (plist.content && plist.content.length > 0) {
          projectList = plist.content;
        }
        setProjectsList(projectList);

        return projectList;
      })
      .finally((res) => {});
  };
  // end get projects list

  // Begin controls modal cópia de imagens
  const [modalCopiaImagens, setModalCopiaImagens] = useState(false);
  const [modalCopiaImagensContent, setModalCopiaImagensContent] =
    useState(false);
  const openModalCopiaImagensContent = (title, body, cancel, confirm, ok) => {
    setModalCopiaImagensContent({
      title: title,
      body: body,
      cancel: cancel,
      confirm: confirm,
      ok: ok,
    });
    setModalCopiaImagens(true);
  };

  // const contador = (i) => {
  //   if (i < 10) {
  //     setTimeout(() => {
  //       console.log(i);
  //       contador(++i);
  //     }, 1000);
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const enviaRequisicaoCopiaImagens = (selectedMissionId, selectedProject) => {
    let apiUrlFomatted =
      apiUrl +
      "/missions/" +
      selectedMissionId +
      "/copy-to-project-" +
      selectedProject;

    openModalCopiaImagensContent(
      "Copiar imagens da missão",
      <>
        <div className="m-4">
          <p className="text-start">Processo de cópia iniciado</p>
        </div>
        <ModalFooter className="mt-4">
          <Button
            color="secondary"
            onClick={
              () => setModalCopiaImagens(false)
              // window.location.reload(false)
            }
          >
            Ok
          </Button>
        </ModalFooter>
      </>
    );

    props.toast({
      title: "Copiar imagens da missão",
      message: (
        <>
          <div style={{ display: "flex" }}>
            <Spinner
              // className="m-auto"
              className="ms-2 me-2"
              style={{
                display: "block",
                width: "20px",
                height: "20px",
              }}
              color="primary"
            />
            Copiando...
          </div>
        </>
      ),
    });

    fetch(apiUrlFomatted, {
      method: "POST",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        props.toast({
          title: "Copiar imagens da missão",
          message: (
            <div>
              <div className="mt-1 mb-1">Imagens copiadas.</div>
              <a
                className="btn btn-outline-secondary pt-1 pb-1 col-12"
                style={{ cursor: "pointer" }}
                href={"/missoes/" + selectedProject}
              >
                Ver projeto
              </a>
            </div>
          ),
          timer: 3000,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  // End controls modal  cópia de imagens

  // Início do controle de exclusão de missões
  const [modalDeleteMission, setModalDeleteMission] = useState(false);
  const [modalContent, setModalDeleteMissionContent] = useState({});

  const toggleModalDeleteMission = () =>
    setModalDeleteMission(!modalDeleteMission);
  // const closeModalDeleteMission = () => {
  //   setUpdatePage(Math.random());
  //   setModalDeleteMission(false);
  // };

  const openModalDeleteMissionContent = (id, icon, title, body) => {
    setModalDeleteMissionContent({
      idMission: id,
      icon: icon,
      title: title,
      body: body,
      footer: true,
    });
    toggleModalDeleteMission();
  };

  const deleteMission = (id) => {
    setModalDeleteMissionContent({
      id: modalContent.id,
      icon: modalContent.icon,
      title: modalContent.title,
      body: (
        <Spinner
          className="m-auto"
          style={{ display: "block" }}
          color="primary"
        />
      ),
      footer: false,
    });
    fetch(apiUrl + "/missions/" + id, {
      method: "DELETE",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        setModalDeleteMissionContent({
          id: modalContent.id,
          icon: <BsFillCheckCircleFill />,
          title: modalContent.title,
          body: "Missão apagada com sucesso.",
          footer: "reload",
        });
      })
      .catch((error) => {
        setModalDeleteMissionContent({
          id: modalContent.id,
          icon: <BsFillXCircleFill />,
          title: modalContent.title,
          body: "Não foi possível apagar a missão.",
          footer: false,
        });
        console.error("Error:", error);
      });
  };
  // Fim do controle de exclusão de missões

  // Hooks de armazenamento de missões
  const [missionItems, setMissionItems] = useState([]);
  const [missionItemsList, setMissionItemsList] = useState([]);
  const [updateItemsList, setUpdateItemsList] = useState(0);
  const [missionMapList, setMissionMapList] = useState([]);

  // Atualização da lista de missões
  useEffect(() => {
    let missionList = [];

    missionItemsList.forEach((element, cont = 0) => {
      let listItem = createMissionItemList(element, cont++);
      missionList.push(listItem);
    });
    setMissionItems([]);
    if (missionList.length > 0) setMissionItems(missionList);
    else setMissionItems(<div>Sem missões disponíveis</div>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateItemsList]);

  // Atualização da lista de missões do projeto

  // Obtenção dos dados do projeto
  const getProjectData = () => {
    fetch(apiUrl + "/projects/" + projectId, {
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((pulist) => {
        try {
          pulist = JSON.parse(pulist);
        } catch (error) {}
        setProjectData(pulist);
      })
      .finally(() => {});
  };

  // Detecção dos pontos extremos para centralização e zoom
  const calculateMaxMinCoord = (element) => {
    let latCenter = 0;
    let lngCenter = 0;
    let contPoints = 0;
    let latMax = null;
    let latMin = null;
    let lngMax = null;
    let lngMin = null;
    element.forEach((imePoint) => {
      contPoints++;
      latCenter = latCenter + imePoint.latitude;
      lngCenter = lngCenter + imePoint.longitude;
      if (!latMax || latMax < imePoint.latitude) {
        latMax = imePoint.latitude;
      }
      if (!latMin || latMin > imePoint.latitude) {
        latMin = imePoint.latitude;
      }
      if (!lngMax || lngMax < imePoint.longitude) {
        lngMax = imePoint.longitude;
      }
      if (!lngMin || lngMin > imePoint.longitude) {
        lngMin = imePoint.longitude;
      }
    });

    if (!latMax) latMax = 0;
    if (!latMin) latMin = 0;
    if (!lngMax) lngMax = 0;
    if (!lngMin) lngMin = 0;

    latCenter = latCenter / contPoints;
    lngCenter = lngCenter / contPoints;

    if (!latCenter) latCenter = -20.32138449535715;
    if (!lngCenter) lngCenter = -40.339507198852004;

    let diffLat = (latMax - latMin) * 10000;

    let zoomLevel = 0;

    if (!diffLat || (diffLat <= 1 && diffLat >= -1)) {
      zoomLevel = 2;
    } else {
      if (diffLat < 10) zoomLevel = 19;
      else if (diffLat < 70) zoomLevel = 16;
      else if (diffLat < 150) zoomLevel = 15;
      else if (diffLat < 300) zoomLevel = 14;
      else if (diffLat < 500) zoomLevel = 13;
      else if (diffLat < 1000) zoomLevel = 12;
      else if (diffLat < 8000) zoomLevel = 11;
      else if (diffLat < 10000) zoomLevel = 10;
      else if (diffLat < 35000) zoomLevel = 9;
      else if (diffLat < 50000) zoomLevel = 8;
      else if (diffLat < 80000) zoomLevel = 7;
      else if (diffLat < 100000) zoomLevel = 6;
      else if (diffLat < 150000) zoomLevel = 5;
      else zoomLevel = 2;
    }

    let data = {
      latCenter: latCenter,
      lngCenter: lngCenter,
      contPoints: contPoints,
      latMax: latMax,
      latMin: latMin,
      lngMax: lngMax,
      lngMin: lngMin,
      zoomLevel: zoomLevel,
    };
    return data;
  };

  // Cria o elemento da lista de missões
  const createMissionItemList = (element, id) => {
    if(element.being_deleted) return;

    return (
      <ListGroupItem
        key={Math.random()}
        className="p-0"
        action
        onDoubleClick={() => {
          navigate("/missoes/" + element.id + "/imagens/" + projectId);
        }}
        style={{
          marginBottom: "10px",
          borderRadius: "10px",
        }}
      >
        <Row className="m-0">
          <Col className="p-0">
            <Row className="m-0">
              <Col lg={4} className="p-0">
                {missionMapList &&
                missionMapList[id] &&
                missionMapList[id].images ? (
                  <MapContainer
                    className="m-0"
                    center={[
                      calculateMaxMinCoord(missionMapList[id].images).latCenter,
                      calculateMaxMinCoord(missionMapList[id].images).lngCenter,
                    ]}
                    zoom={
                      calculateMaxMinCoord(missionMapList[id].images)
                        .zoomLevel && 15
                    }
                    dragging={false}
                    scrollWheelZoom={false}
                    style={{
                      borderRadius: "10px",
                      position: "relative",
                      width: "100%",
                      height: "200px",
                    }}
                    doubleClickZoom={false}
                  >
                    <TileLayer
                      attribution={mapSourcePreview.attribution}
                      url={mapSourcePreview.url}
                      subdomains={mapSourcePreview.subDomains}
                    />
                    {missionMapList &&
                    missionMapList[id] &&
                    missionMapList[id].images
                      ? missionMapList[id].images.map((elem) => {
                          return (
                            <Circle
                              key={Math.random()}
                              center={[elem.latitude, elem.longitude]}
                              fillColor={defaultPointColor}
                              radius="5"
                              fillOpacity={0.6}
                              color={defaultPointColor}
                            />
                          );
                        })
                      : ""}
                  </MapContainer>
                ) : (
                  <div
                    className="d-flex justify-content-center row m-0"
                    style={{
                      borderRadius: "10px",
                      position: "relative",
                      width: "100%",
                      height: "200px",
                      background: "#c9c9c9",
                    }}
                  >
                    <div
                      className="spinner-border row m-auto"
                      style={{ color: "#858585" }}
                    >
                      <div className="visually-hidden">Loading...</div>
                    </div>
                  </div>
                )}
              </Col>
              <Col className="p-3">
                <Link
                  to={"/missoes/" + element.id + "/imagens/" + projectId}
                  className="p-0"
                >
                  <div
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample8"
                    aria-expanded="false"
                    aria-controls="collapseExample8"
                  >
                    <h5>
                      {element.name ? element.name : "Missão #" + element.id}
                    </h5>
                  </div>

                  <div>
                    <small>ID: {element.id}</small>
                  </div>
                  <div className="mt-1 mb-1">
                    <i>
                      <small>{element.description}</small>
                    </i>
                  </div>
                  <div>
                    <small>Status: {element.status}</small>
                  </div>
                </Link>
              </Col>
              {projectData ? (
                <Col lg={3} className={"m-auto row m-0"}>
                  <div className="col-12 m-auto">
                    {CheckPermission(
                      userPermission && userPermission.role
                        ? userPermission.role
                        : "",
                      "EMPLOYEE"
                    ) ? (
                      <Link
                        className={"btn btn-item-list pt-0 pb-0"}
                        style={{ width: "100%", height: "1.7em" }}
                        type="button"
                        onClick={() => {
                          let selectedProject = null;
                          return openModalCopiaImagensContent(
                            "Copiar imagens da missão",
                            <div>
                              {listProjectsList &&
                              listProjectsList.length > 0 ? (
                                <div className="m-4">
                                  <p className="text-start">
                                    Selecione o projeto de destino
                                  </p>
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      onChange={(selected) => {
                                        selectedProject = selected.target.value;
                                      }}
                                    >
                                      <option value={null}></option>
                                      {listProjectsList &&
                                      listProjectsList.length > 0
                                        ? listProjectsList.map((mission) => {
                                            return (
                                              <option
                                                key={Math.random()}
                                                value={mission.id}
                                              >
                                                {"#" +
                                                  mission.id +
                                                  " - " +
                                                  mission.name}
                                              </option>
                                            );
                                          })
                                        : ""}
                                    </select>
                                  </div>
                                </div>
                              ) : (
                                <Spinner
                                  className="m-auto"
                                  style={{ display: "block" }}
                                  color="primary"
                                />
                              )}
                              <ModalFooter className="mt-4">
                                <Button
                                  color="secondary"
                                  onClick={() => setModalCopiaImagens(false)}
                                >
                                  Cancelar
                                </Button>
                                <Button
                                  color="success"
                                  onClick={() =>
                                    enviaRequisicaoCopiaImagens(
                                      element.id,
                                      selectedProject
                                    )
                                  }
                                >
                                  Confirmar
                                </Button>
                              </ModalFooter>
                            </div>
                          );
                        }}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Realizar uma cópia das imagens da missão"
                      >
                        <small>
                          <Row>
                            <Col className="row m-0 p-0 col-3">
                              <BsClipboard className="m-auto" />
                            </Col>
                            <Col className="ml-auto">Copiar</Col>
                            <Col className="d-block d-sm-none col-3"></Col>
                          </Row>
                        </small>
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-12 m-auto">
                    {CheckPermission(
                      userPermission && userPermission.role
                        ? userPermission.role
                        : "",
                      "EMPLOYEE"
                    ) ? (
                      projectData && projectData.status !== "Finalizado" ? (
                        <Link
                          className="btn btn-item-list pt-0 pb-0"
                          style={{ width: "100%", height: "1.7em" }}
                          type="button"
                          to={"/editarmissao/" + element.id + "/" + projectId}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Realizar alterações na missão"
                        >
                          <small>
                            <Row>
                              <Col className="row m-0 p-0 col-3">
                                <BsFillPencilFill className="m-auto" />
                              </Col>
                              <Col className="ml-auto">Editar</Col>
                              <Col className="d-block d-sm-none col-3"></Col>
                            </Row>
                          </small>
                        </Link>
                      ) : (
                        <div
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Não é possível editar o projeto pois está finalizado."
                        >
                          <Link
                            className="btn btn-item-list pt-0 pb-0 disabled"
                            style={{ width: "100%", height: "1.7em" }}
                            type="button"
                            to={null}
                          >
                            <small>
                              <Row>
                                <Col className="row m-0 p-0 col-3">
                                  <BsFillPencilFill className="m-auto" />
                                </Col>
                                <Col className="ml-auto">Editar</Col>
                                <Col className="d-block d-sm-none col-3"></Col>
                              </Row>
                            </small>
                          </Link>
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="m-auto">
                    {CheckPermission(
                      userPermission && userPermission.role
                        ? userPermission.role
                        : "",
                      "EMPLOYEE"
                    ) ? (
                      projectData && projectData.status !== "Finalizado" ? (
                        <Link
                          className="btn btn-item-list pt-0 pb-0"
                          style={{ width: "100%", height: "1.7em" }}
                          type="button"
                          onClick={() =>
                            openModalDeleteMissionContent(
                              element.id,
                              <BsFillExclamationTriangleFill />,
                              element.id + " - " + element.name,
                              "Deseja realmente apagar a missão " +
                                element.name +
                                " (#" +
                                element.id +
                                ")?"
                            )
                          }
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Excluir a missão (Essa ação não pode ser revertida)"
                        >
                          <small>
                            <Row>
                              <Col className="row m-0 p-0 col-3">
                                <BsTrashFill className="m-auto" />
                              </Col>
                              <Col className="ml-auto">Excluir</Col>
                              <Col className="d-block d-sm-none col-3"></Col>
                            </Row>
                          </small>
                        </Link>
                      ) : (
                        <div
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Não é possível excluir o projeto pois está finalizado."
                        >
                          <Link
                            className="btn btn-item-list pt-0 pb-0 disabled"
                            style={{ width: "100%", height: "1.7em" }}
                            type="button"
                            to={null}
                          >
                            <small>
                              <Row>
                                <Col className="row m-0 p-0 col-3">
                                  <BsTrashFill className="m-auto" />
                                </Col>
                                <Col className="ml-auto">Excluir</Col>
                                <Col className="d-block d-sm-none col-3"></Col>
                              </Row>
                            </small>
                          </Link>
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-12 m-auto">
                    {CheckPermission(
                      userPermission && userPermission.role
                        ? userPermission.role
                        : "",
                      "EMPLOYEE"
                    ) ? (
                      projectData && projectData.status !== "Finalizado" ? (
                        element.status === "Processamento finalizado" ? (
                          <Link
                            className="btn btn-item-list pt-0 pb-0"
                            style={{
                              width: "100%",
                              minHeight: "1.7em",
                              height: "auto",
                            }}
                            type="button"
                            to={"/editarpontos/" + element.id}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Realizar alterações na missão"
                          >
                            <small>
                              <Row>
                                <Col className="row m-0 p-0 col-3">
                                  {/* <Col sm={1} className="col-1 m-auto"> */}
                                  <BsPinMapFill className="m-auto" />
                                </Col>

                                <Col className="ml-auto">
                                  Supervisionar pontos
                                </Col>
                                <Col className="d-block d-sm-none col-3"></Col>
                              </Row>
                            </small>
                          </Link>
                        ) : (
                          <div
                            className=""
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="A missão ainda não foi processada."
                          >
                            <div
                              className="btn btn-item-list pt-0 pb-0 disabled"
                              style={{ width: "100%", height: "auto" }}
                              type="button"
                            >
                              <small>
                                <Row>
                                  <Col className="row m-0 p-0 col-3">
                                    <BsPinMapFill className="m-auto" />
                                  </Col>
                                  <Col className="ml-auto">
                                    Supervisão de pontos
                                    <br />
                                    indisponível
                                  </Col>
                                  <Col className="d-block d-sm-none col-3"></Col>
                                </Row>
                              </small>
                            </div>
                          </div>
                        )
                      ) : (
                        <div
                          className=""
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="O projeto foi finalizado."
                        >
                          <div
                            className="btn btn-item-list pt-0 pb-0 disabled"
                            style={{ width: "100%", height: "auto" }}
                            type="button"
                          >
                            <small>
                              <Row>
                                <Col className="row m-0 p-0 col-3">
                                  <BsPinMapFill className="m-auto" />
                                </Col>
                                <Col className="ml-auto">
                                  Supervisão de pontos
                                  <br />
                                  indisponível
                                </Col>
                                <Col className="d-block d-sm-none col-3"></Col>
                              </Row>
                            </small>
                          </div>
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Col>
        </Row>
      </ListGroupItem>
    );
  };

  const fetchMissionImages = async (missionItemsList) => {
    if (missionItemsList && missionItemsList.length > 0) {
      const auxMapList = [];

      for (let i = 0; i < missionItemsList.length; i++) {
        const missionId = missionItemsList[i].id;

        try {
          const response = await fetch(
            `${apiUrl}/missions/${missionId}/images?size=10000`,
            {
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + userPermission.access_token,
              },
            }
          );

          if (!response.ok) {
            throw new Error("Error fetching mission images");
          }

          const pulist = await response.json();
          const images = pulist.content;
          const maxMinCoord = calculateMaxMinCoord(images);

          auxMapList.push({ images, maxMinCoord });
        } catch (error) {
          console.error("Error fetching mission images:", error);
        }
      }

      setMissionMapList(auxMapList);
    }

    setUpdateItemsList(Math.random());
  };

  const [totalPages, setTotalPages] = useState(0);
  const [pageNo, setPageNo] = useState(0);

  // Obtenção da lista de missões do projeto
  async function getData() {
    getProjectsList();

    await fetch(
      apiUrl +
        "/projects/" +
        projectId +
        "/missions?page=" +
        pageNo +
        "&size=10&sort=name",
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userPermission.access_token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((pulist) => {
        try {
          pulist = JSON.parse(pulist);
          setTotalPages(pulist.totalPages);
        } catch (error) {}
        let missionList = [];
        let projetos = pulist.content;

        setMissionMapList([]);
        setUpdateItemsList(Math.random());
        fetchMissionImages(projetos);

        setMissionItemsList(projetos);
        projetos.forEach((element, cont = 0) => {
          let listItem = createMissionItemList(element, cont++);

          missionList.push(listItem);
        });

        if (missionList.length > 0) {
          setMissionItems(missionList);
        } else {
          setMissionItems(<div>Sem missões disponíveis</div>);
        }

        return missionList;
      })
      .finally(() => {
        getProjectData();
      });
  }

  // Ao obter o token de acesso ou ao for necessário recarregar os dados
  // da página é definido o indicador de carregamento e realizada a
  // requisição para obter os dados.
  useEffect(() => {
    setMissionItems(
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status" id="loading">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
    if (userPermission) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission, pageNo]);

  useEffect(() => {}, [missionMapList]);

  // Verifica se o usuario está autenticado
  if (!CheckAuth()) {
    return <Navigate to="/login" />;
  }

  // Constrói o layout da página
  return (
    <div className="p-2">
      <Header title={projectData && projectData.name ? projectData.name : ""} />
      <Tooltip id="my-tooltip" style={{ zIndex: "1000" }} />
      {/* Início modal de cópia de imagem */}
      <Modal // Modal copy
        isOpen={modalCopiaImagens}
        toggle={() => setModalCopiaImagens(false)}
        backdrop="static"
        className="p-0"
      >
        <ModalHeader toggle={() => setModalCopiaImagens(false)}>
          <div className="row m-0">
            <div className="col m-auto">
              {modalCopiaImagensContent.title
                ? modalCopiaImagensContent.title
                : ""}
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="m-0 p-0">
          {modalCopiaImagensContent.body ? modalCopiaImagensContent.body : ""}
        </ModalBody>
      </Modal>
      {/* Fim modal de cópia de imagem */}

      <Modal
        isOpen={modalDeleteMission}
        toggle={toggleModalDeleteMission}
        backdrop="static"
      >
        <ModalHeader>
          <div className="row m-0">
            <div className="col-2 m-auto">
              {modalContent.icon ? modalContent.icon : ""}
            </div>
            <div className="col m-auto">
              {modalContent.title ? modalContent.title : ""}
            </div>
          </div>
        </ModalHeader>
        <ModalBody>{modalContent.body ? modalContent.body : ""}</ModalBody>
        {modalContent.footer && modalContent.footer === "reload" ? (
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => window.location.reload(false)}
            >
              Ok
            </Button>
          </ModalFooter>
        ) : modalContent.footer ? (
          <ModalFooter>
            <Button color="secondary" onClick={toggleModalDeleteMission}>
              Cancelar
            </Button>
            <Button
              color="danger"
              onClick={() => {
                deleteMission(modalContent.idMission);
              }}
            >
              Apagar
            </Button>
          </ModalFooter>
        ) : (
          ""
        )}
      </Modal>
      <Container
        className="p-0"
        style={{ backgroundColor: "white", borderRadius: "10px" }}
      >
        <div className="container" style={{ marginTop: "10vh " }}>
          <div
            id="basicInformation"
            className="container /*main-content*/ recuadoOut"
          >
            <div className="row pt-4">
              <div className="col-lg-12 mt-auto mb-auto text-start">
                {/* Inicio botão voltar pagina */}
                <h2 className="m-0 text-start">
                  <Link
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Voltar a pagina anterior"
                    className="text-end "
                    style={{ marginRight: "0px" }}
                    to={"/detalhesprojeto/" + projectId}
                  >
                    <BsArrowLeft
                      className="nav-button-circle "
                      style={{ boxShadow: "none" }}
                    />
                  </Link>
                  Missões do projeto{" "}
                  {projectData ? '"' + projectData.name + '"' : "#" + projectId}
                  {/* Final Botão voltar pagina */}
                </h2>
              </div>
              <Row className="col-lg-12 text-end pr-0 m-0 row">
                <Row className="m-auto">
                  <Col lg={8} className="text-start mt-auto mb-auto">
                    <div className="mt-4 mb-4">
                      {projectData ? (
                        <div>
                          <div className="">
                            <small>
                              <b>Cidade: </b>
                              {projectData.city
                                ? projectData.city
                                : "Não disponível"}
                            </small>
                          </div>
                          <div className="">
                            <small>
                              <b>Descrição: </b>
                              {projectData.description
                                ? projectData.description
                                : "Não disponível"}
                            </small>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="spinner-border row m-auto"
                          style={{ color: "#858585" }}
                        >
                          <div className="visually-hidden">Loading...</div>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col>
                    <div className="m-2 me-0">
                      <Link
                        className="text-end "
                        style={{ marginRight: "0px" }}
                        to={"/mapa/projeto/" + projectId}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Visualizar pontos no mapa"
                      >
                        Ver projeto no mapa
                        <BsGeoAltFill
                          className="nav-button-circle me-0"
                          style={{ boxShadow: "none" }}
                        />
                      </Link>
                    </div>
                    <div className="m-2 me-0">
                      {/* Início botão de adicionar nova missão */}
                      {projectData.status !== "Finalizado" &&
                      CheckPermission(
                        userPermission && userPermission.role
                          ? userPermission.role
                          : "",
                        "EMPLOYEE"
                      ) ? (
                        <Link
                          className="text-end "
                          style={{ marginRight: "0px" }}
                          to={"/novamissao/" + projectId}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Criar uma nova missão"
                        >
                          Adicionar
                          <BsFillPlusCircleFill className="nav-button-circle me-0" />
                        </Link>
                      ) : (
                        ""
                      )}
                      {/* Fim botão de adicionar nova missão */}
                    </div>
                  </Col>
                </Row>
              </Row>
            </div>
            <div style={{ display: "flex", padding: "5px" }}>
              <div style={{ width: "100%" }} id="uprojetos">
                <hr style={{ marginTop: "4px" }} />
                <ListGroup className="pb-3">{missionItems}</ListGroup>
                {totalPages > 1 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <PaginationBar
                      totalPages={totalPages}
                      currentPage={pageNo}
                      setCurrentPage={setPageNo}
                    />
                  </div>
                )}
                {missionItems && missionItems.length > 0 ? (
                  <Row className="m-0 pt-3">
                    <small style={{ fontSize: ".8em" }}>
                      *Os pontos apresentados no mapa são referentes a
                      geolocalização das imagens enviadas .
                    </small>
                  </Row>
                ) : (
                  ""
                )}
                {projectData.status === "Finalizado" ? (
                  <Row className="m-0 pt-1">
                    <small style={{ fontSize: ".8em" }}>
                      *As alterações nas missões deste projeto não estão
                      disponíveis devido ao projeto estar finalizado.
                    </small>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Missions;
