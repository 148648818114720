import React from "react";
import { permissionsList } from "../Auth/CheckPermission";
import { Modal, ModalHeader } from "reactstrap";
import ReactDOM from "react-dom/client";

// Realiza o parse do token JWT para busca de informações
function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

async function GetTokenApi() {
  // Recupera os dados do arquivo ".env"
  let apiUrl = process.env.REACT_APP_API_URL;
  let keyCloakUrl = process.env.REACT_APP_KEYCLOAK_URL;

  // Realiza a obtenção dos dados do usuário
  const getUserData = (idUser) => {
    return new Promise((resolve, reject) => {
      fetch(apiUrl + "/users/" + idUser, {
        method: "GET",
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("robotictech_accessToken"),
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            throw new Error("Erro");
          }
        })
        .then(async (data) => {
          try {
            data = JSON.parse(data);
          } catch (error) {}
          resolve(data);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  // Obtém os dados da empresa relacionada ao utilizador autenticado
  const getCompanyUserData = (idCompany) => {
    return new Promise((resolve, reject) => {
      fetch(apiUrl + "/companies/" + idCompany, {
        method: "GET",
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("robotictech_accessToken"),
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            throw new Error("Erro");
          }
        })
        .then(async (data) => {
          try {
            data = JSON.parse(data);
          } catch (error) {}
          resolve(data);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  // Realiza a atualização do token utilizando o refresh_token
  if (localStorage.getItem("robotictech_refreshToken")) {
    let details = {
      grant_type: "refresh_token",
      client_id: "login-app",
      refresh_token: localStorage.getItem("robotictech_refreshToken"),
    };
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(keyCloakUrl + "/realms/Census/protocol/openid-connect/token", {
      method: "POST",
      headers: {
        accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
    })
      .then((res) => {
        if (res.status === 200) return res.json();
        else throw Error("Não foi possível atualizar o token de acesso a API");
      })
      .then((data) => {
        if (data) {
          localStorage.setItem("robotictech_accessToken", data.access_token);
          localStorage.setItem("robotictech_refreshToken", data.refresh_token);
        }
      })
      .catch((error) => {
        localStorage.clear();
        console.log(error);
        const root = ReactDOM.createRoot(document.getElementById("root"));
        root.render(
          <Modal isOpen={true} centered>
            <ModalHeader>Por favor, realize o login novamente.</ModalHeader>
            <a className="btn btn-secondary" href="/login">
              Ok
            </a>
          </Modal>
        );
      });
  }

  // Monta a estrutura de dados do usuario e salva em storage
  if (localStorage.getItem("robotictech_accessToken")) {
    let userData = {};
    userData.access_token = localStorage.getItem("robotictech_accessToken");

    if (localStorage.getItem("robotictech_username")) {
      userData.username = localStorage.getItem("robotictech_username");
    }
    if (localStorage.getItem("robotictech_password")) {
      userData.password = localStorage.getItem("robotictech_password");
    }

    if (!localStorage.getItem("robotictech_id")) {
      let userDataAux = parseJwt(
        localStorage.getItem("robotictech_accessToken")
      );

      localStorage.setItem("robotictech_id", userDataAux.sub);
      userDataAux.realm_access.roles.forEach((uda) => {
        permissionsList.forEach((pl) => {
          if (pl.role === uda) {
            localStorage.setItem("robotictech_role", uda);
          }
        });
      });

      localStorage.setItem("robotictech_email", userDataAux.email);
      localStorage.setItem("robotictech_firstname", userDataAux.given_name);
      localStorage.setItem("robotictech_lastname", userDataAux.family_name);

      if (!localStorage.getItem("robotictech_company_id")) {
        await getUserData(userDataAux.sub).then((res) => {
          localStorage.setItem("robotictech_company_id", res.company_id);
          userData.company_id = localStorage.getItem("robotictech_companyid");
          getCompanyUserData(res.company_id).then((res_) => {
            localStorage.setItem("robotictech_company_name", res_.name);
            localStorage.setItem(
              "robotictech_company_logo",
              res_.logo_image_path
            );
          });
        });
      }

      userData.id = localStorage.getItem("robotictech_id");
      userData.role = localStorage.getItem("robotictech_role");
      userData.email = localStorage.getItem("robotictech_email");
      userData.first_name = localStorage.getItem("robotictech_firstname");
      userData.last_name = localStorage.getItem("robotictech_lastname");
      userData.company_id = localStorage.getItem("robotictech_companyid");

      return userData;
    } else {
      userData.id = localStorage.getItem("robotictech_id");
      userData.role = localStorage.getItem("robotictech_role");
      userData.email = localStorage.getItem("robotictech_email");
      userData.first_name = localStorage.getItem("robotictech_firstname");
      userData.last_name = localStorage.getItem("robotictech_lastname");
      userData.company_id = localStorage.getItem("robotictech_companyid");
      return userData;
    }
  } else {
    console.log("Não autenticado!");
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
      <Modal isOpen={true} centered>
        <ModalHeader>Por favor, realize o login novamente.</ModalHeader>
        <a className="btn btn-secondary" href="/login">
          Ok
        </a>
      </Modal>
    );
    // navigate("/login");
    return true;
  }
}

export default GetTokenApi;
