import React, { useState, useEffect } from "react";
import Header from "../../elements/Header";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import { BsArrowLeft } from "react-icons/bs";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import CheckPermission from "../Auth/CheckPermission";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

const EditMission = () => {
  document.title = "Editar Missão";
  let { projectId, missionId } = useParams();
  //const { keycloak } = useKeycloak();
  let apiUrl = process.env.REACT_APP_API_URL;
  const [modal, setModal] = useState(false);
  const [missionData, setMissionData] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
    button: "",
    buttonHref: "",
  });
  const toggleModal = (content) => {
    if (!content.title) content.title = "";
    if (!content.content) content.content = "";
    if (!content.button) content.button = "Ok";
    if (!content.buttonHref) content.buttonHref = "/missoes/" + projectId;

    setModalContent(content);
    setModal(!modal);
  };

  const [userPermission, setUserPermission] = useState(null);
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });

  async function getUserData() {
    let ret = false;
    await fetch(apiUrl + "/missions/" + missionId, {
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        ret = data;
        setMissionData(data);
      })
      .catch((error) => {
        toggleModal({
          title: "Editar projeto",
          content:
            "Houve um erro ao buscar os dados do projeto.\n\nDetalhes:\n" +
            error,
        });
        console.error("Error:", error);
      });
    return ret;
  }
  let fields_ = [
    {
      id: "name",
      name: "Nome",
      type: "text",
      initialValue: null,
      hidden: false,
    },
    {
      id: "description",
      name: "Descrição",
      type: "text",
      initialValue: null,
      hidden: false,
    },
    {
      id: "user_id",
      name: "Responsável",
      type: "text",
      initialValue: /*userProfile.firstName*/ 7,
      hidden: true,
    },
    {
      id: "project_id",
      name: "Id do projeto",
      type: "text",
      initialValue: projectId,
      hidden: true,
    },
  ];

  const [fields, setFields] = useState(fields_);

  useEffect(() => {
    if (userPermission)
      getUserData().then((res) => {
        Object.entries(res).forEach((ele) => {
          fields_.forEach((et) => {
            if (et["id"] === ele[0]) et["initialValue"] = ele[1];
          });
        });
        setFields(fields_);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission]);

  const createField = (fieldData) => {
    return (
      <FormGroup key={Math.random()} row hidden={fieldData["hidden"]}>
        <Label for={fieldData["id"]} sm={3}>
          {fieldData["name"]}
        </Label>
        <Col sm={9}>
          <Input
            id={fieldData["id"]}
            name={fieldData["id"]}
            placeholder={fieldData["name"]}
            type={fieldData["type"]}
            defaultValue={fieldData["initialValue"]}
          />
        </Col>
      </FormGroup>
    );
  };

  const onFormSubmit = (values) => {
    values.preventDefault();
    let fieldsAndValues = missionData;
    fields.forEach((ele) => {
      let value = values.target[ele.id].value;
      if (value) fieldsAndValues[ele.id] = value;
    });

    console.log(fieldsAndValues);

    fetch(apiUrl + "/missions/" + missionId, {
      method: "PUT",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
      body: JSON.stringify(fieldsAndValues),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        toggleModal({
          title: "Editar missão",
          content:
            "Missão alterada!\n\nVocê poderá vê-lo na página de missões.",
        });
      })
      .catch((error) => {
        toggleModal({
          title: "Editar missão",
          content: "Houve um erro ao editar a missão.\n\nDetalhes:\n" + error,
        });
        console.error("Error:", error);
      });
  };
  return (
    <div>
      <Modal isOpen={modal} toggle={toggleModal} backdrop="static">
        <ModalHeader>{modalContent.title}</ModalHeader>
        <ModalBody>{modalContent.content}</ModalBody>
        <ModalFooter>
          <Link
            className="btn btn-outline-secondary"
            to={modalContent.buttonHref}
          >
            {modalContent.button}
          </Link>
        </ModalFooter>
      </Modal>
      <Header />
      <Container
        className="p-0"
        style={{ backgroundColor: "white", borderRadius: "10px" }}
      >
        <Tooltip id="my-tooltip" />
        <div className="container" style={{ marginTop: "10vh " }}>
          <div
            id="basicInformation"
            className="container  /*main-content*/ recuadoOut"
          >
            <div className="row pt-4">
              <h2 className="col-10 m-0 text-start">
                {/* Inicio botão voltar pagina */}
                <div className="m-2">
                  <Link
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Voltar a Página Anterior"
                    className="text-end "
                    style={{ marginRight: "0px" }}
                    to={"/missoes/" + projectId}
                  >
                    <BsArrowLeft
                      className="nav-button-circle "
                      style={{ boxShadow: "none" }}
                    />
                  </Link>
                  Editar Missão
                </div>
                {/* Final Botão voltar pagina */}
              </h2>
              <div className="col-2 pr-0 m-0 row"></div>
            </div>
            <hr />
            <div
              className="main-content mt-0 p-3"
              style={{ display: "flex", padding: "5px" }}
            >
              <div style={{ width: "100%" }} id="uprojetos">
                {CheckPermission(
                  userPermission && userPermission.role
                    ? userPermission.role
                    : "",
                  "EMPLOYEE"
                ) ? (
                  <Form onSubmit={onFormSubmit}>
                    {fields.map((e) => {
                      return createField(e);
                    })}
                    <div className="row">
                      <Button
                        className="col-8 m-auto"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Salvar as alterações"
                      >
                        Salvar
                      </Button>
                    </div>
                  </Form>
                ) : (
                  <h4>Sem permissão para acessar esta área.</h4>
                )}

                {/* <Link onClick={() => toggleModal({title: 'teste titulo', content: 'teste content'})}>
                Abrir modal
              </Link> */}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EditMission;
