import React, { useState, useEffect, useRef } from "react";
import Header from "../../elements/Header";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Button,
  CardBody,
  Card,
  CardTitle,
  Spinner,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Form,
  Badge,
  Progress,
  FormText,
} from "reactstrap";
import {
  BsArrowLeft,
  BsFillCheckCircleFill,
  BsMenuButtonWideFill,
  BsFillXCircleFill,
  BsFillExclamationTriangleFill,
  BsFillCloudUploadFill,
  BsTrash,
  BsListUl,
  BsGrid,
  BsCardImage,
  BsCheck,
  BsClipboard,
  BsXCircle,
  BsXSquare,
} from "react-icons/bs";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import CheckPermission from "../Auth/CheckPermission";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import PhotoSphereViewer from "../../elements/PhotoSphereViewer/PhotoSphereViewer";
import api from "../../api/api";

const chunkSize = 100000000;

const missionStatus = [
  "Executando recorte do vídeo",
  "Executando atualização das imagens",
  "Executando Reconstrução 3D",
  "Executando detecção dos objetos",
  "Executando agrupamento das detecções",
  "Executando segmentação da via",
  "Executando atualização dos objetos"
];

const MissionImages = (props) => {
  document.title = "Imagens da Missão";
  let { id: missionId, projetoID: projectId } = useParams();
  let apiUrl = process.env.REACT_APP_API_URL;
  // const navigate = useNavigate();
  const [userPermission, setUserPermission] = useState(null);
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });

  const [listaImagensCarregada, setListaImagensCarregada] = useState(false);
  const [listImages, setListImages] = useState([]);
  const [listImagesMode, setListImagesMode] = useState("block");
  const [pagePaginationAPI, setPagePaginationAPI] = useState(12);
  const [listImagesToView, setListImagesToView] = useState(null);

  const [pageCountPaginationAPI, setPageCountPaginationAPI] = useState(12);
  const [pageSearchPaginationAPI, setPageSearchPaginationAPI] = useState("");
  const [count, setCount] = useState(false);
  const [listImagesContent, setListImagesContent] = useState();
  const [missionDetails, setMissionDetails] = useState();
  const [projectData, setProjectData] = useState();
  const [detalhesProcessamento, setDetalhesProcessamento] = useState({});
  const [loadingProcessamentoImagens, setLoadingProcessamentoImagens] =
    useState(false);

  const [axiosCancelToken, setAxiosCancelToken] = useState(
    axios.CancelToken.source()
  );
  const [progressBar, setProgressBar] = useState(0);

  // Begin controls to send/preview images upload
  const [listUploadImages, setListUploadImages] = useState([]);
  const [flagContinueSendImages, setFlagContinueSendImages] = useState(false);
  const [listUploadImagesPreviewError, setListUploadImagesPreviewError] =
    useState([]);
  // End controls to send/preview images upload

  // Begin controls to delete process
  const [listDeleteImages, setListDeleteImages] = useState([]);
  // End controls to delete process

  // Begin control loading
  const [loading, setLoading] = useState(false);
  // End control loading

  // Begin Controls Modal Detalhes
  const [modalDetalhes, setModalDetalhes] = useState(false);
  const [modalNotificacao, setModalNotificacao] = useState(false);

  const toggleModalDetalhes = () => setModalDetalhes(!modalDetalhes);
  const openModalDetalhes = (el) => {
    setModalActiveElement(el);
    setModalDetalhes(true);
  };
  const [modalActiveElement, setModalActiveElement] = useState(null);
  const [modalProcessarImagem, setModalProcessarImagem] = useState(false);
  const toggleModalProcessarImagem = () => {
    setCount(Math.random());
    setModalProcessarImagem(!modalProcessarImagem);
  };

  // Begin Controls Modal Exclusão imagens
  const [modalDeleteImages, setModalDeleteImages] = useState(false);
  // End Controls Modal Exclusão imagens

  // Begin Controls Modal Detalhes Upload Video
  const [modalUploadVideo, setModalUploadVideo] = useState(false);
  const [statusEnviandoVideo, setStatusEnviandoVideo] = useState(false);
  // End Controls Modal Upload Video

  // Begin controls modal pipeline
  const [modalPipeline, setModalPipeline] = useState(false);
  const [modalPipelineContent, setModalPipelineContent] = useState(false);
  // End controls modal pipeline

  // begin controls to get all missions

  const [listAllMissions, setListAllMissions] = useState(null);

  // Begin get all projects of the company
  const [selectedProjectToCopy, setSelectedProjectToCopy] = useState(false);

  const projectsToCopyRef = useRef(null);

  const [listProjects, setListProjects] = useState(null);
  const getProjects = () => {
    fetch(
      CheckPermission(
        userPermission && userPermission.role ? userPermission.role : "",
        "ADMIN"
      )
        ? apiUrl + "/projects?size=10000"
        : apiUrl +
            "/companies/" +
            localStorage.getItem("robotictech_company_id") +
            "/projects?size=10000",
      {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userPermission.access_token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((pulist) => {
        try {
          pulist = JSON.parse(pulist);
        } catch (error) {
          throw error;
        }
        setListProjects(pulist.content);
      })
      .finally(() => {
        getAllMissions();
      });
  };

  useEffect(() => {
    const newMission = JSON.parse(
      sessionStorage.getItem("robotictech_new_mission")
    );

    if (newMission) {
      setModalUploadVideo(true);
      sessionStorage.setItem("robotictech_new_mission", false);
    }
  }, []);
  // Begin get all projects of the company

  // Begin get all missions
  const getAllMissions = () => {
    fetch(apiUrl + "/missions?size=10000", {
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((mulist) => {
        try {
          mulist = JSON.parse(mulist);
        } catch (error) {}
        let missionList = [];
        if (mulist.content && mulist.content.length > 0) {
          missionList = mulist.content;
        }

        let auxListAllMissions = {};

        missionList.forEach((e) => {
          if (!auxListAllMissions[e.project_id]) {
            auxListAllMissions[e.project_id] = [];
          }
          let exists = false;
          auxListAllMissions[e.project_id].forEach((i) => {
            if (i.id === e.id) {
              exists = true;
            }
          });
          if (!exists) {
            auxListAllMissions[e.project_id].push(e);
          }
        });

        setListAllMissions(auxListAllMissions);

        return missionList;
      })
      .finally((res) => {});
  };
  // end get all missions

  // Begin controls modal cópia de imagens
  const [modalCopiaImagens, setModalCopiaImagens] = useState(false);
  const [modalCopiaImagensContent, setModalCopiaImagensContent] =
    useState(false);
  const openModalCopiaImagensContent = (title, body, cancel, confirm, ok) => {
    setModalCopiaImagensContent({
      title: title,
      body: body,
      cancel: cancel,
      confirm: confirm,
      ok: ok,
    });
    setModalCopiaImagens(true);
  };
  // End controls modal  cópia de imagens

  // Begin Controls Modal Delete
  const [modalDelete, setModalDelete] = useState(false);
  const [modalDeleteContent, setModalDeleteContent] = useState({});
  const toggleModalDelete = () => {
    setModalDelete(!modalDelete);
  };
  const closeModal = () => {
    // setPagePaginationAPI(0);
    // setListImages([]);
    setListImagesContent("Por favor recarregue a página.");
    setModalDelete(false);
    // setCount(Math.random());
  };
  const openModalDeleteContent = (id, icon, title, body) => {
    setModalDeleteContent({
      idImage: id,
      icon: icon,
      title: title,
      body: body,
      footer: true,
    });
    toggleModalDelete();
  };
  // End Controls Modal Delete

  const loadingIcon = () => {
    return (
      <div
        style={{
          backgroundColor: "rgba(0,0,0,.4)",
          zIndex: "3000",
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          //display: "flex",
          display: loading ? "flex" : "none",
        }}
      >
        <Spinner
          color="primary"
          style={{
            margin: "auto",
            width: "100px",
            height: "100px",
          }}
        >
          Loading...
        </Spinner>
      </div>
    );
  };

  // Begin batch delete process function

  const [currentDeleteImageItem, setCurrentDeleteImageItem] = useState(-1);

  useEffect(() => {
    if (currentDeleteImageItem >= 0) {
      if (currentDeleteImageItem === 0) {
        setFlagContinueSendImages(true);
      }
      if (flagContinueSendImages || currentDeleteImageItem === 0) {
        console.log("passou 1");
        if (listDeleteImages && listDeleteImages[currentDeleteImageItem]) {
          console.log("passou 2");
          setModalDeleteImages({
            title: "Apagando imagens",
            content: (
              <Row>
                <Progress
                  className="m-0 p-0"
                  style={{
                    height: "30px",
                    fontSize: ".6em",
                  }}
                  color={"success"}
                  value={
                    (currentDeleteImageItem / listDeleteImages.length) * 100
                  }
                >
                  {Math.ceil(
                    (currentDeleteImageItem / listDeleteImages.length) * 100
                  ) + "%"}
                </Progress>
                <div className="text-center">
                  Processadas: {currentDeleteImageItem} /{" "}
                  {listDeleteImages.length}
                </div>
              </Row>
            ),
            confirmButton: false,
            cancelButton: true,
          });
          deleteImage(listDeleteImages[currentDeleteImageItem])
            .then(() => {
              console.log("apagado", listDeleteImages[currentDeleteImageItem]);
              setModalDeleteImages({
                title: "Apagando imagens",
                content: (
                  <Row>
                    <Progress
                      className="m-0 p-0"
                      style={{
                        height: "30px",
                        fontSize: ".6em",
                      }}
                      color={"success"}
                      value={
                        ((currentDeleteImageItem + 1) /
                          listDeleteImages.length) *
                        100
                      }
                    >
                      {Math.ceil(
                        ((currentDeleteImageItem + 1) /
                          listDeleteImages.length) *
                          100
                      ) + "%"}
                    </Progress>
                    <div className="text-center">
                      Processadas: {currentDeleteImageItem + 1} /{" "}
                      {listDeleteImages.length}
                    </div>
                  </Row>
                ),
                confirmButton: false,
                cancelButton: true,
              });
              //setCount(Math.random());
            })
            .finally(() => {
              if (currentDeleteImageItem + 1 < listDeleteImages.length) {
                //deleteImagesProcess(currentDeleteImageItem);
                setCurrentDeleteImageItem(currentDeleteImageItem + 1);
              } else {
                setFlagContinueSendImages(false);
                // setCount(Math.random());
                setModalDeleteImages({
                  title: "Apagando imagens",
                  content: (
                    <Row>
                      <Progress
                        className="m-0 p-0"
                        style={{
                          height: "30px",
                          fontSize: ".6em",
                        }}
                        color={"success"}
                        value={
                          ((currentDeleteImageItem + 1) /
                            listDeleteImages.length) *
                          100
                        }
                      >
                        {Math.ceil(
                          ((currentDeleteImageItem + 1) /
                            listDeleteImages.length) *
                            100
                        ) + "%"}
                      </Progress>
                      <div className="text-center">
                        Processadas: {currentDeleteImageItem + 1} /{" "}
                        {listDeleteImages.length}
                      </div>
                    </Row>
                  ),
                  confirmButton: false,
                  cancelButton: false,
                  okButton: true,
                });

                setModalNotificacao({
                  title: "Apagando imagens",
                  content: "O processo de exclusão foi terminado.",
                });
                setCurrentDeleteImageItem(-1);
                let auxListImages = listImages;
                listDeleteImages.forEach((et) => {
                  auxListImages = auxListImages.filter(
                    (item) => item.id !== et
                  );
                });
                //setCount(Math.random());
                console.log(auxListImages);
                setListImages(null);
                setTimeout(() => {
                  setListImages(auxListImages);

                  let aux = pagePaginationAPI;
                  setPagePaginationAPI(0);
                  setTimeout(() => {
                    setCount(Math.random());
                    setPagePaginationAPI(aux);
                  }, 1);
                }, 10);

                setLoading(false);

                setListDeleteImages([]);
              }
            });
        }
      } else {
        window.location.reload(false);

        //setCount(Math.random());
        setFlagContinueSendImages(false);
        setModalNotificacao({
          title: "O envio foi cancelado",
          content: "O envio foi cancelado pelo usuário.",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDeleteImageItem]);
  // End batch delete process function

  /* Begin gunction delete image */

  const deleteImage = async (id) => {
    console.log(id);
    setModalDeleteContent({
      id: modalDeleteContent.id,
      icon: modalDeleteContent.icon,
      title: modalDeleteContent.title,
      body: (
        <Spinner
          className="m-auto"
          style={{ display: "block" }}
          color="primary"
        />
      ),
      footer: false,
    });
    return new Promise((resolve, reject) => {
      try {
        GetTokenApi().then((res) => {
          setUserPermission(res);
          fetch(apiUrl + "/images/" + id, {
            method: "DELETE",
            headers: {
              accept: "*/*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + userPermission.access_token,
            },
          })
            .then((response) => {
              if (response.ok) {
                return response.text();
              } else {
                setModalDeleteContent({
                  id: modalDeleteContent.id,
                  icon: <BsFillXCircleFill />,
                  title: modalDeleteContent.title,
                  body: "Houve uma falha de comunicação ao tentar excluir a imagem.",
                  footer: false,
                });
                reject("Erro.");
              }
            })
            .then((data) => {
              setModalDeleteContent({
                id: modalDeleteContent.id,
                icon: <BsFillCheckCircleFill />,
                title: modalDeleteContent.title,
                body: "Imagem apagada com sucesso.",
                footer: false,
              });
              //setCount(Math.random());
              resolve(data);
            })
            .catch((error) => {
              setModalDeleteContent({
                id: modalDeleteContent.id,
                icon: <BsFillXCircleFill />,
                title: modalDeleteContent.title,
                body: "Não foi possível apagar a imagem.",
                footer: false,
              });
              console.error("Error:", error);
              reject(error);
            })
            .finally(() => {});
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  /* End function delete image */

  /* Begin function process pipeline */

  const processarPipeline = () => {
    console.log(missionId);
    setModalPipelineContent({
      body: (
        <Spinner
          className="m-auto"
          style={{ display: "block" }}
          color="primary"
        />
      ),
      footer: false,
    });

    fetch(apiUrl + "/missions/" + missionId + "/process/classify", {
      method: "POST",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          setModalPipelineContent({
            body: "Houve uma falha na solicitação de processamento da pipeline.",
            footer: true,
          });
        }
      })
      .then((data) => {
        setModalPipelineContent({
          body: "Solicitação enviada.",
          footer: true,
        });
        setCount(Math.random());
      })
      .catch((error) => {
        setModalPipelineContent({
          body: "Não foi possível solicitar o processamento da pipeline.",
          footer: true,
        });
        console.error("Error:", error);
      })
      .finally(() => {});
  };

  /* End function process pipeline */

  /* Begin function images handle upload */

  const handleUpload = (files) => {
    let auxList = [];
    Array.from(files.target.files).forEach((e) => {
      auxList.push([e, "Aguardando"]);
    });
    setListUploadImages(auxList);
  };

  /* End function images handle upload */

  /* Begin function to copy images */

  // const [responseCopyImagesList, setResponseCopyImagesList] = useState({});

  const copyImages = (index, selectedMission, statusList) => {
    if (index === 0) {
      statusList = {};
    }
    //"/images/copy-to-mission-500033/500034,500035,500036,500037"

    console.log(listDeleteImages);
    GetTokenApi().then((res) => {
      setUserPermission(res);
      //setStatusEnviandoFilaImagens(true);
      console.log("Enviando ", listDeleteImages[index]);
      //let auxList = responseCopyImagesList;
      //auxList[index][0]._name = encodeURI(auxList[index][0].name);
      if (!statusList[listDeleteImages[index]])
        statusList[listDeleteImages[index]] = {};
      statusList[listDeleteImages[index]]["status"] = "Enviando";

      console.log(index + " - " + selectedMission, statusList);

      let apiUrlFomatted =
        apiUrl +
        "/images/copy-to-mission-" +
        selectedMission +
        "/" +
        listDeleteImages[index];

      fetch(apiUrlFomatted, {
        method: "POST",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userPermission.access_token,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            throw new Error("Erro");
          }
        })
        .then((data) => {
          try {
            data = JSON.parse(data);
          } catch (error) {}
          console.log(data);
          if (data[0].response === "OK")
            statusList[listDeleteImages[index]]["status"] = "Enviado";
          else {
            let msgTranslated = "";
            if (data[0].response === "AlREADY_EXIST")
              msgTranslated = "EXISTE NO DESTINO";
            else if (data[0].response === "ID_NOT_FOUND")
              msgTranslated = "NÃO ENCONTRADA";
            else if (data[0].response === "COPY_ERROR")
              msgTranslated = "ERRO DE CÓPIA";
            statusList[listDeleteImages[index]]["status"] = msgTranslated;
          }
          statusList[listDeleteImages[index]]["data"] = data[0];

          openModalCopiaImagensContent(
            "Copiando imagens selecionadas",
            <>
              <ModalBody>
                <>
                  <Progress
                    style={{
                      height: "30px",
                      fontSize: ".6em",
                    }}
                    color={"success"}
                    value={
                      (Object.entries(statusList).length /
                        listDeleteImages.length) *
                      100
                    }
                  >
                    {Math.ceil(
                      (Object.entries(statusList).length /
                        listDeleteImages.length) *
                        100
                    )}
                    %
                  </Progress>
                  <p>
                    {"Imagens copiadas: " +
                      Object.entries(statusList).filter((data) => {
                        return data[1]["status"] === "Enviado";
                      }).length +
                      "/" +
                      listDeleteImages.length}
                  </p>
                </>
                {statusList
                  ? Object.entries(statusList).map((item) => {
                      if (item[1]["status"] !== "Enviado")
                        return (
                          <Card key={Math.random()}>
                            <Row>
                              <Col className="ps-3 pe-3">
                                <small>
                                  <b>
                                    {item &&
                                    item[1] &&
                                    item[1]["data"] &&
                                    item[1]["data"].name
                                      ? item[1]["data"].name
                                      : ""}
                                  </b>
                                </small>
                              </Col>
                              <Col style={{ textAlign: "center" }} sm={4}>
                                <Row>
                                  <small>
                                    <Badge
                                      className="m-auto"
                                      style={{ width: "100%" }}
                                      color={
                                        item[1]["status"] === "Enviado"
                                          ? "success"
                                          : item[1]["status"] === "COPY_ERROR"
                                          ? "danger"
                                          : "secondary"
                                      }
                                    >
                                      {item[1]["status"]}
                                    </Badge>
                                  </small>
                                </Row>
                              </Col>
                            </Row>
                          </Card>
                        );
                      else return false;
                    })
                  : ""}
              </ModalBody>
            </>
          );
        })
        .finally(() => {
          if (index < listDeleteImages.length - 1) {
            copyImages(index + 1, selectedMission, statusList);
          } else {
            setModalNotificacao({
              title: "A cópia de imagens terminou",
              content:
                "As imagens foram copiadas, verifique a lista para imagens que não puderam ser processadas.",
            });
            console.log("terminado");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    });
  };

  /* End function to copy images */

  /* Begin function form handle upload video */

  const startMultipartUpload = async (file, folderName) => {
    let totalSize = file.size;
    let numOfParts = Math.ceil(totalSize / chunkSize);

    const response = await api.post(
      `${apiUrl}/missions/${missionId}/images/upload-video/start`,
      {
        fileName: file.name,
        folderName: folderName,
        numberOfParts: numOfParts,
      },
      {
        cancelToken: axiosCancelToken.token,
      }
    );

    return response.data;
  };

  const uploadFileParts = async (
    presignedURLs,
    video,
    currentVideoIndex,
    numOfVideos
  ) => {
    let partETags = [];

    const maxPercentageForVideo = 100 / numOfVideos;
    const maxPercentageForEachPart =
      maxPercentageForVideo / presignedURLs.length;

    for (let i = 0; i < presignedURLs.length; i++) {
      const url = presignedURLs[i];
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, video.size);
      const chunk = video.slice(start, end);

      const uploadResponse = await axios.put(url, chunk, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        cancelToken: axiosCancelToken.token,
        onUploadProgress(progressEvent) {
          const percentageCompleted =
            (progressEvent.loaded / progressEvent.total) * 100;
          const currentPartProgress =
            (i + percentageCompleted / 100) * maxPercentageForEachPart;
          const totalProgress =
            (currentVideoIndex * maxPercentageForVideo + currentPartProgress) /
            100;
          setProgressBar(totalProgress);
        },
      });

      partETags.push({
        eTag: uploadResponse.headers.etag,
        partNumber: i + 1,
      });
    }

    return partETags;
  };

  const completeMultipartUpload = async (fileKey, uploadId, partETags) => {
    const response = await api.post(
      `${apiUrl}/missions/${missionId}/images/upload-video/finish`,
      {
        fileKey,
        uploadId,
        partETags,
      },
      {
        cancelToken: axiosCancelToken.token,
      }
    );

    return response.data;
  };

  const startPipeline = async (folderName) => {
    await api.post(
      `${apiUrl}/missions/${missionId}/images/upload-video/start-pipeline`,
      {
        folderName: folderName,
      }
    );
  };

  const abortMultiparUpload = async (fileKey, uploadId) => {
    await api.post(
      `${apiUrl}/missions/${missionId}/images/upload-video/abort`,
      {
        fileKey: fileKey,
        uploadId: uploadId,
      }
    );
  };

  const handleSubmitUploadVideo = (e) => {
    e.preventDefault();

    GetTokenApi().then((res) => {
      setUserPermission(res);
      setStatusEnviandoVideo(true);

      const videos = Array.from(e.target.video.files);

      let folderName = null;
      let fileKey = null;
      let uploadId = null;

      const uploadFilesToPresignedUrl = async () => {
        try {
          for (let i = 0; i < videos.length; i++) {
            const response = await startMultipartUpload(videos[i], folderName);

            fileKey = response.fileKey;
            uploadId = response.uploadId;
            folderName = response.folderName;

            const presignedURLs = response.presignedURLs;

            const partETags = await uploadFileParts(
              presignedURLs,
              videos[i],
              i,
              videos.length
            );

            await completeMultipartUpload(fileKey, uploadId, partETags);
          }

          startPipeline(folderName);

          setModalNotificacao({
            title: "O envio foi terminado",
            content: "Os vídeos foram enviados.",
          });
          setModalUploadVideo(false);
        } catch (error) {
          const errorMessage = error.message;

          if (error.code === "ERR_CANCELED") {
            await abortMultiparUpload(fileKey, uploadId);
          }

          setModalNotificacao({
            title: "O envio foi terminado",
            content: errorMessage,
          });
        } finally {
          setAxiosCancelToken(axios.CancelToken.source());
          setTimeout(() => {
            setProgressBar(0);
          }, 2000);
          setStatusEnviandoVideo(false);
        }
      };

      uploadFilesToPresignedUrl();
    });
  };
  /* End function form handle upload video */

  /* Begin function processar missão */

  const processarMissao = (id) => {
    setLoadingProcessamentoImagens(true);
    fetch(apiUrl + "/missions/" + id + "/process/start", {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setModalNotificacao({
            title: "Processamento iniciado",
            content:
              "O processamento foi iniciado, acompanhe pelo status da missão",
            actionButton: () => window.location.reload(false),
          });
          setModalProcessarImagem(false);
        }
        return response.text();
      })
      .then((data) => {
        console.log(data);
        setCount(Math.random());
      })
      .catch((error) => {
        setModalNotificacao({
          title: "Processamento não pode ser iniciado",
          content: "O processamento não pode ser iniciado. Erro:" + error,
        });
        setModalProcessarImagem(false);
        console.error("Error:", error);
      })
      .finally(() => {
        setLoadingProcessamentoImagens(false);
      });
  };

  const processarMissaoStop = (id) => {
    setLoadingProcessamentoImagens(true);

    fetch(apiUrl + "/missions/" + id + "/process/stop", {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setModalNotificacao({
            title: "Processamento interrompido",
            content:
              "O processamento foi interrompido, acompanhe pelo status da missão",
            actionButton: () => window.location.reload(false),
          });
          setModalProcessarImagem(false);
        }
        // try {
        //   response.json();
        // } catch (error) {
        return response.text();
        // }
      })
      .then((data) => {
        console.log(data);
        setCount(Math.random());
      })
      .catch((error) => {
        setModalNotificacao({
          title: "Processamento não pode ser interrompido",
          content: "O processamento não pode ser interrompido. Erro:" + error,
        });
        setModalProcessarImagem(false);
        console.error("Error:", error);
      })
      .finally(() => {
        setLoadingProcessamentoImagens(false);
      });
  };

  /* End function processar missão */

  function doisDigitos(number) {
    var output = number + "";
    while (output.length < 2) {
      output = "0" + output;
    }
    return output;
  }

  function formatData(element) {
    if (element) {
      let dia =
        doisDigitos(element[2]) +
        "/" +
        doisDigitos(element[1]) +
        "/" +
        element[0];
      return dia;
    }
  }

  useEffect(() => {
    if (userPermission) {
      setListImages([]);
      setPagePaginationAPI(pageCountPaginationAPI);
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCountPaginationAPI]);

  useEffect(() => {
    let auxList = [];
    if (listImagesMode === "list") {
      auxList.push(
        <Col className="m-0" xs={12} key={Math.random()}>
          <Row className="p-0">
            {projectData &&
            projectData.status &&
            projectData.status !== "Finalizado" &&
            missionDetails &&
            missionDetails.status &&
            missionDetails.status !== "Processamento finalizado" ? (
              <Col
                className="m-0 row d-none d-sm-none d-md-block"
                xs={1}
                sm={1}
                md={1}
                lg={1}
              ></Col>
            ) : (
              ""
            )}
            <Col
              xs={1}
              sm={7}
              md={4}
              lg={3}
              className="m-0 col-12 mt-auto mb-auto"
            >
              <small>
                <strong>Nome</strong>
              </small>
            </Col>
            <Col
              sm={3}
              md={2}
              className="m-0 mt-auto mb-auto text-center d-none d-sm-block"
            >
              <small>
                <strong>Captura</strong>
              </small>
            </Col>
            <Col
              sm={3}
              md={2}
              className="m-0 mt-auto mb-auto text-center d-none d-md-none d-lg-block"
            >
              <small>
                <strong>Upload</strong>
              </small>
            </Col>
            <Col
              sm={3}
              md={4}
              lg={3}
              className="m-0 mt-auto mb-auto text-center d-none d-sm-none d-md-block"
            >
              <small>
                <strong>Latitude/Longitude</strong>
              </small>
            </Col>
            {projectData &&
            projectData.status &&
            projectData.status !== "Finalizado" &&
            missionDetails &&
            missionDetails.status &&
            missionDetails.status !== "Processamento finalizado" ? (
              <Col
                sm={2}
                md={1}
                lg={1}
                className="m-0 mt-auto mb-auto text-center d-none d-sm-block"
              >
                <small>
                  <strong>Excluir </strong>
                </small>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Col>
      );
    }
    if (listImages)
      listImages.forEach((el) => {
        if (
          !pageSearchPaginationAPI ||
          pageSearchPaginationAPI === "" ||
          el.id.toString().includes(pageSearchPaginationAPI) ||
          el.mission_id.toString().includes(pageSearchPaginationAPI) ||
          el.path
            .split("/")
            [el.path.split("/").length - 1].includes(pageSearchPaginationAPI) ||
          el.upload_date.toString().includes(pageSearchPaginationAPI) ||
          el.capture_date.toString().includes(pageSearchPaginationAPI)
        )
          if (listImagesMode === "block") {
            auxList.push(
              <Col
                key={Math.random()}
                className="col-12"
                sm={6}
                md={4}
                lg={3}
                style={{
                  padding: ".5em",
                }}
              >
                <Card key={Math.random()}>
                  <CardBody
                    style={{
                      padding: "0",
                    }}
                  >
                    <CardTitle
                      tag="h5"
                      style={{
                        padding: "0.5em",
                      }}
                    >
                      <Row>
                        <Col className="mt-auto mb-auto ps-1 pe-1">
                          {/* #{el.id} */}
                          <Row className="m-0 p-0">
                            {projectData && missionDetails ? (
                              <Col className="col-1 mt-auto mb-auto">
                                <div
                                  className="p-0 m-0"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    border: "1px solid #CCC",
                                    borderRadius: "6px",
                                  }}
                                  onClick={() => {
                                    let aux = listDeleteImages;
                                    setListDeleteImages(null);
                                    if (
                                      listDeleteImages.filter(
                                        (item) => item === el.id
                                      ).length > 0
                                    ) {
                                      aux = listDeleteImages.filter(
                                        (item) => item !== el.id
                                      );
                                    } else {
                                      aux.push(el.id);
                                    }
                                    setCount(Math.random());
                                    setListDeleteImages(aux);
                                  }}
                                >
                                  {listDeleteImages
                                    ? listDeleteImages.map((item) => {
                                        let returnItem = null;
                                        if (item === el.id)
                                          returnItem = (
                                            <BsCheck
                                              key={Math.random()}
                                              className=""
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                marginTop: "-10px",
                                              }}
                                            />
                                          );
                                        return returnItem;
                                      })
                                    : ""}
                                </div>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col
                              className="ms-3 me-0 ms-1 p-0"
                              onClick={() => {
                                let aux = listDeleteImages;
                                setListDeleteImages(null);
                                if (
                                  listDeleteImages.filter(
                                    (item) => item === el.id
                                  ).length > 0
                                ) {
                                  aux = listDeleteImages.filter(
                                    (item) => item !== el.id
                                  );
                                } else {
                                  aux.push(el.id);
                                }
                                setCount(Math.random());
                                setListDeleteImages(aux);
                              }}
                            >
                              <small
                                className="text-start"
                                style={{ fontSize: "0.6em" }}
                              >
                                {decodeURI(
                                  el.path.split("/")[
                                    el.path.split("/").length - 1
                                  ]
                                )}
                              </small>
                            </Col>
                          </Row>
                        </Col>
                        {projectData &&
                        projectData.status &&
                        projectData.status !== "Finalizado" &&
                        missionDetails &&
                        missionDetails.status &&
                        missionDetails.status !== "Processamento finalizado" ? (
                          <>
                            <Link
                              className="mt-auto mb-auto mr-5 btn btn-item-list p-0"
                              type="button"
                              style={{
                                height: "28px",
                                width: "28px",
                              }}
                              onClick={() => {
                                openModalDeleteContent(
                                  el.id,
                                  <BsFillExclamationTriangleFill />,
                                  "Imagem #" + el.id,
                                  "Deseja realmente apagar a imagem #" +
                                    el.id +
                                    "?",
                                  true
                                );
                              }}
                            >
                              <BsTrash />
                            </Link>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </CardTitle>
                    <Row>
                      <img
                        alt="loaded img"
                        onClick={() => openModalDetalhes(el)}
                        style={{ width: "100%", cursor: "pointer" }}
                        src={el.path_low}
                      />
                    </Row>
                    <Row>
                      <Col className="text-center">
                        <small>
                          {el.capture_date
                            ? "Capturada em: " + formatData(el.capture_date)
                            : ""}
                        </small>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            );
          } else {
            auxList.push(
              <Col
                className="border rounded pt-1 pb-1 pr-4 pl-4"
                style={{ cursor: "pointer" }}
                sm={12}
              >
                <Row className="m-0">
                  <Col xs={1} sm={1} md={1} className="m-auto me-2">
                    <div
                      className="p-0 m-0"
                      style={{
                        width: "20px",
                        height: "20px",
                        border: "1px solid #CCC",
                        borderRadius: "6px",
                      }}
                      onClick={() => {
                        let aux = listDeleteImages;
                        setListDeleteImages(null);
                        if (
                          listDeleteImages.filter((item) => item === el.id)
                            .length > 0
                        ) {
                          aux = listDeleteImages.filter(
                            (item) => item !== el.id
                          );
                        } else {
                          aux.push(el.id);
                        }
                        setCount(Math.random());
                        setListDeleteImages(aux);
                      }}
                    >
                      {listDeleteImages
                        ? listDeleteImages.map((item) => {
                            let returnItem = null;
                            if (item === el.id)
                              returnItem = (
                                <BsCheck
                                  key={Math.random()}
                                  className=""
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    marginTop: "-10px",
                                  }}
                                />
                              );
                            return returnItem;
                          })
                        : ""}
                    </div>
                  </Col>
                  <Col className="m-auto col p-0">
                    <Row
                      className="col-12 m-0 p-0"
                      onClick={() => openModalDetalhes(el)}
                    >
                      <Col
                        xs={12}
                        sm={8}
                        md={5}
                        lg={4}
                        className="ms-0 me-0 mt-auto mb-auto row p-0"
                      >
                        {/* #{el.id} */}
                        <Col className="col-2">
                          <BsCardImage className="ms-0 mt-auto mb-auto me-3 p-0" />
                        </Col>
                        <Col className="col">
                          <small>
                            {decodeURI(
                              el.path.split("/")[el.path.split("/").length - 1]
                            )}
                          </small>
                        </Col>
                      </Col>
                      <Col
                        sm={3}
                        md={2}
                        className="mt-auto mb-auto text-center d-none d-sm-block"
                      >
                        <small>{formatData(el.capture_date)}</small>
                      </Col>
                      <Col
                        sm={3}
                        md={2}
                        className="mt-auto mb-auto text-center d-none d-md-none d-lg-block"
                      >
                        <small>{formatData(el.upload_date)}</small>
                      </Col>
                      <Col
                        md={4}
                        lg={3}
                        className="mt-auto mb-auto text-center d-none d-sm-none d-md-block"
                      >
                        <small>
                          <div>
                            {el.latitude.toString().substring(0, 10)},
                            {el.longitude.toString().substring(0, 10)}
                          </div>
                        </small>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={2} sm={2} md={1} className="text-center">
                    {projectData &&
                    projectData.status &&
                    projectData.status !== "Finalizado" &&
                    missionDetails &&
                    missionDetails.status &&
                    missionDetails.status !== "Processamento finalizado" ? (
                      <Link
                        className="mt-auto mb-auto mr-5 btn btn-item-list p-0"
                        type="button"
                        style={{
                          height: "28px",
                          width: "28px",
                        }}
                        onClick={() => {
                          openModalDeleteContent(
                            el.id,
                            <BsFillExclamationTriangleFill />,
                            "Imagem #" + el.id,
                            "Deseja realmente apagar a imagem #" + el.id + "?",
                            true
                          );
                        }}
                      >
                        <BsTrash />
                      </Link>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </Col>
            );
          }
      });
    setListImagesContent(auxList);

    setListImagesToView(null);
    let aux = [];
    if (auxList && auxList.length > 0) {
      let cont = 0;
      auxList.filter((item) => {
        if (cont++ < pagePaginationAPI) {
          aux.push(item);
          return true;
        } else return false;
      });
    } else if (!listaImagensCarregada) {
      aux.push(<div key={Math.random()}>Carregando...</div>);
    } else {
      aux.push(<div key={Math.random()}>Sem imagens disponíveis.</div>);
    }
    setListImagesToView(aux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listImages,
    listImagesMode,
    count,
    pageSearchPaginationAPI,
    pagePaginationAPI,
  ]);

  const getProjectData = () => {
    fetch(apiUrl + "/projects/" + projectId, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((pulist) => {
        try {
          pulist = JSON.parse(pulist);
        } catch (error) {}
        setProjectData(pulist);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  function getData() {
    let auxList = listImages;
    if (listImages && listImages.length <= 0)
      setListImagesContent(<Spinner color="primary">Loading...</Spinner>);

    fetch(apiUrl + "/missions/" + missionId, {
      method: "GET",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setMissionDetails(data);
        //getMissionOfProject();
        getProjectData();
        getProjects();
        fetch(
          apiUrl +
            "/missions/" +
            missionId +
            "/images?size=" +
            10000 +
            "&page=0&sort=name",
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + userPermission.access_token,
            },
          }
        )
          .then((response) => {
            if (response.ok) {
              return response.text();
            } else {
              throw new Error("Erro");
            }
          })
          .then((data) => {
            try {
              data = JSON.parse(data);
            } catch (error) {}
            let auxDetalhesProcessamento = detalhesProcessamento;
            auxDetalhesProcessamento["totalElements"] = data.totalElements;
            auxDetalhesProcessamento["last"] = data.last;
            setDetalhesProcessamento(auxDetalhesProcessamento);

            setListaImagensCarregada(true);

            data.content.forEach((imageInApi) => {
              let exists = false;
              auxList.forEach((imageInList) => {
                if (imageInList.id === imageInApi.id) exists = true;
              });
              if (!exists) auxList.push(imageInApi);
            });

            setListImages(auxList);
            setCount(Math.random());
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  useEffect(() => {
    if (userPermission) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission]);

  useEffect(() => {
    setListImagesToView(null);
    let aux = [];
    if (listImagesContent && listImagesContent.length > 0) {
      let cont = 0;
      listImagesContent.filter((item) => {
        if (cont++ <= pagePaginationAPI) {
          aux.push(item);
          return true;
        } else return false;
      });
    } else if (!listaImagensCarregada) {
      aux.push(<div>Carregando...</div>);
    } else {
      aux.push(<div>Sem imagens disponíveis.</div>);
    }
    setListImagesToView(aux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let auxList = [];
    if (listUploadImages) {
      //setListUploadImagesPreviewError([]);
      listUploadImages.forEach((e) => {
        if (e[1] !== "Enviado" && e[1] !== "Aguardando" && e[1] !== "Enviando")
          auxList.push(
            <Card key={Math.random()}>
              <Row>
                <Col>
                  <b>{e && e[0] && e[0].name ? e[0].name : ""} </b>
                  <small>
                    ({Math.floor((e[0].size / 1024 / 1024) * 100) / 100} MB
                  </small>
                  )
                </Col>
                <Col style={{ textAlign: "center" }} sm={4}>
                  <small>
                    <Badge
                      style={{ width: "100%" }}
                      color={
                        e[1] === "Enviado"
                          ? "success"
                          : e[1] === "Não Enviado"
                          ? "danger"
                          : "secondary"
                      }
                    >
                      {e[1]}
                    </Badge>
                  </small>
                </Col>
              </Row>
            </Card>
          );
      });
      setListUploadImagesPreviewError(auxList);
    }
  }, [listUploadImages, count]);

  function getCurrentMissionStatusStep(currentMissionStatus) {
    let indexMission = missionStatus.indexOf(currentMissionStatus);
  
    if (indexMission === -1) {
      return "";
    }
  
    return `(${indexMission + 1}/${missionStatus.length})`;
  }

  return (
    <div>
      {loadingIcon()}
      <Header />
      {/* Início modal de notificação */}
      <Modal // Modal de notificação
        isOpen={modalNotificacao ? true : false}
      >
        <ModalHeader>
          {modalNotificacao && modalNotificacao.title
            ? modalNotificacao.title
            : ""}
        </ModalHeader>
        <ModalBody>
          <div className="row m-1">
            {modalNotificacao && modalNotificacao.content
              ? modalNotificacao.content
              : ""}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={
              modalNotificacao && modalNotificacao.actionButton
                ? modalNotificacao.actionButton
                : () => setModalNotificacao(false)
            }
          >
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      {/* Fim modal de notificação */}

      {/* Início modal de exclusão de imagens */}
      <Modal
        isOpen={modalDeleteImages ? true : false}
        toggle={() => {
          if (flagContinueSendImages) {
            setFlagContinueSendImages(false);
          } else {
            setModalDeleteImages(false);
            setCount(Math.random());
          }
        }}
      >
        <ModalHeader
          toggle={() => {
            if (flagContinueSendImages) setFlagContinueSendImages(false);
            else {
              setModalDeleteImages(false);
              setCount(Math.random());
            }
          }}
        >
          {modalDeleteImages && modalDeleteImages.title
            ? modalDeleteImages.title
            : "Apagar imagens"}
        </ModalHeader>
        <ModalBody>
          <div className="row m-1">
            {modalDeleteImages && modalDeleteImages.content
              ? modalDeleteImages.content
              : ""}
          </div>
        </ModalBody>
        <ModalFooter>
          {modalDeleteImages && modalDeleteImages.okButton ? (
            <>
              <Button
                color="outline-secondary"
                onClick={() => {
                  if (flagContinueSendImages) setFlagContinueSendImages(false);
                  else {
                    setModalDeleteImages(false);
                    setCount(Math.random());
                  }
                }}
              >
                Ok
              </Button>
            </>
          ) : (
            ""
          )}

          {modalDeleteImages && modalDeleteImages.cancelButton ? (
            <>
              <Button
                color="outline-secondary"
                onClick={() => {
                  if (flagContinueSendImages) setFlagContinueSendImages(false);
                  else {
                    setModalDeleteImages(false);
                    setCount(Math.random());
                  }
                }}
              >
                Cancelar
              </Button>
            </>
          ) : (
            ""
          )}
          {modalDeleteImages && modalDeleteImages.confirmButton ? (
            <>
              <Button
                color="success"
                onClick={() => setCurrentDeleteImageItem(0)}
              >
                Continuar
              </Button>
            </>
          ) : (
            ""
          )}
        </ModalFooter>
      </Modal>
      {/* Fim modal de exclusão de imagens */}

      {/* Início modal de detalhes da imagem */}
      <Modal // Modal detalhes
        isOpen={modalDetalhes}
        toggle={toggleModalDetalhes}
        fullscreen
        className="pb-4"
      >
        <ModalHeader toggle={toggleModalDetalhes}>
          {modalActiveElement ? modalActiveElement.id : ""}
        </ModalHeader>
        <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
          <PhotoSphereViewer imgSrc={modalActiveElement} height="100%" />
        </div>
      </Modal>
      {/* Fim modal de detalhes da imagem */}
      {/* Início modal de exclusão de imagem */}
      <Modal // Modal delete
        isOpen={modalDelete}
        toggle={toggleModalDelete}
        backdrop="static"
      >
        <ModalHeader toggle={closeModal}>
          <div className="row m-0">
            <div className="col-2 m-auto">
              {modalDeleteContent.icon ? modalDeleteContent.icon : ""}
            </div>
            <div className="col m-auto">
              {modalDeleteContent.title ? modalDeleteContent.title : ""}
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          {modalDeleteContent.body ? modalDeleteContent.body : ""}
        </ModalBody>
        {modalDeleteContent.footer ? (
          <ModalFooter>
            <Button color="secondary" onClick={toggleModalDelete}>
              Cancelar
            </Button>
            <Button
              color="danger"
              onClick={() => deleteImage(modalDeleteContent.idImage)}
            >
              Apagar
            </Button>
          </ModalFooter>
        ) : (
          ""
        )}
      </Modal>
      {/* Fim modal de exclusão de imagem */}

      {/* Início modal de cópia de imagem */}
      <Modal // Modal copy
        isOpen={modalCopiaImagens}
        toggle={() => setModalCopiaImagens(false)}
        backdrop="static"
        className="p-0"
      >
        <ModalHeader toggle={() => setModalCopiaImagens(false)}>
          <div className="row m-0">
            <div className="col m-auto">
              {modalCopiaImagensContent && modalCopiaImagensContent.title
                ? modalCopiaImagensContent.title
                : ""}
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="m-0 p-0">
          {modalCopiaImagensContent && modalCopiaImagensContent.body
            ? modalCopiaImagensContent.body
            : ""}
        </ModalBody>
      </Modal>
      {/* Fim modal de cópia de imagem */}

      {/* Início modal de processar pipeline */}
      <Modal // Modal delete
        isOpen={modalPipeline}
        toggle={() => setModalPipeline(false)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalPipeline(false)}>
          <div className="row m-0">
            <div className="col-12 m-auto">Processar pipeline</div>
          </div>
        </ModalHeader>
        <ModalBody>
          {missionDetails &&
          missionDetails.status &&
          (missionDetails.status === "Aguardando processamento" ||
            missionDetails.status === "Completed" ||
            missionDetails.status === "Processamento finalizado")
            ? modalPipelineContent && modalPipelineContent.body
              ? modalPipelineContent.body
              : "Deseja processar a pipeline da missão?"
            : "A missão está sendo processada, aguarde a finalização para solicitar o processamento da pipeline."}
        </ModalBody>
        <ModalFooter>
          {missionDetails &&
          missionDetails.status &&
          (missionDetails.status === "Aguardando processamento" ||
            missionDetails.status === "Completed" ||
            missionDetails.status === "Processamento finalizado") ? (
            !modalPipelineContent || !modalPipelineContent.footer ? (
              <>
                <Button
                  color="secondary"
                  onClick={() => setModalPipeline(false)}
                >
                  Cancelar
                </Button>
                <Button color="success" onClick={() => processarPipeline()}>
                  Sim
                </Button>
              </>
            ) : modalPipelineContent.footer ? (
              <Button
                color="secondary"
                onClick={() => window.location.reload(false)}
              >
                Ok
              </Button>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </ModalFooter>
      </Modal>
      {/* Fim modal de processar pipeline */}

      {/* Início modal de upload de video */}

      {/* <ImagesUpload isOpen={[modalUpload, setModalUpload]} /> */}

      <Modal // Modal upload video
        isOpen={modalUploadVideo}
        toggle={!statusEnviandoVideo ? () => setModalUploadVideo(false) : false}
        backdrop="static"
      >
        <ModalHeader
          toggle={
            !statusEnviandoVideo ? () => setModalUploadVideo(false) : false
          }
        >
          <div className="row m-0">
            <div className="col-2 m-auto">
              <BsFillCloudUploadFill />
            </div>
            <div className="col m-auto">Enviar vídeos...</div>
          </div>
        </ModalHeader>
        <ModalBody>
          {!statusEnviandoVideo ? (
            <Form onSubmit={(e) => handleSubmitUploadVideo(e)}>
              <FormGroup>
                <>
                  <div>
                    <Label for="fileVideo">Arquivos de vídeo (INSV)</Label>
                    <Input
                      id="fileVideo"
                      name="video"
                      multiple={true}
                      type="file"
                      required
                      onChange={(records) => handleUpload(records)}
                      accept=".insv"
                    />
                    <FormText>Selecione os arquivos a serem enviados.</FormText>
                  </div>
                  <Button outline className="col-12">
                    Enviar
                  </Button>
                </>
              </FormGroup>
            </Form>
          ) : (
            <>
              <div>Enviando Vídeos</div>
              <Progress
                className="mt-2 mb-2"
                style={{
                  height: "20px",
                }}
                color={"success"}
                value={progressBar && progressBar * 100}
              >
                {Math.ceil(progressBar && progressBar * 1000) / 10}%
              </Progress>
              <Row className="text-end p-2">
                <div
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    setProgressBar(0);
                    axiosCancelToken.cancel("Cancelado pelo usuário");
                    // source.cancel();
                    // abortController.abort();
                  }}
                >
                  Cancelar
                </div>
              </Row>
            </>
          )}
          <Row>{listUploadImagesPreviewError}</Row>
        </ModalBody>
      </Modal>
      {/* Fim modal de upload de video */}

      {/* Begin modal de processar imagens */}
      <Modal // Modal processar missão
        isOpen={modalProcessarImagem}
        toggle={toggleModalProcessarImagem}
        backdrop="static"
      >
        <ModalHeader toggle={toggleModalProcessarImagem}>
          Detalhes do processamento da missão #{missionId}
        </ModalHeader>
        {loadingProcessamentoImagens ? (
          <ModalBody className="text-center">
            <Spinner color="primary">Loading...</Spinner>
          </ModalBody>
        ) : missionDetails &&
          missionDetails.status &&
          missionDetails.status === "Aguardando imagens" ? (
          <ModalBody>
            <div className="row m-1">
              É necessário enviar pelo menos uma imagem.
            </div>
          </ModalBody>
        ) : // (missionDetails && !missionDetails.status) ||
        missionDetails &&
          missionDetails.status &&
          (missionDetails.status === "Aguardando processamento" ||
            missionDetails.status === "Processamento finalizado") ? (
          <>
            <ModalBody>
              <div className="row m-1">
                {detalhesProcessamento &&
                detalhesProcessamento.totalElements ? (
                  <div>
                    {missionDetails.status &&
                    (missionDetails.status === "Completed" ||
                      missionDetails.status === "Processamento finalizado")
                      ? "Esta ação irá reprocessar "
                      : "Esta ação irá processar "}
                    {detalhesProcessamento.totalElements} imagens.
                  </div>
                ) : (
                  ""
                )}
                <div>
                  Deseja continuar com o processamento das imagens da missão?
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModalProcessarImagem}>
                Cancelar
              </Button>
              <Button
                color="success"
                onClick={() => processarMissao(missionId)}
              >
                Processar
              </Button>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalBody>
              <Row className="m-1">
                Já existe um processamento sendo executado nesta missão.
              </Row>
              <Row className="m-1">Deseja interromper?</Row>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModalProcessarImagem}>
                Fechar
              </Button>
              <Button
                color="success"
                onClick={() => processarMissaoStop(missionId)}
              >
                Interromper
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
      {/* End modal de processar imagens */}

      <div className="container" style={{ marginTop: "10vh " }}>
        <Tooltip id="my-tooltip" />
        <div
          id="basicInformation"
          className="container /*main-content*/ recuadoOut"
        >
          <div className="row pt-4">
            <div className="row col-12 m-0 text-start">
              <Row className="m-0">
                <Col className="mt-auto mb-auto">
                  <h2 className="col text-start">
                    {/* Inicio botão voltar pagina */}
                    <div className="m-2 ms-0" style={{ display: "flex" }}>
                      <Link
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Voltar a Página Anterior"
                        className="text-end "
                        style={{ marginRight: "0px" }}
                        to={"/missoes/" + projectId}
                        //to={"/home"}
                      >
                        <BsArrowLeft
                          className="nav-button-circle ms-0"
                          style={{ boxShadow: "none" }}
                        />
                      </Link>
                      <p className="text-start">
                        {missionDetails && missionDetails.name
                          ? missionDetails.name
                          : ""}{" "}
                        (#{missionId})
                      </p>
                    </div>
                    {/* Final Botão voltar pagina */}
                  </h2>
                  {missionDetails && missionDetails.name ? (
                    <div className="col">
                      <small>Nome: {missionDetails.name}</small>
                    </div>
                  ) : (
                    ""
                  )}
                  {missionDetails && missionDetails.description ? (
                    <div className="col">
                      <small>Descrição: {missionDetails.description}</small>
                    </div>
                  ) : (
                    ""
                  )}
                  {detalhesProcessamento &&
                  detalhesProcessamento.totalElements ? (
                    <div className="col">
                      <small>
                        Exibindo{" "}
                        {pagePaginationAPI <=
                        detalhesProcessamento.totalElements
                          ? pagePaginationAPI
                          : detalhesProcessamento.totalElements}{" "}
                        de {detalhesProcessamento.totalElements} imagens
                      </small>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
                <Col lg={5} className="row m-0 p-0">
                  <Row className="text-end m-0 p-2">
                    {missionDetails && missionDetails.status ? (
                      <Badge
                        key={Math.random()}
                        className="ms-auto me-4 mt-auto mb-auto"
                        color={
                          missionDetails.status &&
                          missionDetails.status === "Sem previsão"
                            ? "warning"
                            : missionDetails.status.indexOf("passo") !== -1
                            ? "warning"
                            : missionDetails.status === "Concluído" ||
                              missionDetails.status === "Completed" ||
                              missionDetails.status ===
                                "Processamento finalizado"
                            ? "success"
                            : "secondary"
                        }
                      >
                        {missionDetails.status} {getCurrentMissionStatusStep(missionDetails.status)}
                        
                      </Badge>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Row className="m-0 p-0">
                    <Row className="m-0 p-0 mt-auto">
                      <Col className="m-0 p-2">
                        {CheckPermission(
                          userPermission && userPermission.role
                            ? userPermission.role
                            : "",
                          "EMPLOYEE"
                        ) ? (
                          <Button
                            className="m-auto col-12"
                            color="white"
                            style={{
                              backgroundColor: "white",
                              borderRadius: "20px",
                              height: "100%",
                            }}
                            onClick={() => toggleModalProcessarImagem()}
                          >
                            <BsMenuButtonWideFill className="me-2" />
                            Processar
                          </Button>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col className="m-0 p-2">
                        {projectData && projectData.status ? (
                          projectData.status !== "Finalizado" ? (
                            <Button
                              className="m-auto col-12"
                              color="white"
                              style={{
                                backgroundColor: "white",
                                borderRadius: "20px",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              onClick={() => setModalUploadVideo(true)}
                            >
                              <div>
                                <BsFillCloudUploadFill className="me-2" />
                              </div>
                              Enviar Vídeos
                            </Button>
                          ) : (
                            ""
                          )
                        ) : (
                          <div className="mt-auto mb-auto text-end">
                            <Spinner
                              style={{ width: "20px", height: "20px" }}
                              className="m-auto p-0"
                              color="primary"
                            />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="col-2 pr-0 m-0 row"></div>
          </div>
          <hr />
          <div className="main-content mt-0 p-3">
            <Row className="pb-2">
              <Col lg={6}>
                <div>
                  <small>Filtro por texto:</small>
                </div>
                <Input
                  onChange={(value) =>
                    setPageSearchPaginationAPI(value.target.value)
                  }
                  placeholder={"Digite um termo para busca"}
                ></Input>
              </Col>
              <Col lg={4}>
                <div>
                  <small>Elementos por página:</small>
                </div>
                <select
                  className="form-select text-center"
                  onChange={(value) =>
                    setPageCountPaginationAPI(value.target.value)
                  }
                >
                  <option value="12">12</option>
                  <option value="24">24</option>
                  <option value="48">48</option>
                  <option value="144">144</option>
                  <option value="288">288</option>
                </select>
              </Col>
              <Col lg={2} className={"text-end m-0 row"}>
                <div className="m-auto">
                  <Button
                    className="border rounded-left p-2 mt-1 mb-1 ml-1 mr-0"
                    color={listImagesMode === "block" ? "secondary" : "none"}
                    onClick={() => setListImagesMode("block")}
                  >
                    <BsGrid />
                  </Button>
                  <Button
                    className="border rounded-right p-2 mt-1 mb-1 mr-1 ml-0"
                    color={listImagesMode === "list" ? "secondary" : "none"}
                    onClick={() => setListImagesMode("list")}
                  >
                    <BsListUl />
                  </Button>
                </div>
              </Col>
            </Row>
            <div style={{ position: "relative" }}>
              <hr />
              {projectData &&
              projectData.status &&
              projectData.status === "Finalizado" ? (
                <Row className="m-0 pt-1">
                  <small style={{ fontSize: ".8em" }}>
                    *As alterações nas missões deste projeto não estão
                    disponíveis devido ao projeto estar finalizado.
                  </small>
                </Row>
              ) : (
                ""
              )}
              {projectData ? (
                <div
                  style={{
                    position: "absolute",
                    top: "-15px",
                    right: "0px",
                    zIndex: "600",
                  }}
                >
                  {missionDetails ? (
                    listDeleteImages &&
                    listDeleteImages.length &&
                    missionDetails.status &&
                    missionDetails.status !== "Processamento finalizado" &&
                    projectData.status &&
                    projectData.status !== "Finalizado" ? (
                      <Button
                        outline
                        className="pt-1 pb-1 me-1 ms-1 bg-light text-dark"
                        onClick={() => {
                          setModalDeleteImages({
                            title: "Apagando imagens",
                            content: (
                              <Row>
                                Deseja apagar
                                {" " +
                                  listDeleteImages.length +
                                  (listDeleteImages.length <= 1
                                    ? " imagem"
                                    : " imagens")}
                                ?
                              </Row>
                            ),
                            confirmButton: true,
                            cancelButton: true,
                            okButton: false,
                          });
                          //setModalDeleteImages(true);
                        }}
                      >
                        <Row>
                          <Col className="col-12 col-md-1">
                            <BsTrash style={{ marginTop: "-2px" }} />
                          </Col>

                          {/* <small>
                          selecionados
                        </small> */}
                          <Col className="d-none d-md-block">
                            <small>
                              <div>Apagar selecionados</div>
                            </small>
                          </Col>
                        </Row>
                      </Button>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {listDeleteImages && listDeleteImages.length > 0 ? (
                    <Button
                      outline
                      className="pt-1 pb-1 me-1 ms-1 bg-light text-dark"
                      onClick={() => {
                        let selectedMission = null;
                        return openModalCopiaImagensContent(
                          "Copiar imagens selecionadas",
                          <>
                            <div className="m-4">
                              <p className="text-start">
                                As seguintes imagens serão copiadas:
                              </p>
                              <ul>
                                {listDeleteImages.map((imagem) => {
                                  return (
                                    <Badge
                                      key={Math.random()}
                                      className="m-1"
                                      color="primary"
                                    >
                                      {imagem}
                                    </Badge>
                                  );
                                })}
                              </ul>
                              <p className="text-start">
                                Selecione o projeto de destino
                              </p>
                              <div>
                                <select
                                  className="form-control"
                                  onChange={(selected) => {
                                    selectedMission = null;
                                    setSelectedProjectToCopy(
                                      selected.target.value
                                    );

                                    projectsToCopyRef.current.options.length = 0;

                                    setTimeout(() => {
                                      projectsToCopyRef.current.options.selectedIndex =
                                        -1;
                                      if (
                                        listAllMissions[
                                          selected.target.value
                                        ] &&
                                        listAllMissions[selected.target.value]
                                          .length > 0
                                      ) {
                                        projectsToCopyRef.current.disabled = false;
                                        [
                                          { name: "Selecione", id: null },
                                          ...listAllMissions[
                                            selected.target.value
                                          ],
                                        ]
                                          .map((mission) => {
                                            const option =
                                              document.createElement("option");
                                            option.textContent =
                                              // selected.target.value +
                                              // " - " +
                                              mission.name;
                                            option.value = mission.id;
                                            return option;
                                          })
                                          .forEach((option) =>
                                            projectsToCopyRef.current.options.add(
                                              option
                                            )
                                          );
                                      } else {
                                        projectsToCopyRef.current.disabled = true;
                                      }
                                    }, 10);
                                  }}
                                >
                                  <option value={null}></option>
                                  {listAllMissions &&
                                    listProjects.map((e) => {
                                      return (
                                        <option
                                          key={Math.random()}
                                          value={e.id}
                                        >
                                          {"#" + e.id + " - " + e.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                              <p className="text-start mt-4">
                                Selecione a missão de destino
                              </p>
                              <div>
                                <select
                                  required={true}
                                  disabled={
                                    selectedProjectToCopy ? false : true
                                  }
                                  ref={projectsToCopyRef}
                                  className={"form-control"}
                                  onChange={(selected) => {
                                    selectedMission = selected.target.value;
                                  }}
                                ></select>
                              </div>
                            </div>
                            <ModalFooter className="mt-4">
                              <Button
                                color="secondary"
                                onClick={() => setModalCopiaImagens(false)}
                              >
                                Cancelar
                              </Button>
                              <Button
                                color={"success"}
                                onClick={() => {
                                  selectedMission &&
                                  selectedMission.toString() !== "null"
                                    ? copyImages(0, selectedMission)
                                    : alert("Selecione uma missão de destino");
                                }}
                              >
                                Confirmar
                              </Button>
                            </ModalFooter>
                          </>
                        );
                      }}
                    >
                      <Row>
                        <Col className="col-12 col-md-1">
                          <BsClipboard style={{ marginTop: "-2px" }} />
                        </Col>
                        <Col className="d-none d-md-block">
                          <small>
                            <div>
                              {listDeleteImages && listDeleteImages.length > 0
                                ? "Copiar as imagens selecionadas"
                                : "Copiar todas as imagens"}
                            </div>
                          </small>
                        </Col>
                      </Row>
                    </Button>
                  ) : (
                    ""
                  )}
                  {listImages &&
                  listImages.length > 0 &&
                  (!listDeleteImages || listDeleteImages.length === 0) ? (
                    <Button
                      outline
                      className="pt-1 pb-1 me-1 ms-1 bg-light text-dark"
                      onClick={() => {
                        setListDeleteImages(
                          listImages.map((imagem) => {
                            return imagem.id;
                          })
                        );
                        setCount(Math.random());
                      }}
                    >
                      <Row>
                        <Col className="col-12 col-md-1">
                          <BsXSquare style={{ marginTop: "-2px" }} />
                        </Col>
                        <Col className="d-none d-md-block">
                          <small>Selecionar todas</small>
                        </Col>
                      </Row>
                    </Button>
                  ) : listImages && listImages.length > 0 ? (
                    <Button
                      outline
                      className="pt-1 pb-1 me-1 ms-1 bg-light text-dark"
                      onClick={() => {
                        setListDeleteImages([]);
                        setCount(Math.random());
                      }}
                    >
                      <Row>
                        <Col className="col-12 col-md-1">
                          <BsXCircle style={{ marginTop: "-2px" }} />
                        </Col>
                        <Col className="d-none d-md-block">
                          <small>Remover toda seleção</small>
                        </Col>
                      </Row>
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div style={{ display: "flex", padding: "5px" }}>
              <div style={{ width: "100%" }} id="uprojetos">
                <Row>{listImagesToView}</Row>
              </div>
            </div>
            {pagePaginationAPI >= detalhesProcessamento.totalElements ? (
              ""
            ) : (
              <Button
                outline
                className="col-12"
                onClick={() =>
                  setPagePaginationAPI(
                    parseInt(pagePaginationAPI) +
                      parseInt(pageCountPaginationAPI)
                  )
                }
              >
                Mostrar mais...
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionImages;
