import React from "react";
import { Row } from "reactstrap";

const filterColorScale = JSON.parse(process.env.REACT_APP_COLOR_SCALE);
const defaultPointColor = process.env.REACT_APP_DEFAULT_POINT_COLOR;

const ColorTableSubtitle = ({
  offCanvas,
  filterLamp,
  filterDistanceToCrossHead,
}) => {
  return (
    <div
      id="ColorTableSubtitle"
      style={{
        position: "absolute",
        right: offCanvas ? "400px" : "20px",
        transition: "right .5s ease-in",
        bottom: "30px",
        zIndex: "600",
        width: "250px",
      }}
    >
      <Row
        style={{
          overflow: "hidden",
          backgroundColor: "#fff",
          borderRadius: "10px",
          fontSize: "1.1rem",
          padding: ".5rem 0",
        }}
      >
        <div>
          <div className="col-12 row m-0 p-1">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: ".5rem",
              }}
            >
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "5px",
                  backgroundColor: defaultPointColor,
                }}
              />
              <small>Não definido</small>
            </div>

            {filterLamp.length === 2 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: ".5rem",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "5px",
                    backgroundColor: filterColorScale[0],
                  }}
                />
                <small>Led e 24 horas ligada</small>
              </div>
            )}

            {filterLamp?.map(({ text, color }) => {
              return (
                <div
                  key={Math.random()}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".5rem",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "5px",
                      backgroundColor: color,
                    }}
                  />
                  <small>{text}</small>
                </div>
              );
            })}

            {filterDistanceToCrossHead.value > 0 && (
              <div
                key={Math.random()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: ".5rem",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "5px",
                    backgroundColor: filterDistanceToCrossHead.color,
                  }}
                />
                <small>Distância até a cruzeta</small>
              </div>
            )}
          </div>
        </div>
      </Row>
    </div>
  );
};

export default ColorTableSubtitle;
