import axios from "axios";
import { Modal, ModalHeader } from "reactstrap";
import ReactDOM from "react-dom/client";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const getAccessToken = () => {
  return localStorage.getItem("robotictech_accessToken");
};

const getRefreshToken = () => {
  return localStorage.getItem("robotictech_refreshToken");
};

api.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${getAccessToken()}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      const refreshToken = getRefreshToken();

      return axios
        .post(
          "https://keycloak.robotictech.com.br/realms/Census/protocol/openid-connect/token",

          new URLSearchParams({
            client_id: "login-app",
            grant_type: "refresh_token",
            refresh_token: refreshToken,
          })
        )
        .then((response) => {
          const newAccessToken = response.data.access_token;
          localStorage.setItem("robotictech_accessToken", newAccessToken);

          error.config.headers.Authorization = `Bearer ${newAccessToken}`;
          return axios(error.config);
        })
        .catch((error) => {
          localStorage.clear();
          console.log(error);
          const root = ReactDOM.createRoot(document.getElementById("root"));
          root.render(
            <Modal isOpen={true} centered>
              <ModalHeader>Por favor, realize o login novamente.</ModalHeader>
              <a className="btn btn-secondary" href="/login">
                Ok
              </a>
            </Modal>
          );

          return Promise.reject(error);
        });
    }
    return Promise.reject(error);
  }
);

export default api;
