import { useCallback, useEffect, useState } from "react";


export const useDragScroll = () => {
    const [node, setNode] = useState(null);
  
    const ref = useCallback((nodeEle) => {
      setNode(nodeEle);
    }, []);
  
    const handleMouseDown = useCallback((e) => {
      if (!node) {
        return;
      }
      const startPos = {
        left: node.scrollLeft,
        top: node.scrollTop,
        x: e.clientX,
        y: e.clientY,
      };
  
      const handleMouseMove = (e) => {
        const dx = e.clientX - startPos.x;
        const dy = e.clientY - startPos.y;
        const angle = 180; // Rotation angle in degrees
        const rad = (angle * Math.PI) / 180;
        const newX = dx * Math.cos(rad) - dy * Math.sin(rad);
        const newY = dx * Math.sin(rad) + dy * Math.cos(rad);
        node.scrollTop = startPos.top - newY;
        node.scrollLeft = startPos.left - newX;
        updateCursor(node);
      };
  
      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        resetCursor(node);
      };
  
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }, [node]);
  
    const handleTouchStart = useCallback((e) => {
      if (!node) {
        return;
      }
      const touch = e.touches[0];
      const startPos = {
        left: node.scrollLeft,
        top: node.scrollTop,
        x: touch.clientX,
        y: touch.clientY,
      };
  
      const handleTouchMove = (e) => {
        const touch = e.touches[0];
        const dx = touch.clientX - startPos.x;
        const dy = touch.clientY - startPos.y;
        const angle = 180; // Rotation angle in degrees
        const rad = (angle * Math.PI) / 180;
        const newX = dx * Math.cos(rad) - dy * Math.sin(rad);
        const newY = dx * Math.sin(rad) + dy * Math.cos(rad);
        node.scrollTop = startPos.top - newY;
        node.scrollLeft = startPos.left - newX;
        updateCursor(node);
      };
  
      const handleTouchEnd = () => {
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
        resetCursor(node);
      };
  
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
    }, [node]);
  
    const updateCursor = (ele) => {
      ele.style.cursor = 'grabbing';
      ele.style.userSelect = 'none';
    };
  
    const resetCursor = (ele) => {
      ele.style.cursor = 'grab';
      ele.style.removeProperty('user-select');
    };
  
    useEffect(() => {
      if (!node) {
        return;
      }
      node.addEventListener('mousedown', handleMouseDown);
      node.addEventListener('touchstart', handleTouchStart);
      return () => {
        node.removeEventListener('mousedown', handleMouseDown);
        node.removeEventListener('touchstart', handleTouchStart);
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [node]);
  
    return [ref];
  };
  