import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const ModalForm = ({
  isOpen,
  toggle,
  setFormObjects,
  saveAnnotation,
  annotationType,
}) => {
  const [form, setForm] = useState({});

  useEffect(() => {
    const initializeForm = () => {
      switch (annotationType) {
        case "lamp":
          return {
            led: false,
            allDayOn: false,
            distanceToFather: 0,
            distanceToGround: 0,
          };
        case "lamp_arm":
          return {
            height: 0,
            length: 0,
            distanceToFather: 0,
            distanceToGround: 0,
          };
        default:
          return { distanceToFather: 0, distanceToGround: 0 };
      }
    };
    const initialForm = initializeForm();
    setForm(initialForm);
    setFormObjects(initialForm);
  }, [annotationType, setFormObjects]);

  const handleFormChange = (e) => {
    const { name, type, checked, value } = e.target;
    const updatedForm = {
      ...form,
      [name]: type === "checkbox" ? checked : value,
    };
    setForm(updatedForm);
    setFormObjects(updatedForm);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Propriedades</ModalHeader>
      <ModalBody>
        <Form style={{ gap: "0" }}>
          {annotationType === "lamp_arm" && (
            <>
              <FormGroup>
                <Label for="height">Altura</Label>
                <Input
                  type="number"
                  name="height"
                  id="height"
                  value={form.height}
                  onChange={handleFormChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="length">Comprimento</Label>
                <Input
                  type="number"
                  name="length"
                  id="length"
                  value={form.length}
                  onChange={handleFormChange}
                />
              </FormGroup>
            </>
          )}
          <FormGroup>
            <Label for="distanceToFather">Distância até o poste</Label>
            <Input
              type="number"
              name="distanceToFather"
              id="distanceToFather"
              value={form.distanceToFather}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="distanceToGround">Distância até o chão</Label>
            <Input
              type="number"
              name="distanceToGround"
              id="distanceToGround"
              value={form.distanceToGround}
              onChange={handleFormChange}
            />
          </FormGroup>
          {annotationType === "lamp" && (
            <>
              <FormGroup check>
                <Input
                  type="checkbox"
                  name="led"
                  id="led"
                  checked={form.led}
                  onChange={handleFormChange}
                />
                <Label for="led" check>
                  Led
                </Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  type="checkbox"
                  name="allDayOn"
                  id="allDayOn"
                  checked={form.allDayOn}
                  onChange={handleFormChange}
                />
                <Label for="allDayOn" check>
                  Ligada 24 horas por dia
                </Label>
              </FormGroup>
            </>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => saveAnnotation(form)}>
          Save
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalForm;
