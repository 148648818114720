import React, { useEffect, useState } from "react";
import { Viewer } from "@photo-sphere-viewer/core";
import { MarkersPlugin } from "@photo-sphere-viewer/markers-plugin";
import "./PhotoSphereViewer.css";

const colors = JSON.parse(process.env.REACT_APP_DETECTED_OBJECTS_COLORS);

const objectTypeNames = {
  cross_head: "Cruzeta",
  fiber_optic_junction_box: "Caixa junção fibra óptica",
  lamp: "Lâmpada",
  lamp_arm: "Braço de luminária",
  telecom_box: "Caixa de telecom",
  transformer: "Transformador",
  wire_reserver: "Reserva de fios",
};

const PhotoSphereViewer = ({
  detection,
  imgSrc,
  height,
  containerId = "viewer",
  annotation = false,
  currentPole,
  numOfPoles,
  annotationType,
  setBoundingBox,
  setNewAnnotationModal
}) => {
  const [polygonPoints, setPolygonPoints] = useState([]);

  function mapCoordsX(x) {
    return (x * (Math.PI - -Math.PI)) / 1 + -Math.PI;
  }

  function mapCoordsY(y) {
    return (y * (-Math.PI / 2 - Math.PI / 2)) / 1 + Math.PI / 2;
  }

  useEffect(() => {
    const drawBB = (detection, markersPlugin, viewer) => {
      const x1 = mapCoordsX(detection.x1);
      const x2 = mapCoordsX(detection.x2);
      const x3 = mapCoordsX(detection.x3);
      const x4 = mapCoordsX(detection.x4);
      const y1 = mapCoordsY(detection.y1);
      const y2 = mapCoordsY(detection.y2);
      const y3 = mapCoordsY(detection.y3);
      const y4 = mapCoordsY(detection.y4);

      let color = colors[detection.objectType];

      const addMarker = () => {
        markersPlugin.addMarker({
          id: Math.random().toString(),
          polygon: [
            [x1, y1],
            [x2, y2],
            [x3, y3],
            [x4, y4],
          ],
          svgStyle: {
            fill: color.fill,
            stroke: color.stroke,
            strokeWidth: "2px",
          },
        });
      };

      viewer.addEventListener("ready", addMarker, { once: true });
    };

    const viewer = new Viewer({
      container: document.getElementById(containerId),
      panorama: imgSrc.path_low + "?not-from-cache-please",
      defaultZoomLvl: 0,
      navbar: [
        "move",
        "zoom",
        "fullscreen",
        {
          id: "customEraserButton",
          title: "Eraser",
          className: "custom-eraser-button",
          visible: annotation,
          content: `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style="fill: rgba(255, 255, 255, 0.7);">
              <path d="M290.7 57.4L57.4 290.7c-25 25-25 65.5 0 90.5l80 80c12 12 28.3 18.7 45.3 18.7L288 480l9.4 0L512 480c17.7 0 32-14.3 32-32s-14.3-32-32-32l-124.1 0L518.6 285.3c25-25 25-65.5 0-90.5L381.3 57.4c-25-25-65.5-25-90.5 0zM297.4 416l-9.4 0-105.4 0-80-80L227.3 211.3 364.7 348.7 297.4 416z"/>
            </svg>
          `,
          onClick: () => {
            setBoundingBox([]);
            setPolygonPoints([]);
            markersPlugin.clearMarkers();
          },
        },
        {
          id: "current-annotation",
          className: "current-annotation",
          content: objectTypeNames[annotationType],
          visible: annotation && annotationType !== null,
          onClick: () => {
            setNewAnnotationModal(true)
          }
        },

        {
          id: "num-of-poles",
          className: "num-of-poles",
          content: `${currentPole + 1}/${numOfPoles}`,
          visible: numOfPoles > 0,
          disabled: true,
        },
      ],
      plugins: [[MarkersPlugin, { markers: [] }]],
    });

    const markersPlugin = viewer.getPlugin(MarkersPlugin);

    markersPlugin.clearMarkers();

    if (annotation) {
      viewer.addEventListener("click", ({ data }) => {
        const { yaw, pitch } = data;
        addPolygonPoint(yaw, pitch, markersPlugin, viewer);
      });
    }

    if (!detection) return;

    const detectionArray = Array.isArray(detection) ? detection : [detection];

    detectionArray.sort(sortByPoleAndArea);

    const firstDetection = detectionArray[0];

    if (firstDetection) {
      const x1 = mapCoordsX(firstDetection.x1);
      const y1 = mapCoordsY(firstDetection.y1);

      viewer.rotate({
        yaw: x1,
        pitch:
          firstDetection.objectType === "pole" ||
          firstDetection.objectType === "tree"
            ? y1 / 3
            : y1,
      });
    }

    detectionArray.forEach((detection) => {
      drawBB(detection, markersPlugin, viewer);
    });

    return () => {
      viewer.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgSrc, detection, containerId]);

  const sortByPoleAndArea = (a, b) => {
    if (a.objectType === "pole" && b.objectType !== "pole") {
      return -1;
    }
    if (a.objectType !== "pole" && b.objectType === "pole") {
      return 1;
    }

    const bbAreaA = calcBbArea(a);
    const bbAreaB = calcBbArea(b);

    return bbAreaB - bbAreaA;
  };

  function calcBbArea(detection) {
    let bb_width = detection.x2 - detection.x1;
    let bb_height = detection.y4 - detection.y1;

    return bb_width * bb_height;
  }

  const addPolygonPoint = (yaw, pitch, markersPlugin, viewer) => {
    const color = colors[annotationType];
    const newPoint = { id: Math.random(), yaw, pitch, color: color.stroke };
    setPolygonPoints((prev) => {
      const updatedPoints = [...prev, newPoint];
      markersPlugin.addMarker({
        id: Math.random(),
        position: { yaw: newPoint.yaw, pitch: newPoint.pitch },
        html: `<div style="width: 10px; height: 10px; background-color: ${color.stroke}; border-radius: 50%;"></div>`,
        anchor: "center",
      });
      drawPolygon(markersPlugin, updatedPoints, viewer);
      return updatedPoints;
    });
  };

  const drawPolygon = (markersPlugin, points) => {
    const path = points.map((point) => [point.yaw, point.pitch]);
    const color = colors[annotationType];

    if (points.length === 4) {
      markersPlugin.clearMarkers();

      markersPlugin.addMarker({
        id: Math.random().toString(),
        polygon: path,
        svgStyle: {
          fill: color.fill,
          stroke: color.stroke,
          strokeWidth: 2,
        },
      });
      setBoundingBox(path);
      setPolygonPoints([]);
    }
  };

  return <div id={containerId} style={{ width: "100%", height: height }}></div>;
};

export default PhotoSphereViewer;
