import React, { useState } from "react";
import Header from "../../elements/Header";
import { Link } from "react-router-dom";
// import { useKeycloak } from "@react-keycloak/web";
// import { useNavigate, useParams } from "react-router-dom";
import {
  // Table,
  // UncontrolledCollapse,
  // Collapse,
  Button,
  // CardBody,
  // Card,
  // ListGroup,
  // ListGroupItem,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  // BsFillFlagFill,
  // BsFillPencilFill,
  // BsFillPlusCircleFill,
  BsArrowLeft,
} from "react-icons/bs";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import CheckPermission from "./CheckPermission";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import {
  removeSpecialChars,
  formatPhone,
  formatCnpj,
} from "../../elements/FormatDateString";

const NewCompany = () => {
  document.title = "User Management - New Company";
  // let { id: companyId } = useParams();
  let apiUrl = process.env.REACT_APP_API_URL;
  // const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
    button: "",
    buttonHref: "",
  });
  const toggleModal = (content) => {
    if (!content.title) content.title = "";
    if (!content.content) content.content = "";
    if (!content.button) content.button = "Ok";
    if (!content.buttonHref) content.buttonHref = null;
    if (!content.actionButton) content.actionButton = null;

    setModalContent(content);
    setModal(!modal);
  };

  const [cnpjMask, setCnpjMask] = useState();
  const [phoneMask, setPhoneMask] = useState();

  const [userPermission, setUserPermission] = useState(null);
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });

  const onFormSubmit = (values) => {
    values.preventDefault();

    let fieldsAndValues = {};
    let logoFile = null;
    Object.entries(values.target.elements).forEach((ele) => {
      let value = null;
      if (ele[0].toString() === "CNPJ") {
        value = removeSpecialChars(ele[1].value);
      } else if (ele[0].toString() === "Telefone") {
        value = removeSpecialChars(ele[1].value);
      } else if (ele[0].toString() === "uploadFile" && ele[1].files[0]) {
        logoFile = ele[1].files[0];
      } else {
        value = ele[1].value;
      }
      if (value) fieldsAndValues[ele[1].id] = value;
    });

    toggleModal({
      title: "Inserir nova empresa",
      content: (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status" id="loading">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ),
      buttonHref: null,
      actionButton: null,
    });

    fetch(apiUrl + "/companies", {
      method: "POST",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
      body: JSON.stringify(fieldsAndValues),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}

        let id = data.id;
        let file = logoFile;

        const uploadData = new FormData();
        uploadData.append("logoImage", file);
        uploadData.append("id", id);

        fetch(apiUrl + "/companies/" + id + "/add-logo-image", {
          method: "POST",
          headers: {
            accept: "*/*",
            Authorization: "Bearer " + userPermission.access_token,
          },
          body: uploadData,
        })
          .then((response) => {
            if (!response.ok) {
              return response.text().then((text) => {
                throw new Error(text);
              });
            } else {
              return response.json();
            }
          })
          .then((data) => {
            try {
              data = JSON.parse(data);
            } catch (error) {}

            toggleModal({
              title: "Inserir nova empresa",
              content:
                "Nova empresa inserida!\n\nVocê poderá vê-la na página de gerenciamento.",
              buttonHref: "/companiesManagement",
            });

            // toggleModal({
            //   title: "Alterar logo da empresa",
            //   content: "Alterado!",

            //   footerConfirm: false,
            //   footerCancel: false,
            //   footerOk: true,
            // });
          })
          .catch((error) => {
            toggleModal({
              title: "Inserir nova empresa",
              content:
                "A empresa foi inserida mas houve um erro ao adicionar sua logo!\n\nVocê poderá vê-la na página de gerenciamento e adicionar uma logo manualmente.",
              buttonHref: "/companiesManagement",
            });
            //   toggleModal({
            //     title: "Alterar logo da empresa",
            //     content: "Houve um erro ao alterar a logo. " + error,

            //     footerConfirm: false,
            //     footerCancel: false,
            //     footerOk: true,
            //   });
          });
      })
      .catch((error) => {
        toggleModal({
          title: "Inserir nova empresa",
          content: "Houve um erro ao inserir a empresa.\n\nDetalhes:\n" + error,
          buttonHref: null,
          actionButton: () => setModal(false),
        });
        console.error("Error:", error);
      });
  };
  return (
    <div>
      <Modal isOpen={modal} toggle={toggleModal} backdrop="static">
        <ModalHeader>{modalContent.title}</ModalHeader>
        <ModalBody>{modalContent.content}</ModalBody>
        {modalContent.buttonHref || modalContent.actionButton ? (
          <ModalFooter>
            <Link
              className="btn btn-outline-secondary"
              to={modalContent.buttonHref ? modalContent.buttonHref : false}
              onClick={
                modalContent.actionButton ? modalContent.actionButton : false
              }
            >
              {modalContent.button}
            </Link>
          </ModalFooter>
        ) : (
          ""
        )}
      </Modal>
      <Header />
      <Tooltip id="my-tooltip" />
      <div className="container" style={{ marginTop: "10vh " }}>
        <div
          id="basicInformation"
          className="container  /*main-content*/ recuadoOut"
        >
          <div className="row pt-4">
            <h2 className="col-10 m-0 text-start">
              <Link
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Voltar a pagina anterior"
                className="text-end "
                style={{ marginRight: "0px" }}
                to={"/companiesManagement"}
              >
                <BsArrowLeft
                  className="nav-button-circle "
                  style={{ boxShadow: "none" }}
                />
              </Link>
              Nova Empresa
            </h2>
            <div className="col-2 pr-0 m-0 row"></div>
          </div>
          <hr />
          <div
            className="main-content mt-0 p-3"
            style={{ display: "flex", padding: "5px" }}
          >
            <div style={{ width: "100%" }} id="uprojetos">
              {true ||
              CheckPermission(
                userPermission && userPermission.role
                  ? userPermission.role
                  : "",
                "EMPLOYEE"
              ) ? (
                <Form onSubmit={onFormSubmit}>
                  <FormGroup row>
                    <Label for={"cnpj"} sm={3}>
                      CNPJ
                    </Label>
                    <Col sm={9}>
                      <Input
                        id={"cnpj"}
                        name={"CNPJ"}
                        placeholder={"CNPJ"}
                        type={"text"}
                        value={cnpjMask}
                        onChange={(value) => {
                          setCnpjMask(formatCnpj(value.target.value));
                        }}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for={"email"} sm={3}>
                      Email
                    </Label>
                    <Col sm={9}>
                      <Input
                        id={"email"}
                        name={"Email"}
                        placeholder={"Email"}
                        type={"text"}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for={"name"} sm={3}>
                      Name
                    </Label>
                    <Col sm={9}>
                      <Input
                        id={"name"}
                        name={"Name"}
                        placeholder={"Name"}
                        type={"text"}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for={"phone"} sm={3}>
                      Telefone
                    </Label>
                    <Col sm={9}>
                      <Input
                        id={"phone"}
                        name={"Telefone"}
                        placeholder={"Telefone"}
                        type={"text"}
                        defaultValue={formatPhone("55")}
                        value={phoneMask}
                        onChange={(value) => {
                          setPhoneMask(formatPhone(value.target.value));
                        }}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row style={{ display: "none" }}>
                    <Col sm={9}>
                      <Input
                        id={"enabled"}
                        name={"enabled"}
                        type={"text"}
                        defaultValue={"true"}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row style={{ display: "none" }}>
                    <Col sm={9}>
                      <Input
                        id={"logo_image_path"}
                        name={"logo_image_path"}
                        type={"text"}
                        defaultValue={null}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for={"uploadFile"} sm={3}>
                      Logo da empresa
                    </Label>
                    <Col sm={9}>
                      <Input type="file" id="uploadFile" name="uploadFile" />
                    </Col>
                  </FormGroup>
                  <div className="row">
                    <Button
                      className="col-8 m-auto"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Criar o Registro de Empresa"
                    >
                      Criar Empresa
                    </Button>
                  </div>
                </Form>
              ) : (
                <h4>Sem permissão para acessar esta área.</h4>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCompany;
