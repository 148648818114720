import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ModalNotification = ({ modalNotificacao, setModalNotificacao }) => {
  return (
    <Modal // Modal de notificação
      style={{ zIndex: "2050" }}
      isOpen={modalNotificacao ? true : false}
    >
      <ModalHeader>
        {modalNotificacao && modalNotificacao.title
          ? modalNotificacao.title
          : ""}
      </ModalHeader>
      <ModalBody>
        <div className="row m-1">
          {modalNotificacao && modalNotificacao.content
            ? modalNotificacao.content
            : ""}
        </div>
      </ModalBody>
      <ModalFooter>
        {modalNotificacao && modalNotificacao.cancelButton ? (
          <Button color="secondary" onClick={modalNotificacao.cancelButton}>
            Cancelar
          </Button>
        ) : (
          ""
        )}

        {modalNotificacao && modalNotificacao.okButton ? (
          <Button color="secondary" onClick={() => setModalNotificacao(false)}>
            Ok
          </Button>
        ) : (
          ""
        )}
        {modalNotificacao && modalNotificacao.confirmButton ? (
          <Button color="success" onClick={modalNotificacao.confirmButton}>
            Confirmar
          </Button>
        ) : (
          ""
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ModalNotification;
