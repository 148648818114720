// import React from "react";
// import { Navigate, Link } from "react-router-dom";
// import { useKeycloak, keycloakPromise } from "@react-keycloak/web";
// import { resolve } from "chart.js/dist/helpers/helpers.options";

const CheckAuth = () => {
  //const { keycloak } = useKeycloak();
  // const isLoggedIn = keycloak.authenticated;

  // await new Promise(async (resolve, reject) => {
  //   let isAuth = await keycloak.authenticated;
  //   if (isAuth) {
  //     return "daskdjas";
  //     resolve("sadasd");
  //   } else reject("oooo");
  // });

  if (localStorage.getItem("robotictech_accessToken")) return true;
  else return false;

  // await new Promise(keycloak.loadUserProfile())
  // .then(res => {
  //     console.log(res)
  //     alert()
  //     return true
  // })
  // .catch(() => {
  //     return false
  // })

  // if(isLoggedIn){
  //     new Promise(keycloak.loadUserProfile())
  //     .then(userProfile => {
  //         console.log(userProfile)
  //         alert()
  //         return userProfile
  //     })
  //     .catch(() => {
  //         return false
  //     })
  // } else {
  //     return false
  // }

  // if(
  //     localStorage.getItem('robotictech_email') &&
  //     localStorage.getItem('robotictech_nome') &&
  //     localStorage.getItem('robotictech_role') &&
  //     localStorage.getItem('robotictech_company') &&
  //     localStorage.getItem('robotictech_id')
  // ) {
  //     return true
  // } else {
  //     return false
  // }
};

export default CheckAuth;
