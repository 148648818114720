import React from "react";
import { Spinner } from "reactstrap";

const LoadingIcon = () => {
  return (
    <div
      key={Math.random()}
      style={{
        backgroundColor: "#fff",
        zIndex: "3000",
        position: "absolute",
        left: "0",
        top: "0",
        width: "100%",
        height: "100%",
        display: "flex",
      }}
    >
      <Spinner
        color="primary"
        style={{
          margin: "auto",
          width: "100px",
          height: "100px",
        }}
      >
        Loading...
      </Spinner>
    </div>
  );
};

export default LoadingIcon;
