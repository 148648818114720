import React, { useState, useEffect } from "react";
import Header from "../../elements/Header";
import {
  // useNavigate,
  Link,
  useParams,
  // UNSAFE_enhanceManualRouteObjects,
} from "react-router-dom";
// import { useKeycloak } from "@react-keycloak/web";
// import { useForm } from "react-hook-form";
import {
  // Table,
  // UncontrolledCollapse,
  // Collapse,
  Button,
  // CardBody,
  // Card,
  // ListGroup,
  // ListGroupItem,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import {
  // BsFillFlagFill,
  // BsFillPencilFill,
  // BsFillPlusCircleFill,
  BsArrowLeft,
} from "react-icons/bs";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import CheckPermission from "./CheckPermission";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import {
  removeSpecialChars,
  formatPhone,
  formatCnpj,
} from "../../elements/FormatDateString";

const EditCompany = () => {
  document.title = "Editar Perfil";
  let { id: companyId } = useParams();
  let apiUrl = process.env.REACT_APP_API_URL;
  const [userPermission, setUserPermission] = useState(null);
  const [companyData, setCompanyData] = useState();
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });
  //const { keycloak } = useKeycloak();
  // const navigate = useNavigate();
  // const [userProfile, setUserProfile] = useState({});
  const [loadingIcon, setLoadingIcon] = useState(true);
  // const [projectData, setProjectData] = useState({});
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
    button: "",
    buttonHref: "",
    buttonAction: "",
  });
  const toggleModal = (content) => {
    if (!content.title) content.title = "";
    if (!content.content) content.content = "";
    if (!content.button) content.button = "Ok";
    if (!content.buttonHref) content.buttonHref = false;
    if (!content.buttonAction) content.buttonAction = false;

    setModalContent(content);
    setModal(!modal);
  };

  const [phoneMask, setPhoneMask] = useState();
  const [cnpjMask, setCnpjMask] = useState();

  async function getCompanyData() {
    // await keycloak.loadUserProfile().then((res) => {
    //   setUserProfile(res);
    // });
    let ret = false;
    setLoadingIcon(true);
    await fetch(apiUrl + "/companies/" + companyId, {
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",

        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((res) => {
        //console.log(res.status);
        if (res.status === 200) return res.text();
        else {
          //console.log(res);
          switch (res.status.toString().substring(0, 1)) {
            case "5":
              throw new Error("Erro de comunicação com o servidor.");

            case "4":
              throw new Error("Requição não compreendida.");

            default:
              break;
          }
          return false;
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setCompanyData(data);
        if (data && data.phone) {
          while (data.phone.length <= 11) {
            data.phone = "0" + data.phone;
          }
          if (data.phone[3] === "9") {
            data.phone = "0" + data.phone;
          }
          setPhoneMask(formatPhone(data.phone));
        }
        if (data && data.cnpj) {
          setCnpjMask(formatCnpj(data.cnpj));
        }
        ret = data;
      })
      .catch((error) => {
        toggleModal({
          title: "Editar Empresa",
          content: "Houve um erro ao buscar os dados da empresa.\n\n" + error,
        });
        console.error("Error:", error);
      })
      .finally(() => {
        setLoadingIcon(false);
      });
    return ret;
  }

  useEffect(() => {
    if (userPermission) getCompanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission]);

  const onFormSubmit = (values) => {
    values.preventDefault();
    let fieldsAndValues = {};
    fieldsAndValues["id"] = companyId;
    Object.entries(values.target.elements).forEach((ele) => {
      let value;
      if (ele[0].toString() === "cnpj") {
        value = removeSpecialChars(ele[1].value);
      } else if (ele[0].toString() === "phone") {
        value = removeSpecialChars(ele[1].value);
        console.log(value);
      } else if (ele[1].id === "enabled")
        if (ele[1].checked) value = true;
        else value = false;
      else value = ele[1].value;
      if (value || ele[1].id === "enabled") fieldsAndValues[ele[1].id] = value;
    });

    fetch(apiUrl + "/companies/" + companyId, {
      method: "PUT",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
      body: JSON.stringify(fieldsAndValues),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          console.log(response);
          switch (response.status.toString().substring(0, 1)) {
            case "5":
              throw new Error("Erro de comunicação com o servidor.");

            case "4":
              throw new Error("Requição não compreendida.");

            default:
              break;
          }
          return false;
        }
      })
      .then((data) => {
        toggleModal({
          title: "Editar Empresa",
          content:
            "Empresa alterada!\n\nVocê poderá vê-la na página de empresas.",
          footerOk: true,
        });
      })
      .catch((error) => {
        toggleModal({
          title: "Editar Empresa",
          content: "Houve um erro ao editar a empresa.\n\nDetalhes:\n" + error,
          footerOk: true,
        });
        console.error("Erro:", error);
      });
  };

  const alteraLogoEmpresa = (id, file) => {
    toggleModal({
      title: "Alterar logo da empresa",
      content: (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status" id="loading">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ),

      footerConfirm: false,
      footerCancel: false,
      footerOk: false,
    });

    //console.log("solicitação de alteração");

    GetTokenApi().then((res) => {
      setUserPermission(res);

      const uploadData = new FormData();
      uploadData.append("logoImage", file);
      uploadData.append("id", id);

      fetch(apiUrl + "/companies/" + id + "/add-logo-image", {
        method: "POST",
        headers: {
          accept: "*/*",
          //"Content-Type": "multipart/form-data",
          Authorization: "Bearer " + userPermission.access_token,
        },
        body: uploadData,
      })
        .then((response) => {
          if (!response.ok) {
            return response.text().then((text) => {
              throw new Error(text);
            });
          } else {
            return response.json();
          }
        })
        .then((data) => {
          try {
            data = JSON.parse(data);
          } catch (error) {}

          toggleModal({
            title: "Alterar logo da empresa",
            content: "Alterado!",

            footerConfirm: false,
            footerCancel: false,
            footerOk: true,
          });
        })
        .catch((error) => {
          toggleModal({
            title: "Alterar logo da empresa",
            content: "Houve um erro ao alterar a logo. " + error,

            footerConfirm: false,
            footerCancel: false,
            footerOk: true,
          });
        });
    });
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggleModal} backdrop="static">
        <ModalHeader>{modalContent.title}</ModalHeader>
        <ModalBody>{modalContent.content}</ModalBody>
        {/* <ModalFooter>
          <Link
            className="btn btn-outline-secondary"
            to={modalContent.buttonHref ? modalContent.buttonHref : false}
            onClick={
              modalContent.buttonAction
                ? modalContent.buttonAction
                : () => setModal(false)
            }
          >
            {modalContent.button}
          </Link>
        </ModalFooter> */}
        <ModalFooter>
          {modalContent && modalContent.footerCancel ? (
            <Button color="secondary" onClick={() => setModal(false)}>
              Cancelar
            </Button>
          ) : (
            ""
          )}
          {modalContent && modalContent.footerOk ? (
            <Button
              color="secondary"
              onClick={() => window.location.reload(false)}
            >
              Ok
            </Button>
          ) : (
            ""
          )}
          {modalContent && modalContent.footerConfirm ? (
            <Button color="success" onClick={modalContent.footerConfirm}>
              Confirmar
            </Button>
          ) : (
            ""
          )}
        </ModalFooter>
      </Modal>
      <Header />
      <Tooltip id="my-tooltip" />
      <div className="container" style={{ marginTop: "10vh " }}>
        <div
          id="basicInformation"
          className="container /*main-content*/ recuadoOut"
        >
          <div className="row pt-4">
            <h2 className="col-10 m-0 text-start">
              <Link
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Voltar para a lista de empresas"
                className="text-end "
                style={{ marginRight: "0px" }}
                to={"/companiesManagement"}
              >
                <BsArrowLeft
                  className="nav-button-circle "
                  style={{ boxShadow: "none" }}
                />
              </Link>
              Editar Empresa
            </h2>
            <div className="col-2 pr-0 m-0 row"></div>
          </div>
          <hr />
          <div
            className="main-content mt-0 p-3"
            style={{ display: "flex", padding: "5px" }}
          >
            <div style={{ width: "100%" }} id="uprojetos">
              {(userPermission && userPermission.id === companyId) ||
              CheckPermission(
                userPermission && userPermission.role
                  ? userPermission.role
                  : "",
                "MANAGER"
              ) ? (
                loadingIcon ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status" id="loading">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : CheckPermission(
                    userPermission && userPermission.role
                      ? userPermission.role
                      : "",
                    "MANAGER"
                  ) ? (
                  <Form onSubmit={onFormSubmit}>
                    <Row className="m-0">
                      <Col lg={5} xl={4} xxl={3} className="p-2 m-0">
                        <FormGroup
                          row
                          className="m-0"
                          style={{ height: "100%" }}
                        >
                          <img
                            alt=""
                            src={
                              companyData.logo_image_path
                                ? companyData.logo_image_path +
                                  "?" +
                                  performance.now()
                                : "/imgs/logo_empty.png"
                            }
                            className="img-fluid m-auto"
                          />
                          <div className="mt-auto mb-3">
                            <Label for={"uploadFile"}>
                              Alterar logo da empresa
                            </Label>
                            <Input
                              type="file"
                              id="uploadFile"
                              name="uploadFile"
                              onChange={(file) => {
                                toggleModal({
                                  title: "Alterar logo da empresa",
                                  content:
                                    "Deseja realizar a substituição da imagem atual pela imagem selecionada?",

                                  footerConfirm: () => {
                                    alteraLogoEmpresa(
                                      companyData.id,
                                      file.target.files[0]
                                    );
                                  },
                                  footerCancel: true,
                                  footerOk: false,
                                });
                                //console.log(file);
                              }}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="p-2 m-0">
                        <FormGroup row className="m-0">
                          <Row>
                            <Label for={"name"} sm={3}>
                              Nome
                            </Label>
                          </Row>
                          <Row>
                            <Input
                              id={"name"}
                              name={"name"}
                              placeholder={"Nome"}
                              type={"text"}
                              defaultValue={
                                companyData && companyData.name
                                  ? companyData.name
                                  : ""
                              }
                            />
                          </Row>
                        </FormGroup>
                        <FormGroup row className="m-0">
                          <Row>
                            <Label for={"cnpj"} sm={3}>
                              CNPJ
                            </Label>
                          </Row>
                          <Row>
                            <Input
                              id={"cnpj"}
                              name={"cnpj"}
                              placeholder={"Sobrenome"}
                              type={"text"}
                              value={cnpjMask}
                              onChange={(value) => {
                                setCnpjMask(formatCnpj(value.target.value));
                              }}
                            />
                          </Row>
                        </FormGroup>
                        <FormGroup row className="m-0">
                          <Row>
                            <Label for={"email"} sm={3}>
                              Email
                            </Label>
                          </Row>
                          <Row>
                            <Input
                              id={"email"}
                              name={"email"}
                              placeholder={"email"}
                              type={"text"}
                              defaultValue={
                                companyData && companyData.email
                                  ? companyData.email
                                  : ""
                              }
                            />
                          </Row>
                        </FormGroup>
                        <Row>
                          <Col>
                            <FormGroup row className="m-0">
                              <Row>
                                <Label for={"phone"} sm={3}>
                                  Telefone
                                </Label>
                              </Row>
                              <Row>
                                <Input
                                  id={"phone"}
                                  name={"phone"}
                                  placeholder={"Deixe em branco para manter"}
                                  type={"text"}
                                  value={phoneMask}
                                  onChange={(value) => {
                                    setPhoneMask(
                                      formatPhone(value.target.value)
                                    );
                                  }}
                                />
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col md={5} xl={4} xxl={3}>
                            <FormGroup
                              row
                              className="m-0"
                              style={{ height: "100%" }}
                            >
                              <Row>
                                <Label
                                  for={"enabled"}
                                  className={"text-center"}
                                >
                                  Empresa Ativa
                                </Label>
                              </Row>
                              <Row>
                                <Input
                                  style={{ width: "30px", height: "30px" }}
                                  id={"enabled"}
                                  className={"ms-auto me-auto mt-0 mb-4"}
                                  name={"enabled"}
                                  type={"checkbox"}
                                  defaultChecked={
                                    companyData && companyData.enabled
                                      ? true
                                      : false
                                  }
                                />
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup
                          row
                          className="m-0"
                          style={{ display: "none" }}
                        >
                          <Row>
                            <Input
                              id={"logo_image_path"}
                              name={"logo_image_path"}
                              type={"text"}
                              defaultValue={
                                companyData && companyData.logo_image_path
                                  ? companyData.logo_image_path
                                  : ""
                              }
                            />
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="row">
                      <Button
                        className="col-8 m-auto"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Salvar as informações"
                      >
                        Salvar
                      </Button>
                    </div>
                  </Form>
                ) : (
                  <h5 className="m-2">
                    Você não tem permissão para alterar os dados deste usuário.
                  </h5>
                )
              ) : (
                <h4>Sem permissão para acessar esta área.</h4>
              )}
              {/* <Link onClick={() => toggleModal({title: 'teste titulo', content: 'teste content'})}>
                Abrir modal
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCompany;
