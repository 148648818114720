import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Spinner } from "reactstrap";
import ImageCrop from "../../pages/Map/ImageCrop";
import { BsXLg } from "react-icons/bs";
import api from "../../api/api";
import "./ModalPoleComponents.css";
import ModalConfirmation from "../ModalConfirmation/ModalConfirmation";

const classNames = [
  "crossHeads",
  "dropOutFuses",
  "fiberOpticJunctionBoxes",
  "insulators",
  "lamps",
  "lampArms",
  "telecomBoxes",
  "transformers",
  "wireReserves",
];

const classNameAdapt = [
  "Cruzeta",
  "Chave",
  "Caixa junção fibra óptica",
  "Isolador",
  "Lâmpada",
  "Braço de luminária",
  "Caixa de telecom",
  "Transformador",
  "Reserva de fios",
];

const classColors = ["#56e02e", "#efe52c", "#cb00a8", "#653b00", "#f7f7c0"];

const ModalPoleComponents = ({
  pointDetailOffcanvas,
  openDetailsModal,
  setViewLightComponents,
}) => {
  const [objects, setObjects] = useState([]);
  const [filterType, setFilterType] = useState("all");
  const [handleFilter, setHandleFilter] = useState(objects);
  const [loadingComponents, setLoadingComponents] = useState(true);
  const [itemToDelete, setItemToDelete] = useState();
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const typeCounts = objects.reduce((counts, item) => {
    counts[item.objectName] = (counts[item.objectName] || 0) + 1;
    return counts;
  }, {});

  useEffect(() => {
    setHandleFilter(objects);
  }, [objects]);

  //lida com o clique dos botões de filtro
  const handleFilterClick = (type) => {
    if (type === "all") {
      setHandleFilter(objects);
    } else {
      const filteredData = objects.filter((item) => {
        return item.objectName === type;
      });
      setHandleFilter(filteredData);
    }
    setFilterType(type);
  };

  useEffect(() => {
    const getObjects = async (point) => {
      try {
        const newData = [];

        const { data } = await api.get(
          `/detected-objects/poles/${point}/components`
        );

        for (let i = 0; i < classNames.length; i++) {
          const type = classNames[i];
          const adaptedData = data[type] || [];

          for (const item of adaptedData) {
            item.detection = await setDetectionsObj(item.id);
            item.objectName = classNameAdapt[i];
            item.objectType = type;
          }

          newData.push(...adaptedData);
        }

        setObjects([...newData]);
        setLoadingComponents(false);
      } catch (error) {
        console.error(error);
      }
    };

    getObjects(pointDetailOffcanvas.point.id);
    handleFilterClick("all");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointDetailOffcanvas]);

  async function setDetectionsObj(objectId) {
    try {
      const { data } = await api.get(
        `/detections-obb/detected-objects/${objectId}`
      );

      data.content.sort((a, b) => calcBbArea(b) - calcBbArea(a));

      return data.content;
    } catch (error) {
      console.error(error);
    }
  }

  function calcBbArea(detection) {
    let bb_width = detection.x2 - detection.x1;
    let bb_height = detection.y4 - detection.y1;

    return bb_width * bb_height;
  }

  function handleDeleteClick(item) {
    setItemToDelete(item);
    toggle();
  }

  const deleteObject = async (type) => {
    const paths = {
      crossHeads: "cross-heads",
      fiberOpticJunctionBoxes: "fiber-optic-junction-boxes",
      lamps: "lamps",
      lampArms: "lamp-arms",
      telecomBoxes: "telecom-boxes",
      transformers: "transformers",
      wireReserves: "wire-reserves",
    };
    try {
      await api.delete(`/detected-objects/${paths[type]}/${itemToDelete?.id}`);

      const updatedObjects = objects.filter(
        (object) => object.id !== itemToDelete.id
      );

      setObjects(updatedObjects);
    } catch (e) {
      console.log(e);
    } finally {
      toggle();
    }
  };

  return (
    <>
      <ModalConfirmation
        toggle={toggle}
        modal={modal}
        text={`Tem certeza que deseja excluir o objeto #${itemToDelete?.id}?`}
        confirmFunction={() => deleteObject(itemToDelete.objectType)}
      />
      <Card
        className="modal-pole-components-card"
        style={{
          left: "7%",
          top: "10%",
          position: "absolute",
          zIndex: "1030",
          display: "block",
          overflowX: "scroll",
          maxHeight: "85%",
          maxWidth: "50%",
        }}
      >
        <CardTitle
          style={{
            position: "sticky",
            top: 0,
            zIndex: "2000",
            backgroundColor: "white",
          }}
        >
          <div className="d-flex align-items-center p-3 gap-1">
            <Col style={{ fontSize: "1.2em" }}>Componentes do Poste</Col>

            <BsXLg
              onClick={() => setViewLightComponents(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <hr className="m-0" />
        </CardTitle>
        <CardBody className="">
          <div>
            <div className="filter-options-container">
              <div
                className="filter-option"
                onClick={() => handleFilterClick("all")}
              >
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    borderRadius: "50%",
                    backgroundColor: "#ccc",
                    border:
                      filterType === "all" ? "3px solid black" : "2px #FDF5E6",
                  }}
                />

                <strong>Todos ({objects.length || 0})</strong>
              </div>

              {classNames.map((className, index) =>
                objects.filter(
                  (item) => item.objectName === classNameAdapt[index]
                ).length > 0 ? (
                  <div
                    key={Math.random()}
                    className="filter-option"
                    onClick={() => handleFilterClick(classNameAdapt[index])}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        backgroundColor: classColors[index] || "#ccc",
                        border:
                          filterType === classNameAdapt[index]
                            ? "2px solid black"
                            : "2px #FDF5E6",
                      }}
                    />
                    <strong>{`${classNameAdapt[index]} (${
                      typeCounts[classNameAdapt[index]] || 0
                    })`}</strong>
                  </div>
                ) : null
              )}
            </div>

            {loadingComponents && (
              <div className="m-auto text-center">
                <Spinner
                  color="primary"
                  style={{
                    margin: "auto",
                    width: "100px",
                    height: "100px",
                  }}
                >
                  Loading...
                </Spinner>
              </div>
            )}

            <div className="components-container">
              {handleFilter?.map((item, index) => (
                <div
                  className="components-card"
                  key={Math.random()}
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    className="div-canvas"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      openDetailsModal(item.poleId, item.detection);
                    }}
                  >
                    <ImageCrop
                      imageSrc={item.detection[0]?.image.path_low}
                      detectionData={item.detection[0]}
                    />
                  </div>
                  <div className="components-info-column">
                    <div>
                      <div>
                        <strong>{item.objectName}</strong> #{item.id}
                      </div>
                    </div>
                    {item.led != null && (
                      <div>
                        <div>
                          <strong>Led:</strong>{" "}
                          <span>{item.led ? "Sim" : "Não"}</span>
                        </div>
                      </div>
                    )}
                    {item.allDayOn != null && (
                      <div>
                        <div>
                          <strong>Ligada 24Hrs:</strong>{" "}
                          <span>{item.allDayOn ? "Sim" : "Não"}</span>
                        </div>
                      </div>
                    )}
                    {item.objectType === "lamps" &&
                      item.distanceToFather != null && (
                        <div>
                          <div>
                            <strong>Projeção horizontal:</strong>{" "}
                            <span>{item.distanceToFather.toFixed(2)} m</span>
                          </div>
                        </div>
                      )}

                    {item.length != null && (
                      <div>
                        <div>
                          <strong>Comprimento:</strong>{" "}
                          <span>{item.length.toFixed(2)} m</span>
                        </div>
                      </div>
                    )}
                    {item.objectType !== "lamp-arms" &&
                      item.distanceToGround != null && (
                        <div>
                          <div>
                            <strong>Distância do chão:</strong>{" "}
                            <span>{item.distanceToGround.toFixed(2)} m</span>
                          </div>
                        </div>
                      )}
                    {item.objectType !== "lamp-arms" && item.height != null && (
                      <div>
                        <div>
                          <strong>Altura:</strong>{" "}
                          <span>{item.height.toFixed(2)} m</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <small
                      className="btn btn-danger remove-btn"
                      onClick={() => handleDeleteClick(item)}
                    >
                      Remover
                    </small>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ModalPoleComponents;
