// import { useKeycloak, keycloakPromise } from "@react-keycloak/web";
// import { resolve } from "chart.js/dist/helpers/helpers.options";
let permissions = [
  {
    role: "OUTSOURCED",
    name: "Terceirizado",
  },
  {
    role: "EMPLOYEE",
    name: "Colaborador",
  },
  {
    role: "MANAGER",
    name: "Gerente",
  },
  {
    role: "ADMIN",
    name: "Administrador",
  },
];
const CheckPermission = (userPermission, permissionString) => {
  //userPermission = "MANAGER";

  let exists = false;
  let resultPermission = false;
  for (let i = 0; i < permissions.length; i++) {
    if (permissionString === permissions[i].role) {
      exists = true;
    }
    if (exists && userPermission === permissions[i].role) {
      resultPermission = true;
    }
  }

  return resultPermission;
};

export default CheckPermission;
export const permissionsList = permissions;
