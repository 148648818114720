import { useMemo, useRef } from "react";
import { Marker } from "react-leaflet";

function DraggableMarker({ icon, newPointPosition, setNewPointPosition }) {
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setNewPointPosition(marker.getLatLng());
        }
      },
    }),
    [setNewPointPosition]
  );

  return (
    <Marker
      draggable={true}
      eventHandlers={eventHandlers}
      position={newPointPosition}
      ref={markerRef}
      icon={icon}
    />
  );
}

export default DraggableMarker;
