import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Input, Label, Row, Col, Spinner, Button } from "reactstrap";
import {
  BsFillPinMapFill,
  BsChevronDoubleLeft,
  BsDownload,
  BsFillCaretRightFill,
  BsChevronDoubleRight,
  BsXLg,
} from "react-icons/bs";

import "bootstrap/dist/css/bootstrap.min.css";
import "../elements/Styles.css";
import GetTokenApi from "../pages/KeyCloak/GetTokenApi";
import { useEffect } from "react";

var hash = require("object-hash");

const SideBarMap = (props) => {
  const [collapseOpen, setCollapseOpen] = useState({});

  const [keepMenuOpen, setKeepMenuOpen] = useState(false);
  const [userPermission, setUserPermission] = useState(null);

  const [secondaryMenuContentOpen, setSecondaryMenuContentOpen] =
    useState(null);
  const [secondaryMenuContent, setSecondaryMenuContent] = useState(null);
  const [content, setContent] = useState(null);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [orientationMode, setOrientationMode] = useState(null);
  const [forceHideMenu, setForceHideMenu] = useState(null);
  const [leftMenu, setLeftMenu] = useState(false);
  const [leftMenuOpen, setLeftMenuOpen] = useState(false);

  useEffect(() => {
    if (props.hideLeftMenu[0]) {
      setLeftMenuOpen(false);
      props.hideLeftMenu[1](false);
    }
  }, [props]);

  useEffect(() => {
    if (windowSize !== [window.innerWidth, window.innerHeight]) {
      setWindowSize(null);
      setWindowSize([window.innerWidth, window.innerHeight]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => {
    // handleResize();
    if (
      windowSize &&
      windowSize[0] &&
      windowSize[1] &&
      windowSize[0] <= windowSize[1]
    ) {
      setOrientationMode("portrait");
      setLeftMenu(true);
    } else if (
      windowSize &&
      windowSize[0] &&
      windowSize[1] &&
      windowSize[0] > windowSize[1]
    ) {
      setOrientationMode("landscape");
      setLeftMenu(false);
    } else {
      setOrientationMode(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });

  useEffect(() => {}, [props.loading]);

  useEffect(() => {
    Object.entries(collapseOpen).filter((i) => i[1] === true).length > 0
      ? setKeepMenuOpen(true)
      : setKeepMenuOpen(false);
  }, [collapseOpen]);

  useEffect(() => {
    if (!secondaryMenuContentOpen) {
      setSecondaryMenuContent(null);
    }
    let auxContent = [];
    Object.entries(props).forEach((propsItem) => {
      if (propsItem[0] === "pointTable") {
        propsItem[1].forEach((e) => {
          auxContent.push(
            <div key={Math.random()}>
              <div
                className="m-0 mb-auto item-menu"
                onClick={() => {
                  if (e[1]) {
                    e[0](false);
                  } else {
                    setLeftMenuOpen(false);
                    setSecondaryMenuContentOpen(false);
                    e[0](true);
                  }
                }}
                key={Math.random()}
              >
                <h3 className="text-black-50 m-0 text-center icon-menu">
                  <img alt="icon" src="/imgs/TABELA.svg" className="icon" />
                </h3>
              </div>
              <hr />
            </div>
          );
        });
      } else if (propsItem[0] === "filters") {
        propsItem[1].forEach((e) => {
          auxContent.push(
            <div key={Math.random()}>
              <div className="m-0 mb-auto item-menu" key={Math.random()}>
                <div
                  onClick={() => {
                    if (e[0]) e[1](false);
                    else e[1](true);
                  }}
                >
                  <h3 className="text-black-50 m-0 text-center icon-menu">
                    {e[2]}
                  </h3>
                </div>

                <BsFillCaretRightFill
                  style={{ fontSize: "0.6em" }}
                  className="text-secondary mt-auto mb-auto"
                />
              </div>
              <hr />
            </div>
          );
        });
      } else if (propsItem[0] === "missions") {
        propsItem[1].forEach((e) => {
          let idElement;
          if (e[0]) idElement = hash(e[0]);
          else hash(e);

          if (secondaryMenuContentOpen === e[0]) {
            if (e[1]) {
              let auxSecondaryMenuContent = [];
              auxSecondaryMenuContent.push(
                <div key={Math.random()}>
                  <h5 className="row m-0 p-0">
                    <div className="col">{e[0]}</div>
                    <div className="col-2">
                      <small>
                        <Link
                          to={false}
                          className="ms-auto"
                          onClick={() => {
                            setSecondaryMenuContentOpen(null);
                          }}
                        >
                          <BsXLg />
                        </Link>
                      </small>
                    </div>
                  </h5>
                  <hr />
                </div>
              );
              auxSecondaryMenuContent.push(
                <div key={Math.random()}>
                  <Row className="mt-0 mb-0" key={Math.random()}>
                    <Col sm={1} className="col-1 m-auto">
                      <Input
                        id={"filterCheckAll" + idElement}
                        addon
                        type="checkbox"
                        className="m-1"
                        defaultChecked={e[2].length === 0 ? true : false}
                        onChange={() => {
                          e[3]([]);
                          e[6](true);
                        }}
                      />
                    </Col>
                    <Col>
                      <Label
                        className="m-auto"
                        style={{ lineHeight: "1em" }}
                        for={"filterCheckAll" + idElement}
                      >
                        <small style={{ fontSize: "0.7em" }}>Todas</small>
                      </Label>
                    </Col>
                  </Row>
                </div>
              );
              auxSecondaryMenuContent.push(
                <div key={Math.random()}>
                  <Row className="mt-0 mb-0" key={Math.random()}>
                    <Col sm={1} className="m-auto col-1">
                      <Input
                        id={"filterCheckMergedPoints" + idElement}
                        addon
                        type="checkbox"
                        className="m-1"
                        defaultChecked={e[5]}
                        onChange={() => {
                          e[5] ? e[6](false) : e[6](true);
                          // e[3]([]);
                        }}
                      />
                    </Col>
                    <Col>
                      <Label
                        className="m-auto"
                        style={{ lineHeight: "1em" }}
                        for={"filterCheckMergedPoints" + idElement}
                      >
                        <small style={{ fontSize: "0.7em" }}>
                          Merged Points
                        </small>
                      </Label>
                    </Col>
                  </Row>
                </div>
              );
              e[1].map((it) => {
                auxSecondaryMenuContent.push(
                  <div key={Math.random()}>
                    <Row className="mt-0 mb-0" key={Math.random()}>
                      <Col sm={1} className="m-auto col-1">
                        <Input
                          id={"filterPowerCheck" + it + idElement}
                          addon
                          type="checkbox"
                          className="m-1"
                          defaultChecked={
                            e[2].includes(Array.isArray(it) ? it[0] : it)
                              ? true
                              : false
                          }
                          onChange={() => {
                            let aux = JSON.parse(JSON.stringify(e[2]));

                            if (aux.includes(Array.isArray(it) ? it[0] : it)) {
                              aux.splice(
                                aux.indexOf(Array.isArray(it) ? it[0] : it),
                                1
                              );
                            } else {
                              aux.push(Array.isArray(it) ? it[0] : it);
                            }
                            e[3](aux);
                          }}
                        />
                      </Col>
                      <Col>
                        <Label
                          className="m-auto"
                          style={{ lineHeight: "1em" }}
                          for={"filterPowerCheck" + it + idElement}
                        >
                          <small style={{ fontSize: "0.7em" }}>
                            {Array.isArray(it)
                              ? it[1]
                              : it > 0
                              ? it + "w"
                              : !it
                              ? "Não definido"
                              : it + ""}
                          </small>
                        </Label>
                      </Col>
                    </Row>
                    {it && it[2] && it[2][0] && it[2][0].path ? (
                      <a
                        href={it[2][0].path}
                        className="m-0 p-0 btn btn-outline-secondary"
                      >
                        <Row className="m-0 p-0">
                          <Col xs={2}>
                            <BsDownload className=" p-0" />
                          </Col>
                          <Col>
                            <small style={{ fontSize: "0.7em" }}>
                              Download KMZ
                            </small>
                          </Col>
                        </Row>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                );
                return true;
              });
              setSecondaryMenuContent(auxSecondaryMenuContent);
            } else {
              setSecondaryMenuContent(
                <Row>
                  <Col className="text-center">
                    <Spinner
                      style={{
                        // margin: "auto",
                        height: "15px",
                        width: "15px",
                      }}
                      color="secondary"
                      className="me-3"
                    >
                      Loading...
                    </Spinner>
                  </Col>
                </Row>
              );
            }
          }

          auxContent.push(
            <div key={Math.random()}>
              <div className="m-0 mb-auto item-menu" key={Math.random()}>
                <div
                  onClick={() => {
                    if (secondaryMenuContentOpen === e[0]) {
                      setSecondaryMenuContentOpen(null);
                    } else {
                      setSecondaryMenuContentOpen(e[0]);
                    }
                  }}
                >
                  <h3 className="text-black-50 m-0 text-center icon-menu">
                    {e[4]}
                  </h3>
                </div>
                <BsFillCaretRightFill
                  style={{ fontSize: "0.6em" }}
                  className="text-secondary mt-auto mb-auto"
                />
              </div>
              <hr />
            </div>
          );
        });
      } else if (propsItem[0] === "orthophoto") {
        propsItem[1].forEach((e) => {
          let idElement;
          if (e[0]) idElement = hash(e[0]);
          else hash(e);

          if (secondaryMenuContentOpen === e[0]) {
            if (e[1] && e[1].length > 0) {
              let auxSecondaryMenuContent = [];

              auxSecondaryMenuContent.push(
                <div key={Math.random()}>
                  <h5 className="row m-0 p-0">
                    <div className="col">{e[0]}</div>
                    <div className="col-2">
                      <small>
                        <Link
                          to={false}
                          className="ms-auto"
                          onClick={() => {
                            setSecondaryMenuContentOpen(null);
                          }}
                        >
                          <BsXLg />
                        </Link>
                      </small>
                    </div>
                  </h5>
                  <hr />
                </div>
              );
              auxSecondaryMenuContent.push(
                <Row className="m-0 p-0" key={Math.random()}>
                  <Col xs={1} className="row m-0 p-0 col-1">
                    <Input
                      id={"orthophotoSelectCheckbox" + idElement}
                      addon
                      type="checkbox"
                      className="m-auto p-0"
                      defaultChecked={
                        e[6] && Object.entries(e[6]).length > 0 ? false : true
                      }
                      name="orthophotoSelectCheckbox"
                      onClick={() => {
                        e[5]({});
                        e[4](false);
                        e[3](false);
                      }}
                    />
                  </Col>
                  <Col xs={11} className="col">
                    <Label
                      className="m-auto p-0"
                      for={"orthophotoSelectCheckbox" + idElement}
                    >
                      <small style={{ fontSize: "0.7em" }}>Nenhum</small>
                    </Label>
                  </Col>
                </Row>
              );
              e[1].map((it) => {
                let checked = false;
                if (e[6]) {
                  Object.entries(e[6]).forEach((layerKmz) => {
                    if (layerKmz[0].toString() === it.id.toString()) {
                      checked = true;
                    }
                  });
                }
                auxSecondaryMenuContent.push(
                  <Row className="m-0 p-0" key={Math.random()}>
                    <Col xs={1} className="row m-0 p-0 col-1">
                      <Input
                        id={"orthophotoSelectCheckbox" + it.id + idElement}
                        addon
                        type="checkbox"
                        className="m-auto p-0"
                        name="orthophotoSelectCheckbox"
                        onClick={() => {
                          let kmzList = e[6];
                          let auxKmzList = {};
                          if (checked) {
                            Object.entries(kmzList).forEach((tm) => {
                              if (tm[0].toString() !== it.id.toString()) {
                                auxKmzList[tm[0]] = tm[1];
                              }
                            });
                            e[5](auxKmzList);
                            e[7](true);
                            e[3](false);
                            e[4](null);
                            setTimeout(() => {
                              e[7](false);
                              e[4](
                                e[8].filter((kmlItem) => kmlItem.id !== it.id)
                              );
                              e[3](true);
                            }, 100);
                          } else {
                            let listKmlActive = e[8];

                            if (!listKmlActive) {
                              listKmlActive = [];
                            }

                            let exists = false;
                            listKmlActive.forEach((kmlActiveItem) => {
                              if (it === kmlActiveItem) {
                                exists = true;
                              }
                            });
                            if (!exists) {
                              listKmlActive.push(it);
                            }
                            e[4](false);
                            setTimeout(() => {
                              e[4](listKmlActive);
                            }, 1);
                            // e[4](it);
                            e[3](true);
                          }
                        }}
                        defaultChecked={checked}
                      />
                    </Col>
                    <Col xs={11} className="col">
                      <Label
                        className="m-auto p-0"
                        for={"orthophotoSelectCheckbox" + it.id + idElement}
                      >
                        <small style={{ fontSize: "0.7em" }}>{it.name}</small>
                      </Label>
                    </Col>
                  </Row>
                );

                return true;
              });
              setSecondaryMenuContent(auxSecondaryMenuContent);
            } else if (e[1] && e[1].length === 0) {
              setSecondaryMenuContent("");
            } else {
              setSecondaryMenuContent(
                <Row className="m-0 text-center">
                  <div className="m-auto">
                    <Spinner
                      style={{
                        height: "15px",
                        width: "15px",
                      }}
                      color="secondary"
                    >
                      Loading...
                    </Spinner>
                  </div>
                </Row>
              );
            }
          }
          auxContent.push(
            <div key={Math.random()}>
              <div
                className="m-0 mb-auto item-menu"
                key={Math.random()}
                onClick={() => {
                  if (e[1] && e[1].length > 0) {
                    if (secondaryMenuContentOpen === e[0]) {
                      setSecondaryMenuContentOpen(null);
                    } else {
                      setSecondaryMenuContentOpen(e[0]);
                    }
                  } else {
                    props.toast({
                      title: "KMZ do projeto",
                      message: (
                        <div>
                          <div className="mt-1 mb-1">
                            Não existe KMZ vinculado ao projeto.
                          </div>
                        </div>
                      ),
                      timer: 3000,
                    });
                  }
                }}
              >
                <div>
                  <h3 className="text-black-50 m-0 text-center icon-menu">
                    <BsFillPinMapFill />
                  </h3>
                </div>

                <BsFillCaretRightFill
                  style={{ fontSize: "0.6em" }}
                  className="text-secondary mt-auto mb-auto"
                />
              </div>
              <hr />
            </div>
          );
        });
      }
    });
    setContent(auxContent);
  }, [props, secondaryMenuContentOpen]);

  return (
    <>
      {forceHideMenu ||
      (leftMenu &&
        orientationMode &&
        orientationMode === "portrait" &&
        !secondaryMenuContentOpen) ? (
        <Link
          className={
            "row m-0 p-0 left-menu-arrow-control" +
            (leftMenuOpen ? " left-menu-arrow-control-open" : "")
          }
          style={{ zIndex: "1050" }}
          onClick={() => {
            if (leftMenuOpen) {
              setLeftMenuOpen(false);
            } else {
              setLeftMenuOpen(true);
            }
          }}
        >
          <h3 className="mt-auto ms-auto me-3 p-0">
            {leftMenuOpen ? <BsChevronDoubleLeft /> : <BsChevronDoubleRight />}
          </h3>
        </Link>
      ) : (
        ""
      )}
      <div
        id="barraLateral"
        className={
          "menu-lateral text-center m-0 pt-4 p-1" +
          (keepMenuOpen ? " menu-lateral-keep-open" : "") +
          (forceHideMenu || (orientationMode && orientationMode === "portrait")
            ? " menu-left-portrait"
            : "") +
          (leftMenuOpen ? " menu-lateral-left-portrait-open" : "")
        }
      >
        <Row className="m-0 p-0" key={Math.random()}>
          <Col className="m-0 p-0" key={Math.random()}>
            <Link
              key={Math.random()}
              to={"/home"}
              className="m-0 mb-auto item-menu"
            >
              <div>
                <h3 className="text-black-50 m-0 text-center icon-menu">
                  <img src="/imgs/HOME.svg" alt="" className="icon" />
                  {/* <ReactSVG src="/imgs/HOME.svg" className="icon" /> */}
                </h3>
              </div>
            </Link>
            <hr />
            {content && content}
          </Col>
          {Object.entries(collapseOpen).filter((i) => i[1] === true).length >
          0 ? (
            <Col className="row m-0 p-0" sm={1} key={Math.random()}>
              <div className="m-auto p-2">
                <Link
                  to={false}
                  className="m-auto"
                  onClick={(obj) => {
                    obj.preventDefault();
                    let aux = collapseOpen;
                    setCollapseOpen(false);
                    Object.entries(aux).forEach((el) => {
                      aux[el[0]] = false;
                    });
                    setTimeout(() => {
                      setCollapseOpen(aux);
                    }, 300);
                  }}
                >
                  <BsChevronDoubleLeft />
                </Link>
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </div>
      {secondaryMenuContent ? (
        <div key={Math.random()} className="m-0 secondary-menu">
          <div>
            <Row className="m-0 p-3">{secondaryMenuContent}</Row>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default SideBarMap;
