import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ImageCrop from "../../pages/Map/ImageCrop";
import ModalConfirmation from "../ModalConfirmation/ModalConfirmation";
import ModalMessage from "../ModalMessage/ModalMessage";
import api from "../../api/api";

const objectTypeNames = {
  cross_head: { name: "Cruzeta", path: "cross-heads" },
  fiber_optic_junction_box: {
    name: "Caixa junção fibra óptica",
    path: "fiber-optic-junction-boxes",
  },
  lamp: { name: "Lâmpada", path: "lamps" },
  lamp_arm: { name: "Braço de luminária", path: "lamp-arms" },
  telecom_box: { name: "Caixa de telecom", path: "telecom-boxes" },
  transformer: { name: "Transformador", path: "transformers" },
  wire_reserver: { name: "Reserva de fios", path: "wire-reserves" },
};

const ExclusionModal = ({ isOpen, toggle, detections, updateUiFunction }) => {
  const [selectedDetections, setSelectedDetections] = useState([]);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [messageModal, setMessageModal] = useState(null);
  const [message, setMessage] = useState("");

  const toggleMessageModal = () => setMessageModal(!messageModal);

  const toggleModalConfirmation = () =>
    setModalConfirmation(!modalConfirmation);

  const handleCheckboxChange = (
    detectionId,
    detectedObjectId,
    objectType,
    path
  ) => {
    setSelectedDetections((prevSelected) => {
      const exists = prevSelected.some(
        (d) =>
          d.detectionId === detectionId &&
          d.detectedObjectId === detectedObjectId
      );

      if (exists) {
        return prevSelected.filter(
          (d) =>
            d.detectionId !== detectionId ||
            d.detectedObjectId !== detectedObjectId
        );
      } else {
        return [
          ...prevSelected,
          { detectionId, detectedObjectId, objectType, path },
        ];
      }
    });
  };

  const handleDelete = async () => {
    const deletePromises = selectedDetections.map(async (d) => {
      try {
        await api.delete(`/detections-obb/${d.detectionId}`);

        await api.delete(`/detected-objects/${d.path}/${d.detectedObjectId}`);
      } catch (error) {
        console.error(error);
        throw error;
      }
    });

    try {
      await Promise.all(deletePromises);
      setMessage("Todos os objetos selecionados foram removidos com sucesso!");
      toggleMessageModal();
    } catch (error) {
      setMessage("Um erro ocorreu ao remover os objetos selecionados!");
      toggleMessageModal();
    } finally {
      updateUiFunction();
      setTimeout(() => {
        toggleModalConfirmation();
        toggle();
      }, 2000);
    }
  };

  return (
    <>
      {modalConfirmation && (
        <ModalConfirmation
          toggle={toggleModalConfirmation}
          modal={modalConfirmation}
          text={`Deseja remover os objetos selecionados?`}
          confirmFunction={handleDelete}
        />
      )}

      {messageModal && (
        <ModalMessage
          isOpen={true}
          message={message}
          toggle={toggleMessageModal}
        />
      )}

      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Detecções</ModalHeader>
        <ModalBody>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            {detections.map((d) => {
              if (d.objectType !== "pole") {
                return (
                  <div
                    key={d.detectedObjectId}
                    style={{
                      display: "flex",
                      gap: "1rem",
                    }}
                  >
                    <div>
                      <ImageCrop
                        detectionData={d}
                        imageSrc={d.image.path_low}
                        height={100}
                        width={100}
                      />
                    </div>
                    <p style={{ width: "100%", textAlign: "left" }}>
                      {objectTypeNames[d.objectType].name} #{d.detectedObjectId}
                    </p>
                    <input
                      type="checkbox"
                      checked={selectedDetections.some(
                        (sel) => sel.detectedObjectId === d.detectedObjectId
                      )}
                      onChange={() =>
                        handleCheckboxChange(
                          d.id,
                          d.detectedObjectId,
                          d.objectType,
                          objectTypeNames[d.objectType].path
                        )
                      }
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModalConfirmation}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ExclusionModal;
