import React, { useState, useEffect } from "react";
import Header from "../../elements/Header";
import {
  // useNavigate,
  Link,
  useParams,
  // UNSAFE_enhanceManualRouteObjects,
} from "react-router-dom";
// import { useKeycloak } from "@react-keycloak/web";
// import { useForm } from "react-hook-form";
import {
  // Table,
  // UncontrolledCollapse,
  // Collapse,
  Button,
  // CardBody,
  // Card,
  // ListGroup,
  // ListGroupItem,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { BsArrowLeft } from "react-icons/bs";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import CheckPermission from "../Auth/CheckPermission";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import ValidaSenhaSegura from "./ValidaSenha";

const EditUser = () => {
  document.title = "Editar Perfil";
  let { id: userId } = useParams();
  let apiUrl = process.env.REACT_APP_API_URL;
  const [userPermission, setUserPermission] = useState(null);
  const [permissionsData, setPermissionsData] = useState();
  const [userData, setUserData] = useState();
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });
  const [loadingIcon, setLoadingIcon] = useState(true);
  const [modal, setModal] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
    button: "",
    buttonHref: "",
    buttonAction: "",
  });
  const toggleModal = (content) => {
    if (!content.title) content.title = "";
    if (!content.content) content.content = "";
    if (!content.button) content.button = "Ok";
    if (!content.buttonHref) content.buttonHref = false;
    if (!content.buttonAction) content.buttonAction = false;

    setModalContent(content);
    setModal(!modal);
  };
  function getPermissionsData() {
    fetch(apiUrl + "/users/roles", {
      method: "GET",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setPermissionsData(data);
      })
      .finally(() => {
        getUserData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  async function getUserData() {
    let ret = false;
    setLoadingIcon(true);
    await fetch(apiUrl + "/users/" + userId, {
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",

        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((res) => {
        if (res.status === 200) return res.text();
        else {
          switch (res.status.toString().substring(0, 1)) {
            case "5":
              throw new Error("Erro de comunicação com o servidor.");

            case "4":
              throw new Error("Requição não compreendida.");

            default:
              break;
          }
          return false;
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        // console.log(data);
        setUserData(data);
        ret = data;
      })
      .catch((error) => {
        toggleModal({
          title: "Editar usuário",
          content: "Houve um erro ao buscar os dados do usuário.\n\n" + error,
        });
        console.error("Error:", error);
      })
      .finally(() => {
        setLoadingIcon(false);
      });
    return ret;
  }

  useEffect(() => {
    if (userPermission) {
      getPermissionsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission]);

  const onFormSubmit = (values) => {
    values.preventDefault();
    let fieldsAndValues = {};
    fieldsAndValues["id"] = userId;

    let breakReq = false;

    Object.entries(values.target.elements).forEach((ele) => {
      if (
        ele[0] === "email" &&
        (!ele[1] || !ele[1].value || ele[1].value.toString().length < 2)
      ) {
        breakReq = "O campo email é obrigatório!";
      } else if (ele[1].id === "password" && ele[1].value.length > 0) {
        let statusSenha = ValidaSenhaSegura(ele[1].value);
        console.log(statusSenha);
        if (statusSenha !== "true") {
          breakReq = "A senha " + statusSenha.toString().toLocaleLowerCase();
        }
      }
      let value = ele[1].value;
      if (ele[0] !== "role" && value.toString() !== userData.role.toString()) {
        if (value) fieldsAndValues[ele[1].id] = value;
      }
    });
    console.log(fieldsAndValues);
    if (!breakReq) {
      toggleModal({
        title: "Editar usuário",
        content: (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status" id="loading">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ),
        buttonHref: null,
        actionButton: null,
      });
      fetch(apiUrl + "/users/" + userId, {
        method: "PUT",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userPermission.access_token,
        },
        body: JSON.stringify(fieldsAndValues),
      })
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            return response.text().then((rs) => {
              switch (response.status.toString().substring(0, 1)) {
                case "5":
                  throw new Error("Erro de comunicação com o servidor: " + rs);

                case "4":
                  throw new Error("Requição não compreendida: " + rs);

                default:
                  break;
              }
              return false;
            });
          }
        })
        .then((data) => {
          toggleModal({
            title: "Editar usuário",
            content:
              "Usuário alterado!\n\nVocê poderá vê-lo na página de gerenciamento.",
            buttonHref: "/companiesManagement/" + userData.company_id,
          });
        })
        .catch((error) => {
          toggleModal({
            title: "Editar usuário",
            content: "Houve um erro ao editar o usuário.\n" + error,
            buttonHref: false,
          });
          console.error("Erro:", error);
        });
    } else {
      toggleModal({
        title: "Editar usuário",
        content: "Não foi possível editar o usuário: " + breakReq,
        buttonHref: null,
        actionButton: () => setModal(false),
      });
    }
  };
  return (
    <div>
      <Modal isOpen={modal} toggle={toggleModal} backdrop="static">
        <ModalHeader>{modalContent.title}</ModalHeader>
        <ModalBody>{modalContent.content}</ModalBody>
        <ModalFooter>
          <Link
            className="btn btn-outline-secondary"
            to={modalContent.buttonHref ? modalContent.buttonHref : false}
            onClick={
              modalContent.buttonAction
                ? modalContent.buttonAction
                : () => setModal(false)
            }
          >
            {modalContent.button}
          </Link>
        </ModalFooter>
      </Modal>
      <Header />
      <Tooltip id="my-tooltip" />
      <div className="container" style={{ marginTop: "10vh " }}>
        <div
          id="basicInformation"
          className="container /*main-content*/ recuadoOut"
        >
          <div className="row pt-4">
            <h2 className="col-10 m-0 text-start">
              <Link
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Voltar a tela de empresas"
                className="text-end "
                style={{ marginRight: "0px" }}
                onClick={() => {
                  window.history.back();
                }}
              >
                <BsArrowLeft
                  className="nav-button-circle "
                  style={{ boxShadow: "none" }}
                />
              </Link>
              Editar Usuário
            </h2>
            <div className="col-2 pr-0 m-0 row"></div>
          </div>
          <hr />
          <div
            className="main-content mt-0 p-3"
            style={{ display: "flex", padding: "5px" }}
          >
            <div style={{ width: "100%" }} id="uprojetos">
              {(userPermission && userPermission.id === userId) ||
              CheckPermission(
                userPermission && userPermission.role
                  ? userPermission.role
                  : "",
                "MANAGER"
              ) ? (
                loadingIcon ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status" id="loading">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : CheckPermission(
                    userPermission && userPermission.role
                      ? userPermission.role
                      : "",
                    userData.role
                  ) ? (
                  <Form onSubmit={onFormSubmit}>
                    <FormGroup row>
                      <Label for={"first_name"} sm={3}>
                        Nome
                      </Label>
                      <Col sm={9}>
                        <Input
                          id={"first_name"}
                          name={"first_name"}
                          placeholder={"Nome"}
                          type={"text"}
                          defaultValue={
                            userData && userData.first_name
                              ? userData.first_name
                              : ""
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for={"last_name"} sm={3}>
                        Sobrenome
                      </Label>
                      <Col sm={9}>
                        <Input
                          id={"last_name"}
                          name={"last_name"}
                          placeholder={"Sobrenome"}
                          type={"text"}
                          defaultValue={
                            userData && userData.last_name
                              ? userData.last_name
                              : ""
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for={"email"} sm={3}>
                        Email
                      </Label>
                      <Col sm={9}>
                        <Input
                          id={"email"}
                          name={"email"}
                          placeholder={"email"}
                          type={"text"}
                          defaultValue={
                            userData && userData.email ? userData.email : ""
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for={"password"} sm={3}>
                        Senha
                      </Label>
                      <Col sm={9}>
                        <Input
                          id={"password"}
                          name={"password"}
                          placeholder={"Deixe em branco para manter"}
                          type={"password"}
                          defaultValue={
                            userData && userData.password
                              ? userData.password
                              : ""
                          }
                          onChange={(t) => {
                            if (t.target.value.toString().length > 0) {
                              let statusSenha = ValidaSenhaSegura(
                                t.target.value
                              );
                              if (statusSenha !== "true") {
                                setPasswordMessage(
                                  <Row className={"text-danger"}>
                                    {statusSenha}
                                  </Row>
                                );
                              } else {
                                setPasswordMessage(
                                  <Row className={"text-success"}>
                                    A senha atende aos requisitos de
                                    complexidade!
                                  </Row>
                                );
                              }
                            } else {
                              setPasswordMessage();
                            }
                          }}
                        />
                        <div className="ms-3 mt-1">
                          <small>
                            {passwordMessage ? passwordMessage : ""}
                          </small>
                        </div>
                      </Col>
                    </FormGroup>
                    {userData ? (
                      userData.id !== localStorage.getItem("robotictech_id") ? (
                        <FormGroup row>
                          <Label for={"role"} sm={3}>
                            Permissão
                          </Label>
                          <Col sm={9}>
                            <select
                              className="form-select"
                              id={"role"}
                              name={"role"}
                              defaultValue={userData.role}
                            >
                              {/* <option value={""}>Manter</option> */}
                              {permissionsData
                                ? permissionsData.map((permissionsItem, i) => {
                                    if (
                                      CheckPermission(
                                        userPermission && userPermission.role
                                          ? userPermission.role
                                          : "",
                                        permissionsItem.role
                                      ) &&
                                      permissionsItem &&
                                      permissionsItem.role !==
                                        localStorage.getItem("robotictech_role")
                                    ) {
                                      // if (
                                      //   localStorage.getItem(
                                      //     "robotictech_role"
                                      //   ) === "ADMIN" ||
                                      //   (permissionsItem &&
                                      //     permissionsItem.role &&
                                      //     permissionsItem.role !== "ADMIN")
                                      // ) {
                                      return (
                                        <option
                                          key={i}
                                          value={permissionsItem.role}
                                        >
                                          {permissionsItem.name}
                                        </option>
                                      );
                                    } else {
                                      return false;
                                    }
                                  })
                                : ""}
                            </select>
                          </Col>
                        </FormGroup>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {/* {fields.map((e) => {
                      return createField(e);
                    })} */}
                    <div className="row">
                      <Button
                        className="col-8 m-auto"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Salvar as informações"
                      >
                        Salvar
                      </Button>
                    </div>
                  </Form>
                ) : (
                  <h5 className="m-2">
                    Você não tem permissão para alterar os dados deste usuário.
                  </h5>
                )
              ) : (
                <h4>Sem permissão para acessar esta área.</h4>
              )}
              {/* <Link onClick={() => toggleModal({title: 'teste titulo', content: 'teste content'})}>
                Abrir modal
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
