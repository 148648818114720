import React, { useEffect, useState } from "react";
import { BsXLg } from "react-icons/bs";
import { Button, Card, CardBody, CardFooter, CardTitle } from "reactstrap";
import api from "../../api/api";
import GalleryNewPole from "../GalleryNewPole/GalleryNewPole";
import LoadingIcon from "../LoadingIcon/LoadingIcon";

const ModalNewPole = ({
  toggle,
  pointPosition,
  setActivePointSupervision,
  toggleSupervisionMode,
  setPreviewImagesNewPoint,
  setPreviewImagesVisible
}) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [galleryModal, setGalleryModal] = useState(false);
  const toggleGalleryModal = () => setGalleryModal(!galleryModal);

  useEffect(() => {
    const getImagesByCoordinates = async () => {
      setLoading(true);
      try {
        const { data } = await api.post("/images/coordinates", {
          latitude: pointPosition.lat,
          longitude: pointPosition.lng,
        });

        setImages(data);
        setPreviewImagesNewPoint(data);
        setPreviewImagesVisible(true);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };

    getImagesByCoordinates();
  }, [pointPosition]);

  return (
    <>
      {galleryModal && images.length > 0 && (
        <GalleryNewPole
          imageArray={images}
          isOpen={galleryModal}
          toggle={toggleGalleryModal}
          coordinates={pointPosition}
          setActivePointSupervision={setActivePointSupervision}
          toggleSupervisionMode={toggleSupervisionMode}
          toggleModalNewPole={toggle}
        />
      )}

      <Card
        style={{
          right: "10%",
          top: "10%",
          position: "absolute",
          zIndex: "1030",
          display: "block",
          overflowX: "scroll",
          width: "350px",
        }}
      >
        <CardTitle>
          <div className="d-flex align-items-center p-3 gap-1">
            <BsXLg style={{ cursor: "pointer" }} onClick={toggle} />
          </div>
          <hr className="m-0" />
        </CardTitle>
        <CardBody>
          <div>
            <span>Latitude: {pointPosition.lat}</span>
            <br />
            <span>Longitude: {pointPosition.lng}</span>
          </div>
          <br />
          <div>
            <span>{images.length} imagens encontradas</span>

            {loading && <LoadingIcon />}
          </div>
        </CardBody>
        <CardFooter style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={images.length === 0}
            onClick={() => toggleGalleryModal()}
          >
            Selecionar Imagens
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default ModalNewPole;
