const ValidaSenhaSegura = (p) => {
  var retorno = false;
  var letrasMaiusculas = /[A-Z]/;
  var letrasMinusculas = /[a-z]/;
  var numeros = /[0-9]/;
  var caracteresEspeciais = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
  var auxMaiuscula = 0;
  var auxMinuscula = 0;
  var auxNumero = 0;
  var auxEspecial = 0;

  for (var i = 0; i < p.length; i++) {
    if (letrasMaiusculas.test(p[i])) auxMaiuscula++;
    else if (letrasMinusculas.test(p[i])) auxMinuscula++;
    else if (numeros.test(p[i])) auxNumero++;
    else if (caracteresEspeciais.test(p[i])) auxEspecial++;
  }
  if (p.length >= 8) {
    if (auxMaiuscula > 0) {
      if (auxMinuscula > 0) {
        if (auxNumero > 0) {
          if (auxEspecial) {
            retorno = "true";
          } else {
            retorno = "Precisa de caractere especial";
          }
        } else {
          retorno = "Precisa de número";
        }
      } else {
        retorno = "Precisa de minuscula";
      }
    } else {
      retorno = "Precisa de maiuscula";
    }
  } else {
    retorno = "Precisa de 8 caracteres";
  }
  return retorno;
};

export default ValidaSenhaSegura;
