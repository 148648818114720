import React, { useEffect, useState } from "react";
import {
  Button,
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Input,
  Label,
  Modal,
} from "reactstrap";
import api from "../../api/api";
import { useParams } from "react-router-dom";
import PhotoSphereViewer from "../PhotoSphereViewer/PhotoSphereViewer";
import ModalNewAnnotation from "../ModalNewAnnotation/ModalNewAnnotation";
import "./ModalSupervisionMode.css";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import ModalConfirmation from "../ModalConfirmation/ModalConfirmation";
import ModalMessage from "../ModalMessage/ModalMessage";
import ModalForm from "../ModalForm/ModalForm";
import { IoCloseSharp } from "react-icons/io5";
import ExclusionModal from "../ExclusionModal/ExclusionModal";

const objectTypeNames = [
  { text: "Poste", value: "pole", path: "poles" },
  { text: "Cruzeta", value: "cross_head", path: "cross-heads" },
  {
    text: "Caixa junção fibra óptica",
    value: "fiber_optic_junction_box",
    path: "fiber-optic-junction-boxes",
  },
  { text: "Lâmpada", value: "lamp", path: "lamps" },
  { text: "Braço de luminária", value: "lamp_arm", path: "lamp-arms" },
  { text: "Caixa de telecom", value: "telecom_box", path: "telecom-boxes" },
  { text: "Transformador", value: "transformer", path: "transformers" },
  { text: "Reserva de fios", value: "wire_reserver", path: "wire-reserves" },
];

const ModalSupervisionMode = ({
  toggleSupervisionMode,
  supervisionModeOpen,
  setActivePointSupervision,
  activePointSupervision,
  detectionsArray = null,
  poleInfo = null,
}) => {
  const { id: projectId } = useParams();
  const [poles, setPoles] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [currentPoleDetections, setCurrentPoleDetections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentObjectType, setCurrentObjectType] = useState("all");
  const [filteredDetections, setFilteredDetections] = useState([]);
  const [annotation, setAnnotation] = useState(false);
  const [newAnnotationModal, setNewAnnotationModal] = useState(false);
  const [annotationType, setAnnotationType] = useState("cross_head");
  const [boundingBox, setBoundingBox] = useState([]);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [messageModal, setMessageModal] = useState(null);
  const [message, setMessage] = useState("");
  const [formModal, setFormModal] = useState(false);
  const [formObjects, setFormObjects] = useState();
  const [exclusionModal, setExclusionModal] = useState(false);

  const toggleNewAnnotationModal = () =>
    setNewAnnotationModal(!newAnnotationModal);

  const toggleModalConfirmation = () =>
    setModalConfirmation(!modalConfirmation);

  const toggleMessageModal = () => setMessageModal(!messageModal);

  const toggleFormModal = () => setFormModal(!formModal);

  const toggleModalExclusion = () => setExclusionModal(!exclusionModal);

  useEffect(() => {
    const getPoles = async () => {
      try {
        const { data } = await api.get(
          `/projects/${projectId}/poles?sort=id&size=2000`
        );
        setPoles(data.content);

        if (activePointSupervision != null) {
          const activeIndexPointSupervision = data.content.findIndex(
            (d) => d.id === activePointSupervision.id
          );
          setActiveIndex(activeIndexPointSupervision);
        } else {
          setActivePointSupervision(data.content[0]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (poleInfo === null) {
      getPoles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    const getPoleBestDetection = async (poleId) => {
      setLoading(true);
      try {
        const { data } = await api.get(
          `/detections-obb/detected-objects/${poleId}`
        );

        if (data.content.length > 0) {
          const sortedDetections = data.content.sort(
            (a, b) => calcBbArea(b) - calcBbArea(a)
          );

          const bestDetection = sortedDetections[0];

          await fetchDetections(
            bestDetection.image.id,
            bestDetection.detectedObjectId,
            bestDetection.objectType
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (poles.length > 0) {
      getPoleBestDetection(poles[activeIndex].id);
    }
  }, [activeIndex, poles]);

  useEffect(() => {
    if (poleInfo !== null) {
      fetchDetections(
        detectionsArray[activeIndex].image.id,
        poleInfo.id,
        "pole"
      );
    }
  }, [poleInfo, activeIndex, detectionsArray]);

  const fetchDetections = async (
    imageId,
    detectedObjectId,
    detectedObjectType
  ) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/images/${imageId}/detections-obb`);
      const filteredData = data.filter((item) => {
        if (item.objectType === "pole") {
          return item.detectedObjectId === detectedObjectId;
        }
        return detectedObjectType === "pole"
          ? item.poleId === detectedObjectId
          : item.objectType !== "tree";
      });
      setCurrentPoleDetections(filteredData);
      setFilteredDetections(filteredData);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const calcBbArea = (detection) =>
    (detection.x2 - detection.x1) * (detection.y4 - detection.y1);

  const handleFilterChange = (type) => {
    setCurrentObjectType(type);
    if (type === "all") {
      setFilteredDetections(currentPoleDetections);
    } else {
      const filteredData = currentPoleDetections.filter(
        (item) => item.objectType === type
      );
      setFilteredDetections(filteredData);
    }
  };

  const handleAnnotation = () => {
    setLoading(true);

    if (!annotation) {
      setNewAnnotationModal(true);
    }
    setAnnotation(!annotation);
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const filteredOptions = objectTypeNames.filter((o) =>
    currentPoleDetections.some((detection) => detection.objectType === o.value)
  );

  const navigate = (nextIndex) => {
    if (!animating) {
      setActiveIndex(nextIndex);
      setAnnotation(false);
      handleFilterChange("all");

      if (poleInfo === null) {
        setActivePointSupervision(poles[nextIndex]);
      }
    }
  };

  const next = () => {
    const arrayLength =
      detectionsArray && detectionsArray.length > 0
        ? detectionsArray.length
        : poles.length;
    navigate(activeIndex === arrayLength - 1 ? 0 : activeIndex + 1);
  };

  const previous = () => {
    const arrayLength =
      detectionsArray && detectionsArray.length > 0
        ? detectionsArray.length
        : poles.length;
    navigate(activeIndex === 0 ? arrayLength - 1 : activeIndex - 1);
  };

  const goToIndex = (newIndex) => {
    navigate(newIndex);
  };

  const saveAnnotation = async () => {
    toggleModalConfirmation();

    const currentPole = poleInfo === null ? poles[activeIndex] : poleInfo;
    const path = objectTypeNames.find((o) => o.value === annotationType)?.path;

    if (!path) {
      return;
    }

    if (boundingBox.length !== 4) {
      toggleMessageModal();
      setMessage("4 pontos devem ser selecionados!");
      return;
    }

    const newObject = {
      latitude: currentPole.latitude,
      longitude: currentPole.longitude,
      poleId: currentPole.id,
      projectId: currentPole.projectId,
      ...formObjects,
    };

    const sortedBoundingBox = sortBoundingBoxCoordinates(boundingBox);

    try {
      const { data } = await api.post(`detected-objects/${path}`, newObject);
      const detection = {
        detectedObjectId: data.id,
        image:
          poleInfo === null
            ? currentPoleDetections[0]?.image
            : detectionsArray[activeIndex].image,
        x1: yawToNormalizedX(sortedBoundingBox[0][0]),
        y1: pitchToNormalizedY(sortedBoundingBox[0][1]),
        x2: yawToNormalizedX(sortedBoundingBox[1][0]),
        y2: pitchToNormalizedY(sortedBoundingBox[1][1]),
        x3: yawToNormalizedX(sortedBoundingBox[2][0]),
        y3: pitchToNormalizedY(sortedBoundingBox[2][1]),
        x4: yawToNormalizedX(sortedBoundingBox[3][0]),
        y4: pitchToNormalizedY(sortedBoundingBox[3][1]),
        objectType: annotationType,
        addedManually: true,
      };

      try {
        await api.post("detections-obb", detection);
      } catch (error) {
        console.error("Error posting detection:", error);
      }
    } catch (error) {
      console.error("Error posting detected object:", error);
    } finally {
      toggleMessageModal();
      toggleModalConfirmation();
      toggleFormModal();

      setMessage("Anotação salva com sucesso!");

      setTimeout(() => {
        if (poleInfo === null) {
          fetchDetections(
            currentPoleDetections[0]?.image.id,
            currentPoleDetections[0]?.detectedObjectId,
            currentPoleDetections[0]?.objectType
          );
        } else {
          fetchDetections(
            detectionsArray[activeIndex]?.image.id,
            poleInfo.id,
            "pole"
          );
        }
      }, 200);

      setAnnotation(false);
      handleFilterChange("all");
    }
  };

  function map(x, inMin, inMax, outMin, outMax) {
    x = Math.min(Math.max(x, inMin), inMax);
    return ((x - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }

  function yawToNormalizedX(yaw) {
    yaw =
      ((((yaw + Math.PI) % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI)) -
      Math.PI;
    return map(yaw, -Math.PI, Math.PI, 0, 1);
  }

  function pitchToNormalizedY(pitch) {
    return 1 - map(pitch, -Math.PI / 2, Math.PI / 2, 0, 1);
  }

  function sortBoundingBoxCoordinates(boundingBox) {
    boundingBox.sort((a, b) => b[1] - a[1]);

    let upperPairs = boundingBox.slice(0, 2);
    let lowerPairs = boundingBox.slice(2, 4);

    upperPairs.sort((a, b) => a[0] - b[0]);
    lowerPairs.sort((a, b) => a[0] - b[0]);

    const sortedBoundingBox = [
      upperPairs[0],
      upperPairs[1],
      lowerPairs[1],
      lowerPairs[0],
    ];

    return sortedBoundingBox;
  }

  function getObjectTypeText(type) {
    return objectTypeNames.filter((o) => o.value === type)[0].text;
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [annotationType]);

  async function updateUiFunction() {
    await fetchDetections(
      currentPoleDetections[0]?.image.id,
      currentPoleDetections[0]?.detectedObjectId,
      currentPoleDetections[0]?.objectType
    );
  }

  return (
    <>
      {newAnnotationModal && (
        <ModalNewAnnotation
          isOpen={newAnnotationModal}
          toggle={toggleNewAnnotationModal}
          options={objectTypeNames}
          annotationType={annotationType}
          setAnnotationType={setAnnotationType}
        />
      )}

      {modalConfirmation && (
        <ModalConfirmation
          toggle={toggleModalConfirmation}
          modal={modalConfirmation}
          text={`Deseja salvar a anotação do objeto "${getObjectTypeText(
            annotationType
          )}"?`}
          confirmFunction={toggleFormModal}
        />
      )}

      {messageModal && (
        <ModalMessage
          isOpen={true}
          message={message}
          toggle={toggleMessageModal}
        />
      )}

      {formModal && (
        <ModalForm
          isOpen={formModal}
          toggle={toggleFormModal}
          saveAnnotation={saveAnnotation}
          annotationType={annotationType}
          setFormObjects={setFormObjects}
        />
      )}

      {exclusionModal && (
        <ExclusionModal
          isOpen={exclusionModal}
          toggle={toggleModalExclusion}
          detections={currentPoleDetections}
          updateUiFunction={updateUiFunction}
        />
      )}

      <Modal
        size="xl"
        isOpen={supervisionModeOpen}
        toggle={toggleSupervisionMode}
        backdrop={true}
        keyboard={false}
        style={{ zIndex: 1600 }}
      >
        <div className="container-modal-header">
          <div className="title">
            <h5>
              Poste #{poleInfo == null ? poles[activeIndex]?.id : poleInfo.id}
            </h5>

            <IoCloseSharp
              style={{ cursor: "pointer" }}
              onClick={toggleSupervisionMode}
            />
          </div>
          <div className="container-header-inputs">
            <div className="container-header-inputs">
              {detectionsArray == null && (
                <Input
                  type="select"
                  className="select-object-type"
                  onChange={(e) => handleFilterChange(e.target.value)}
                  value={currentObjectType}
                  style={{ width: "fit-content" }}
                >
                  <option value="all">Todos</option>
                  {filteredOptions.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.text}
                    </option>
                  ))}
                </Input>
              )}

              <div className="container-switch" style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <div onClick={handleAnnotation} style={{ fontSize: "24px" }}>
                    <img
                      alt="icon"
                      src={
                        annotation
                          ? "/imgs/HABILITAR.svg"
                          : "/imgs/DESABILITAR.svg"
                      }
                      style={{ width: "37px" }}
                    />
                  </div>
                  <Label
                    for="annotation"
                    onClick={handleAnnotation}
                    style={{
                      marginLeft: "8px",
                      marginBottom: "0",
                      cursor: "pointer",
                    }}
                  >
                    Nova anotação
                  </Label>
                </div>
              </div>

              {annotation && (
                <Button color="success" onClick={toggleModalConfirmation}>
                  Salvar anotação
                </Button>
              )}
            </div>

            {!annotation && (
              <Button color="danger" onClick={toggleModalExclusion}>
                Remover
              </Button>
            )}
          </div>
        </div>
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          interval={false}
        >
          <CarouselIndicators
            items={detectionsArray || poles}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />

          {poleInfo !== null
            ? detectionsArray?.map((item, index) => (
                <CarouselItem
                  key={index}
                  onExiting={() => setAnimating(true)}
                  onExited={() => setAnimating(false)}
                  active={index === activeIndex}
                >
                  {!loading && index === activeIndex && (
                    <PhotoSphereViewer
                      containerId={`viewer-${index}`}
                      imgSrc={item.image}
                      detection={annotation ? null : filteredDetections}
                      currentPole={activeIndex}
                      numOfPoles={detectionsArray.length}
                      height="75vh"
                      annotation={annotation}
                      annotationType={annotationType}
                      setBoundingBox={setBoundingBox}
                      setNewAnnotationModal={setNewAnnotationModal}
                    />
                  )}
                </CarouselItem>
              ))
            : poles?.map((pole, index) => (
                <CarouselItem
                  key={pole.id}
                  onExiting={() => setAnimating(true)}
                  onExited={() => setAnimating(false)}
                  active={index === activeIndex}
                >
                  {!loading &&
                    index === activeIndex &&
                    currentPoleDetections.length > 0 && (
                      <PhotoSphereViewer
                        containerId={`viewer-${index}`}
                        imgSrc={currentPoleDetections[0]?.image}
                        detection={annotation ? null : filteredDetections}
                        height="75vh"
                        annotation={annotation}
                        currentPole={activeIndex}
                        numOfPoles={poles.length}
                        annotationType={annotationType}
                        setBoundingBox={setBoundingBox}
                        setNewAnnotationModal={setNewAnnotationModal}
                      />
                    )}
                </CarouselItem>
              ))}
          {loading && (
            <div style={{ height: "75vh" }}>
              <LoadingIcon />
            </div>
          )}

          {!loading && currentPoleDetections.length === 0 && (
            <div
              style={{
                height: "75vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "#e7e7e7",
              }}
            >
              <p>Não existem detecções para o objeto selecionado!</p>
            </div>
          )}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>
      </Modal>
    </>
  );
};

export default ModalSupervisionMode;
