import React, { useState, useEffect } from "react";
import Header from "../../elements/Header";
import { Link } from "react-router-dom";
import {
  UncontrolledCollapse,
  Card,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  CardTitle,
  CardText,
} from "reactstrap";
import {
  BsFillPencilFill,
  BsFillPlusCircleFill,
  BsFillNodeMinusFill,
  BsFillNodePlusFill,
  BsArrowLeft,
} from "react-icons/bs";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import CheckPermission from "./CheckPermission";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

const UserManagement = () => {
  document.title = "User Page";
  let apiUrl = process.env.REACT_APP_API_URL;
  //const { keycloak } = useKeycloak();
  const [userPermission, setUserPermission] = useState(null);
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });
  const [projects, setProjects] = useState();
  const [companies, setCompanies] = useState();
  const [users, setUsers] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [detections, setDetections] = useState();

  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
    button: "",
    buttonHref: "",
    buttonAction: "",
    buttonSecAction: "",
  });
  const toggleModal = (content) => {
    if (!content.title) content.title = "";
    if (!content.content) content.content = "";
    if (!content.button) content.button = "Ok";
    if (!content.buttonHref) content.buttonHref = null;
    if (!content.buttonAction) content.buttonAction = null;
    if (!content.buttonSecAction) content.buttonSecAction = null;

    setModalContent(content);
    setModal(!modal);
  };

  const getCompanies = () => {
    setCompanies();
    fetch(apiUrl + "/companies", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setCompanies(data.content);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getProjects = () => {
    setProjects();
    fetch(apiUrl + "/projects", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setProjects(data.totalElements);
        //return data.content.length;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getDetections = () => {
    setDetections();
    fetch(apiUrl + "/detections", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setDetections(data.totalElements);
        //return data.content.length;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getUsers = (company) => {
    if (!users[company]) {
      let auxList = users;
      setUsers(null);
      fetch(apiUrl + "/companies/" + (company ? company : "1") + "/employees", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userPermission.access_token,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            throw new Error("Erro");
          }
        })
        .then((data) => {
          try {
            data = JSON.parse(data);
          } catch (error) {}
          auxList[company] = data.content;
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setUsers(auxList);
        });
    }
  };

  useEffect(() => {
    let userListAux = {};
    if (users && users.length > 0) {
      Object.entries(users).forEach((u) => {
        userListAux[u[0]] = [];
        u[1].forEach((i) => {
          userListAux[u[0]].push(
            <Row className={"m-0 p-1"}>
              <Tooltip id="my-tooltip" />
              <Col sm={1} className={"p-0 m-auto text-center"}>
                {/* <div>
                  <small>
                    <b>{i.id}</b>
                  </small>
                </div> */}
                <div>
                  <small>({i.enabled ? "Ativo" : "Inativo"})</small>
                </div>
              </Col>
              <Col sm={5} className={"p-0 m-auto"}>
                <i>Nome:</i> {i.first_name + " " + i.last_name}
                <br />
                <small>({i.username})</small>
                <br />
                <small>{i.role}</small>
              </Col>
              <Col sm={4} className={"p-0 m-auto"}>
                <i>Contato:</i>
                <br />
                {i.email}
              </Col>
              <Col sm={2} className="text-end row">
                {CheckPermission(
                  userPermission && userPermission.role
                    ? userPermission.role
                    : "",
                  "ADMIN"
                ) ? (
                  <Link
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Editar o Usuário"
                    to={"/editarusuario/" + i.id}
                    className="m-auto mt-1 mb-1 btn btn-item-list pt-0 pb-0"
                    style={{
                      width: "150px",
                      height: "1.7em",
                    }}
                    type="button"
                    alt="Editar usuário"
                  >
                    <small>
                      <BsFillPencilFill /> Editar
                    </small>
                  </Link>
                ) : (
                  ""
                )}

                {CheckPermission(
                  userPermission && userPermission.role
                    ? userPermission.role
                    : "",
                  "EMPLOYEE"
                ) ? (
                  <Link
                    //className="btn btn-item-list"
                    type="button"
                    className="m-auto mt-1 mb-1 btn btn-item-list pt-0 pb-0"
                    style={{
                      width: "150px",
                      height: "1.7em",
                    }}
                    onClick={() => {
                      toggleModal({
                        title: "Desabilitar usuário",
                        content:
                          "Deseja realmente " +
                          (i.enabled ? "desabilitar" : "habilitar") +
                          " o usuário do sistema?",
                        button: "Sim",
                        buttonStyle: "danger",
                        buttonAction: i.enabled
                          ? () => disableEnableUser(i.id, "disable")
                          : () => disableEnableUser(i.id, "enable"),
                        buttonHref: null,
                        buttonSec: "Cancelar",
                        buttonSecStyle: "outline-secondary",
                        buttonSecAction: () => setModal(false),
                      });
                    }}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Desativar o Usuário"
                  >
                    <div
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Ativar o Usuário"
                    >
                      <small>
                        {i.enabled ? (
                          <>
                            <BsFillNodeMinusFill /> Desativar
                          </>
                        ) : (
                          <>
                            <BsFillNodePlusFill /> Ativar
                          </>
                        )}
                      </small>
                    </div>
                  </Link>
                ) : (
                  ""
                )}
              </Col>
              <hr className="m-0 p-0 mt-2" />
            </Row>
          );
          //console.log(i);
        });
      });
      setUsersList(userListAux);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const getLoading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status" id="loading">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  };

  // Início função de requisição de exclusão da empresa pela API

  // const deleteCompany = (id) => {
  //   toggleModal({
  //     title: modalContent.title,
  //     content: getLoading(),
  //     buttonAction: () => setModal(false),
  //     buttonHref: null,
  //   });
  //   fetch(apiUrl + "/companies/" + id, {
  //     method: "DELETE",
  //     headers: {
  //       accept: "*/*",
  //       "Content-Type": "application/json",

  //       Authorization: "Bearer " + userPermission.access_token,
  //     },
  //   })
  //     .then((response) => {
  //       if (!response.ok || response.status !== 200) {
  //         return response.text().then((text) => {
  //           throw new Error(text);
  //         });
  //       } else {
  //         return response.json();
  //       }
  //     })
  //     .then((data) => {
  //       toggleModal({
  //         title: modalContent.title,
  //         content: "Empresa excluída com sucesso.",

  //         buttonAction: () => setModal(false),
  //         buttonHref: null,
  //       });
  //     })
  //     .catch((error) => {
  //       toggleModal({
  //         title: modalContent.title,
  //         content: "Não foi possível excluir a empresa.",

  //         buttonAction: () => setModal(false),
  //         buttonHref: null,
  //       });
  //       console.error("Error:", error);
  //     });
  // };
  // Fim função de requisição de exclusão da empresa pela API

  // Início função de requisição de exclusão do usuario pela API

  const disableEnableUser = (id, status) => {
    toggleModal({
      title: modalContent.title,
      content: getLoading(),
      buttonAction: () => setModal(false),
      buttonHref: null,
    });
    //console.log(id);
    fetch(
      apiUrl + "/users/" + id + (status === "disable" ? "/disable" : "/enable"),
      {
        method: "PUT",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",

          Authorization: "Bearer " + userPermission.access_token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
        // if (!response.ok || response.status !== 200) {
        //   return response.text().then((text) => {
        //     throw new Error(text);
        //   });
        // } else {
        //   return response.text();
        // }
      })
      .then((data) => {
        toggleModal({
          title: modalContent.title,
          content:
            "Usuário " +
            (status === "disable" ? "desabilitado" : "habilitado") +
            " com sucesso.",

          buttonAction: () => setModal(false),
          buttonHref: null,
        });
      })
      .catch((error) => {
        toggleModal({
          title: modalContent.title,
          content:
            "Não foi possível " +
            (status === "disable" ? "desabilitar" : "habilitar") +
            " o usuário. " +
            error,

          buttonAction: () => setModal(false),
          buttonHref: null,
        });
        console.error("Error:", error);
      });
  };
  // Fim função de requisição de exclusão do usuario pela API

  const getData = () => {
    getCompanies();
    getDetections();
    getProjects();
  };

  useEffect(() => {
    if (userPermission) {
      getData();
    }
    console.log("updating...");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggleModal} backdrop="static">
        <ModalHeader>{modalContent.title}</ModalHeader>
        <ModalBody>{modalContent.content}</ModalBody>
        <ModalFooter>
          {modalContent.buttonSec &&
          modalContent.buttonSecStyle &&
          modalContent.buttonSecStyle ? (
            <Link
              className={"btn btn-" + modalContent.buttonSecStyle}
              onClick={modalContent.buttonSecAction}
            >
              {modalContent.buttonSec}
            </Link>
          ) : (
            ""
          )}
          <Link
            className={
              "btn btn-" +
              (modalContent.buttonStyle
                ? modalContent.buttonStyle
                : "outline-secondary")
            }
            to={modalContent.buttonHref ? modalContent.buttonHref : false}
            onClick={
              modalContent.buttonAction ? modalContent.buttonAction : false
            }
          >
            {modalContent.button}
          </Link>
        </ModalFooter>
      </Modal>
      <Header />
      <div className="container" style={{ marginTop: "10vh " }}>
        <div style={{ display: "flex", padding: "5px" }}>
          <div style={{ width: "100%" }} id="uprojetos">
            <Row className="mb-4">
              <Col>
                <Card>
                  <CardTitle tag="h5" className="text-center">
                    Empresas
                  </CardTitle>
                  <CardText className="display-6">
                    {companies ? companies.length : getLoading()}
                  </CardText>
                </Card>
              </Col>
              {/* <Col>
                <Card>
                  <CardTitle tag="h5" className="text-center">
                    Usuários
                  </CardTitle>
                  <CardText className="display-6">
                    {users ? users.length : getLoading()}
                  </CardText>
                </Card>
              </Col> */}
              <Col>
                <Card>
                  <CardTitle tag="h5" className="text-center">
                    Detecções
                  </CardTitle>
                  <CardText className="display-6">
                    {detections ? detections : getLoading()}
                  </CardText>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardTitle tag="h5" className="text-center">
                    Projetos
                  </CardTitle>
                  <CardText className="display-6">
                    {projects ? projects : getLoading()}
                  </CardText>
                </Card>
              </Col>
            </Row>

            <hr />
            <div>
              <div className="row pt-4">
                <h2 className="col-8 m-0 text-start">
                  <Link
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Voltar a Página Inicial"
                    className="text-end "
                    style={{ marginRight: "0px" }}
                    to={"/home"}
                  >
                    <BsArrowLeft
                      className="nav-button-circle "
                      style={{ boxShadow: "none" }}
                    />
                  </Link>
                  Empresas
                </h2>
                <div className="col-4 pr-0 m-0 row">
                  <div className="text-end">
                    {/* Início botão de adicionar nova empresa */}

                    {CheckPermission(
                      userPermission && userPermission.role
                        ? userPermission.role
                        : "",
                      "ADMIN"
                    ) ? (
                      // <Link
                      //   className="text-end "
                      //   style={{ marginRight: "0px" }}
                      //   to={"/novaempresa"}
                      // >
                      //   <BsFillPlusCircleFill className="nav-button-circle " />
                      // </Link>

                      <Link
                        className="text-end "
                        style={{ marginRight: "0px" }}
                        to={"/novaempresa"}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Criar uma Nova Empresa"
                      >
                        Nova Empresa
                        <Tooltip id="my-tooltip" />
                        <BsFillPlusCircleFill className="nav-button-circle " />
                      </Link>
                    ) : (
                      ""
                    )}

                    {/* Fim botão de adicionar nova empresa */}
                  </div>
                </div>
              </div>
              <hr />
              <Card>
                <Row className={"m-0 p-1"}>
                  <Col sm={1} className={"p-0 m-auto text-center"}>
                    <b>#</b>
                  </Col>
                  <Col sm={4} className={"p-0 m-auto"}>
                    <b>Nome / CNPJ</b>
                  </Col>
                  <Col sm={3} className={"p-0 m-auto"}>
                    <b>Email</b>
                  </Col>
                  <Col sm={2} className={"p-0 m-auto"}>
                    <b>Telefone</b>
                  </Col>
                  <Col sm={2} className="text-end"></Col>
                  <hr className="m-0 p-0 mt-2" />
                </Row>
                {companies
                  ? companies.map((e) => {
                      return (
                        <>
                          <Row
                            style={{ cursor: "pointer" }}
                            id={"toggler" + e.id}
                            onClick={() => getUsers(e.id)}
                            className={"m-0 p-1"}
                          >
                            <Col sm={1} className={"p-0 m-auto text-center"}>
                              <b>{e.id}</b>
                            </Col>
                            <Col sm={4} className={"p-0 m-auto"}>
                              {e.name}
                              <br />
                              <small>({e.cnpj})</small>
                            </Col>
                            <Col sm={3} className={"p-0 m-auto"}>
                              {e.email}
                            </Col>
                            {/* <Col sm={2}>{e.cnpj}</Col> */}
                            <Col sm={2} className={"p-0 m-auto"}>
                              {e.phone}
                            </Col>
                            <Col sm={2} className="text-end">
                              {false &&
                              CheckPermission(
                                userPermission && userPermission.role
                                  ? userPermission.role
                                  : "",
                                "ADMIN"
                              ) ? (
                                <Link
                                  to={"/editarempresa/"}
                                  className="m-auto mt-1 mb-1 btn btn-item-list pt-0 pb-0"
                                  style={{ width: "150px", height: "1.7em" }}
                                  type="button"
                                  alt="Editar projeto"
                                >
                                  <small>
                                    <BsFillPencilFill /> Editar
                                  </small>
                                </Link>
                              ) : (
                                ""
                              )}

                              {/* {CheckPermission(
                                userPermission && userPermission.role
                                  ? userPermission.role
                                  : "",
                                "ADMIN"
                              ) ? (
                                <Link
                                  className="m-auto mt-1 mb-1 btn btn-item-list pt-0 pb-0"
                                  style={{ width: "150px", height: "1.7em" }}
                                  type="button"
                                  onClick={() => {
                                    toggleModal({
                                      title: "Excluir empresa",
                                      content:
                                        "Deseja realmente exluir a empresa do sistema?",
                                      button: "Sim",
                                      buttonStyle: "danger",
                                      buttonAction: () => deleteCompany(e.id),
                                      buttonSec: "Cancelar",
                                      buttonSecStyle: "outline-secondary",
                                      buttonSecAction: () => setModal(false),
                                      buttonHref: null,
                                    });
                                  }}
                                >
                                  <small>
                                    <BsTrashFill /> Excluir
                                  </small>
                                </Link>
                              ) : (
                                ""
                              )} */}
                            </Col>
                            <hr className="m-0 p-0 mt-2" />
                          </Row>
                          <Row
                            style={{ marginLeft: "20px", marginBottom: "10px" }}
                          >
                            <Col>
                              <UncontrolledCollapse toggler={"#toggler" + e.id}>
                                <div>
                                  {/* <CardBody> */}
                                  <div>
                                    <div className="row pt-4">
                                      <h4 className="col-8 m-0 text-start">
                                        Usuários {e.name}
                                      </h4>
                                      <div className="col-4 pr-0 m-0 row">
                                        <div className="text-end">
                                          {/* Início botão de adicionar novo usuário */}

                                          {CheckPermission(
                                            userPermission &&
                                              userPermission.role
                                              ? userPermission.role
                                              : "",
                                            "EMPLOYEE"
                                          ) ? (
                                            <Link
                                              className="text-end "
                                              style={{ marginRight: "0px" }}
                                              to={"/novousuario/" + e.id}
                                              data-tooltip-id="my-tooltip"
                                              data-tooltip-content="Criar um novo Usuário"
                                            >
                                              Novo Usuário
                                              <Tooltip id="my-tooltip" />
                                              <BsFillPlusCircleFill
                                                style={{
                                                  height: "25px",
                                                  width: "25px",
                                                }}
                                                className="nav-button-circle "
                                              />
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                          {/* Fim botão de adicionar novo usuário */}
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                    <Card>
                                      {usersList[e.id]
                                        ? usersList[e.id]
                                        : getLoading()}
                                    </Card>
                                  </div>
                                  {/* </CardBody> */}
                                </div>
                                <hr />
                              </UncontrolledCollapse>
                            </Col>
                          </Row>
                        </>
                      );
                    })
                  : getLoading()}
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
