const removeSpecialChars = (string) => {
  return string
    .toString()
    .replace(/\./g, "")
    .replace(/-/g, "")
    .replace(/\(/g, "")
    .replace(/\)/g, "")
    .replace(/ /g, "")
    .replace(/\//g, "")
    .replace(/\+/g, "");
};

const formatCnpj = (string) => {
  return string
    .replace(/\D+/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

const formatPhone = (string) => {
  // verifica com base no número de dígitos se é um celular ou fixo
  try {
    if (typeof string !== "string") {
      throw new Error("Verifique o formato de entrada");
    }
    string = string.replace(/\D+/g, "");
    string = string.replace(/\D/g, "");
    string = string.replace(/^(\d)/, "+$1");
    string = string.replace(/(.{3})(\d)/, "$1 ($2");
    string = string.replace(/(.{7})(\d)/, "$1) $2");
    if (string.length === 14) {
      string = string.replace(/(.{1})$/, "-$1");
    } else if (string.length === 15) {
      string = string.replace(/(.{2})$/, "-$1");
    } else if (string.length === 16) {
      string = string.replace(/(.{3})$/, "-$1");
    } else if (string.length === 17) {
      string = string.replace(/(.{4})$/, "-$1");
    } else if (string.length >= 17) {
      string = string.replace(/(\d{5})(\d)/, "$1-$2");
    }
  } catch (error) {
    console.log("Erro ao formatar telefone. " + error);
  }
  return string;
};

export { formatCnpj, formatPhone, removeSpecialChars };
// export default cidadesEstados;
